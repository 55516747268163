interface TimezoneRegion {
  name: string;
  zone: string[];
}

export const timezones: TimezoneRegion[] =
  [
    {
      name: "US/Canada",
      zone: [
        "Canada/Pacific",
        "Canada/Mountain",
        "Canada/Central",
        "Canada/Eastern",
        "US/Alaska",
        "US/Arizona",
        "Canada/Newfoundland",
        "US/Hawaii"
      ]
    },
    {
      name: "America",
      zone: [
        "America/Adak",
        "America/Buenos_Aires",
        "America/Asuncion",
        "America/Bogota",
        "America/Campo_Grande",
        "America/Caracas",
        "America/Godthab",
        "Atlantic/Azores",
        "Canada/Saskatchewan",
        "America/Havana",
        "America/Mazatlan",
        "America/Mexico_City",
        "America/Montevideo",
        "America/Miquelon",
        "America/Noronha",
        "America/Santiago",
        "America/Santa_Isabel",
        "Atlantic/Stanley",
        "Brazil/Acre"
      ]
    },
    {
      name: "Africa",
      zone: [
        "Africa/Cairo",
        "Etc/GMT+2",
        "Etc/GMT+2",
        "Africa/Windhoek"
      ]
    },
    {
      name: "Asia",
      zone: [
        "Etc/GMT+3",
        "Asia/Baghdad",
        "Asia/Baku",
        "Etc/GMT+2",
        "Etc/GMT+3",
        "Asia/Dhaka",
        "Asia/Dubai",
        "Asia/Gaza",
        "Asia/Irkutsk",
        "Etc/GMT+7",
        "Israel",
        "Asia/Kabul",
        "Pacific/Majuro",
        "Indian/Maldives",
        "Asia/Kathmandu",
        "Indian/Mahe",
        "Asia/Krasnoyarsk",
        "Asia/Omsk",
        "Asia/Rangoon",
        "Asia/Singapore",
        "Asia/Tehran",
        "Japan",
        "Asia/Vladivostok",
        "Asia/Yakutsk",
        "Asia/Yekaterinburg",
        "Asia/Yerevan"
      ]
    },
    {
      name: "Atlantic",
      zone: [
        "Atlantic/Azores",
        "Atlantic/Cape_Verde"
      ]
    },
    {
      name: "Australia",
      zone: [
        "Australia/Adelaide",
        "Australia/Brisbane",
        "Australia/Darwin",
        "Australia/Eucla",
        "Australia/Lord_Howe",
        "Australia/Perth",
        "Australia/Melbourne"
      ]
    },
    {
      name: "UTC",
      zone: [
        "UTC"
      ]
    },
    {
      name: "Europe",
      zone: [
        "Etc/GMT+1",
        "Etc/GMT+2",
        "Europe/Lisbon",
        "Europe/Minsk",
        "Europe/Moscow",
        "Turkey"
      ]
    },
    {
      name: "Pacific",
      zone: [
        "Pacific/Apia",
        "Pacific/Auckland",
        "Pacific/Chatham",
        "Pacific/Easter",
        "Pacific/Fiji",
        "Pacific/Gambier",
        "Pacific/Kiritimati",
        "Pacific/Majuro",
        "Pacific/Marquesas",
        "Pacific/Norfolk",
        "Pacific/Noumea",
        "Pacific/Pago_Pago",
        "Pacific/Pitcairn",
        "Pacific/Tarawa",
        "Pacific/Tongatapu"
      ]
    }
  ]
;
