import { DispatchWithoutAction, useCallback, useEffect, useMemo, useState } from "react"

export interface ITelegramUser {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  language_code: string;
}

/**
 * The color scheme currently used in the Telegram app. Either “light” or “dark”.
 * Can `undefined`, if `window` is undefined.
 */
export type ColorScheme = "light" | "dark" | undefined;

/**
 * This object contains the user's current theme settings.
 * This object implement original Telegram WebApp type of {@link telegram!ThemeParams}
 */
export interface ThemeParams {
  /**
   * Background color in the #RRGGBB format.
   */
  bg_color?: string;
  /**
   * Main text color in the #RRGGBB format.
   */
  text_color?: string;
  /**
   * Hint text color in the #RRGGBB format.
   */
  hint_color?: string;
  /**
   * Link color in the #RRGGBB format.
   */
  link_color?: string;
  /**
   * Button color in the #RRGGBB format.
   */
  button_color?: string;
  /**
   * Button text color in the #RRGGBB format.
   */
  button_text_color?: string;
  /**
   * Secondary background color in the #RRGGBB format.
   */
  secondary_bg_color?: string;
}

export interface TelegramWebApp {
  initData: string;
  initDataUnsafe: {
    query_id: string;
    user: ITelegramUser;
    auth_date: string;
    hash: string;
  };
  version: string;
  platform: string;
  colorScheme: ColorScheme;
  themeParams: ThemeParams;
  isExpanded: boolean;
  viewportHeight: number;
  viewportStableHeight: number;
  isClosingConfirmationEnabled: boolean;
  headerColor: string;
  backgroundColor: string;
  BackButton: {
    isVisible: boolean;
  };
  MainButton: {
    text: string;
    color: string;
    textColor: string;
    isVisible: boolean;
    isProgressVisible: boolean;
    isActive: boolean;
  };
  HapticFeedback: any;
}

export type ImpactOccurredFunction = (
  style: "light" | "medium" | "heavy" | "rigid" | "soft",
) => void;

export type NotificationOccurredFunction = (
  type: "error" | "success" | "warning",
) => void;

export type SelectionChangedFunction = () => void;

export const useWebApp = (): any => window.Telegram ? window.Telegram.WebApp : {} as any;

export const useHapticFeedback = (): readonly [
  ImpactOccurredFunction,
  NotificationOccurredFunction,
  SelectionChangedFunction,
] => {
  const WebApp = useWebApp();
  const HapticFeedback = WebApp?.HapticFeedback;

  const impactOccurred: ImpactOccurredFunction = useCallback(
    (...args) => HapticFeedback?.impactOccurred(...args),
    [ HapticFeedback ],
  );
  const notificationOccurred: NotificationOccurredFunction = useCallback(
    (...args) => HapticFeedback?.notificationOccurred(...args),
    [ HapticFeedback ],
  );
  const selectionChanged: SelectionChangedFunction = useCallback(
    (...args) => HapticFeedback?.selectionChanged(...args),
    [ HapticFeedback ],
  );

  return [ impactOccurred, notificationOccurred, selectionChanged ] as const;
};

export const useThemeParams: () => readonly [ ColorScheme, ThemeParams ] = () => {
  const WebApp = useWebApp();
  const [ colorScheme, setColor ] = useState<ColorScheme>(WebApp?.colorScheme);
  const [ themeParams, setThemeParams ] = useState<ThemeParams>(
    WebApp?.themeParams || {},
  );

  useEffect(() => {
    if (!WebApp) return;
    const eventHandler = () => {
      setColor(WebApp.colorScheme);
      setThemeParams(WebApp.themeParams);
    };

    WebApp.onEvent?.("themeChanged", eventHandler);
    return () => {
      WebApp.offEvent?.("themeChanged", eventHandler);
    };
  }, [ WebApp ]);

  return [ colorScheme, themeParams ] as const;
};

export const useExpand = (): readonly [ boolean | undefined, DispatchWithoutAction ] => {
  const WebApp = useWebApp();
  const [ isExpanded, setIsExpanded ] = useState(WebApp?.isExpanded);

  useEffect(() => {
    if (!WebApp) return;
    const handleEvent = (payload: { isStateStable: boolean }) => {
      if (payload.isStateStable) {
        setIsExpanded(WebApp.isExpanded);
      }
    };

    WebApp.onEvent?.("viewportChanged", handleEvent);
    return () => WebApp.offEvent?.("viewportChanged", handleEvent);
  }, [ WebApp ]);

  const handleExpand = useCallback(() => WebApp?.expand?.(), [ WebApp ]);

  return [ isExpanded, handleExpand ] as const;
};

export interface ShowPopupButton extends Record<string, unknown> {
  id?: string;
  type?: "default" | "ok" | "close" | "cancel" | "destructive" | string;
  text?: string;
}

export interface ShowPopupParams extends Record<string, unknown> {
  title?: string;
  message: string;
  buttons?: ShowPopupButton[];
}

export type ShowPopupFunction = (params: ShowPopupParams) => Promise<string>;

export const useShowPopup: () => ShowPopupFunction = () => {
  const WebApp = useWebApp();

  return useCallback(
    params =>
      new Promise((resolve, reject) => {
        try {
          WebApp?.showPopup?.(params, (buttonId: string) => {
            resolve(buttonId);
          });
        } catch (e) {
          reject(e);
        }
      }),
    [ WebApp ],
  );
};

export type SwitchInlineQueryFunction = (
  query: string,
  chatType?: ("users" | "bots" | "groups" | "channels")[],
) => void;

export const useSwitchInlineQuery = (): SwitchInlineQueryFunction => {
  const WebApp = useWebApp();
  return useCallback(
    (...args) => WebApp?.switchInlineQuery?.(...args),
    [ WebApp ],
  );
};

export type GetItemFunction = (key: string) => Promise<string>;
export type SetItemFunction = (key: string, value: string) => Promise<void>;
export type GetItemsFunction = (keys: string[]) => Promise<string[]>;
export type RemoveItemFunction = (key: string) => Promise<void>;
export type RemoveItemsFunction = (key: string[]) => Promise<void>;
export type GetKeysFunction = () => Promise<string[]>;

export const useCloudStorage = (): {
  getItem: GetItemFunction;
  setItem: SetItemFunction;
  getItems: GetItemsFunction;
  removeItem: RemoveItemFunction;
  removeItems: RemoveItemsFunction;
  getKeys: GetKeysFunction;
} => {
  const cloudStorage = useWebApp()?.CloudStorage;

  const wrapWithPromise = <T>(fn: (callback: (error: any, result: T | PromiseLike<T>) => void) => void): Promise<T> =>
    new Promise((resolve, reject) => {
      fn((error, result) => {
        if (!error) {
          resolve(result);
          return
        }
        reject(error);
      });
    });

  const getItem: GetItemFunction = useCallback(
    key => wrapWithPromise<string>(callback => cloudStorage?.getItem(key, callback)),
    [ cloudStorage ],
  );

  const setItem: SetItemFunction = useCallback(
    (key, value) => wrapWithPromise<void>(callback => cloudStorage?.setItem(key, value, callback)),
    [ cloudStorage ],
  );

  const getItems: GetItemsFunction = useCallback(
    keys => wrapWithPromise<string[]>(callback => cloudStorage?.getItems(keys, callback)),
    [ cloudStorage ],
  );

  const removeItem: RemoveItemFunction = useCallback(
    key => wrapWithPromise<void>(callback => cloudStorage?.removeItem(key, callback)),
    [ cloudStorage ],
  );

  const removeItems: RemoveItemsFunction = useCallback(
    keys => wrapWithPromise<void>(callback => cloudStorage?.removeItems(keys, callback)),
    [ cloudStorage ],
  );

  const getKeys: GetKeysFunction = useCallback(
    () => wrapWithPromise<string[]>(callback => cloudStorage?.getKeys(callback)),
    [ cloudStorage ],
  );

  return useMemo(
    () => ({
      getItem,
      setItem,
      getItems,
      removeItem,
      removeItems,
      getKeys,
    }),
    [ cloudStorage ],
  );
};

