import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Screen, useScreen } from "context/screen.provider";
import Timezone from "widgets/forms/timezone";
import Calendar, { DateOption } from "widgets/forms/calendar/calendar";
import Card from "widgets/components/card";
import Time from "widgets/components/time";
import { parseDuration } from "widgets/forms/timepicker/timespot";
import Remind from "widgets/forms/remind";
import Note from "widgets/forms/note";
import { List, ListItem } from "widgets/components/list/list";
import { Event } from "widgets/forms/timepicker/timepicker";
import { useAccount } from "hooks/useAccount";
import { Booking, BookingTimeSpotItem } from "app/types/booking";
import { formatMinutes } from "app/utils";
import { FormatSpot } from "app/types/common";
import { BookingAvailabilityQueryParams, useBookingAvailabilityFetchByHashQuery } from "app/services/booking";
import { useEventListQuery } from "app/services/events";
import { useGetCalendarEventListQuery } from "app/services/calendar";
import { Location, LocationAskInvitee, LocationPhoneCall, locations, LocationType } from "app/types/location";
import LocationPhoneSetForm from "./locationPhoneSetForm";
import LocationPlaceSetForm from "./locationPlaceSetForm";
import BookingLocationCard from "../components/bookingLocationCard";
import moment from "moment";
import Collapse from "widgets/components/сollapse";

export interface BookingEventCreateFormProps {
  booking: Booking
  onChange: (name: string, data: any) => void
  errors?: { [key: string]: string }
}

type FormData = {
  name: string
  day: string | undefined
  timezone: string
  start_time: string
  note: string
  remind: string
  location?: Location
  duration: string
}

const BookingEventCreateForm: FC<BookingEventCreateFormProps> = ({ booking, onChange, errors }) => {

  const { t } = useTranslation()
  const account = useAccount()
  const {
    screen,
    setScreen,
  } = useScreen();

  const [ queryParams, setQueryParams ] = useState<BookingAvailabilityQueryParams>({
    hash: booking.hash,
    range_start: !!booking.start_date
      ? moment(booking.start_date).startOf("month").format("YYYY-MM-DD")
      : moment().startOf("month").format("YYYY-MM-DD"),
    range_end: !!booking.start_date
      ? moment(booking.start_date).endOf("month").format("YYYY-MM-DD")
      : moment().endOf("month").format("YYYY-MM-DD"),
    timezone: account.profile.timezone,
    duration: booking.duration,
  })

  const getDefaultName = () => {
    let accName = [ account.account.first_name, account.account.last_name ].join(" ")
    if (!accName) accName = account.account.username
    return (booking?.hosts[0].name + " x " + accName)
      .replace(/\s{2,}/g, ' ')
      .trim()
  }

  const [ location, setLocation ] = useState<{ index: number, data?: Location }>({
    index: booking.locations?.length ? 0 : -1,
    data: booking.locations?.length ? booking.locations[0] : undefined
  })

  const [ dataValue, setDataValue ] = React.useState<FormData>({
    timezone: account.account.timezone,
    name: getDefaultName(),
    day: "",
    start_time: "",
    remind: "30m",
    note: booking.note,
    location: location.data,
    duration: booking.duration,
  } as FormData);

  const {
    data: events, isLoading: isEventsLoading
  } = useEventListQuery({
    from: queryParams.range_start ? moment(queryParams.range_start).unix() : undefined,
    to: queryParams.range_end ? moment(queryParams.range_end).unix() : undefined,
  })
  const {
    data: calendarEvents, isLoading: isCalendarEventsLoading
  } = useGetCalendarEventListQuery({
    from: queryParams.range_start ? moment(queryParams.range_start).unix() : undefined,
    to: queryParams.range_end ? moment(queryParams.range_end).unix() : undefined,
  })

  const {
    data: availability,
    refetch: availabilityRefetch,
    isLoading: isAvailabilityEventsLoading,
    isFetching: isAvailabilityEventsFetching
  } = useBookingAvailabilityFetchByHashQuery(queryParams)

  useEffect(() => {
    if (location) {
      onChange && onChange("location", location.data)
    }
    if (!dataValue.name) {
      onChange && onChange("name", getDefaultName())
    }
  }, []);

  useEffect(() => {
    availabilityRefetch();
  }, [ queryParams ]);

  const handleChange = (name: any, data: any) => {
    if (name === "duration") {
      setQueryParams({ ...queryParams, duration: data })
      return
    }
    if (name === "timezone") {
      setQueryParams({ ...queryParams, timezone: data })
    }
    setDataValue({ ...dataValue, [name]: data })
    onChange && onChange(name, data)
  }

  const handleChangeLocation = (location: Location, index: number) => {
    setLocation({ index: index, data: location })
    handleChange("location", location)
  }

  const dates: DateOption[] = (availability?.days || []).map((item: any) => {
    const date = moment(item.date, "YYYY-MM-DD").format("ll")
    return { date: date, status: item.status }
  })

  const onDateRangeChange = (from: string, to: string) => {
    setQueryParams((state) => {
      return {
        ...state,
        range_start:
          moment(from).startOf("month").format("YYYY-MM-DD"),
        range_end:
          moment(to).endOf("month").format("YYYY-MM-DD"),
      }
    })
  }

  const handleSelectDay = (day?: string) => {
    setDataValue({ ...dataValue, day: day })
  }

  // Checking conflict situations of spots and events
  let spots = availability?.days.slice().find(d => d.date === dataValue.day!)?.spots || [];
  const groupEvents = groupEventsByDate([ ...(events || []), ...(calendarEvents || []) ] as Event[])
  spots = updateSpotsStatus(groupEvents[dataValue.day!] || [], spots, booking.duration)

  const screens = {
    timezone: <Timezone value={dataValue.timezone} onSelect={(v) => handleChange("timezone", v)}
                        timeFormat={account.account.format_time}/>,
    remind: <Remind value={dataValue.remind} onSelect={(v) => handleChange("remind", v)}/>,
    note: <Note value={dataValue.note} onChange={(v) => handleChange("note", v)}
                title={t("features.booking.forms.bookingEventCreateForm.screens.note.title")}/>,
    location_place: <LocationPlaceSetForm value={(location.data as LocationAskInvitee)?.note}
                                          onSubmit={(v) => {
                                            const newState = {
                                              ...location,
                                              data: { ...location.data, note: v } as LocationAskInvitee
                                            }
                                            setLocation(newState)
                                            handleChange("location", newState.data)
                                            setScreen(undefined)
                                          }}/>,
    location_phone: <LocationPhoneSetForm value={(location.data as LocationPhoneCall)?.phone}
                                          onSubmit={(v) => {
                                            const newState = {
                                              ...location,
                                              data: { ...location.data, phone: v } as LocationPhoneCall
                                            }
                                            setLocation(newState)
                                            handleChange("location", newState.data)
                                            setScreen(undefined)
                                          }}/>,
    location: (
      <div className="px-5">

        <div className="font-bold pt-5 pb-8 text-center">
          {t("features.booking.forms.bookingEventCreateForm.location.title")}
        </div>

        <List className="mb-5">
          {booking.locations?.map((loc, index) => (
            <ListItem key={index}>
              <div className="flex justify-between" onClick={() => handleChangeLocation(loc, index)}>
                <div className="flex flex-inline content-center gap gap-1">
                  <span className="w-6 text-left">
                    <i className={locations[(loc?.kind as LocationType)]?.ico}/>
                  </span>
                  <span className="font-bold text-tg-theme-accent-text">
                    {t("common.locations." + loc?.kind + ".title")}
                  </span>
                </div>
                <div className="w-8 text-right text-tg-theme-subtitle">
                  {location.index === index && <i className="fas fa-check"/>}
                </div>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    ),
    availability: (
      <>
        <div className="my-5 mt-8 px-6 text-center">
          <p className="font-medium text-tg-theme-text">
            {t("features.booking.forms.bookingEventCreateForm.screens.availability.text")}
          </p>
        </div>
        <SpotList
          start_time={dataValue.start_time}
          spots={spots!}
          timezone={availability?.timezone!}
          onSelect={(v) => handleChange("start_time", v)}/>
      </>
    )
  };

  return (
    <Screen screens={screens}>
      <div>
        {!screen && (
          <div className="my-8 mt-8 px-5 normal-case">
            <Card title={`${t("features.booking.forms.bookingEventCreateForm.name.title")}*`}
                  className={`py-3 ${!!errors?.name && "border border-red-500 rounded-md"} `}>
              <input
                value={dataValue.name}
                className="w-full text-tg-theme-text bg-tg-theme-section focus:outline-none"
                onChange={e => handleChange("name", e.target.value)}
              />
            </Card>
          </div>
        )}

        <div className="px-5">
          <List className="mb-5" title={t("features.booking.forms.bookingEventCreateForm.event.title")}>
            <ListItem>
              <div className="flex justify-between">
                <div className="flex-1">{t("features.booking.forms.bookingEventCreateForm.event.item.duration")}</div>
                <div className="text-tg-theme-subtitle">
                  {formatMinutes(parseDuration(dataValue.duration), account.account.language)}
                </div>
              </div>
            </ListItem>
            <ListItem>
              <div className="flex justify-between" onClick={() => setScreen("timezone")}>
                <div className="flex-1">{t("features.booking.forms.bookingEventCreateForm.event.item.timezone")}</div>
                <div className="text-tg-theme-subtitle">
                  {dataValue.timezone}
                </div>
                <div className="w-8 text-right text-tg-theme-subtitle">
                  <i className="fas fa-angle-right"/>
                </div>
              </div>
            </ListItem>
          </List>
        </div>

        {(!!dataValue.day && !dataValue.start_time) &&
          <div
            onClick={() => handleSelectDay()}
            className="flex flex-col gap gap-2 items-center justify-center h-26 px-5 pt-5 mb-10">
            <Time variant="WDatetime" time={dataValue.day} timezone={dataValue.timezone}/>
          </div>
        }

        {(!dataValue.start_time) && (
          <div className="px-5">
            <Calendar
              isLoading={isAvailabilityEventsLoading || isAvailabilityEventsFetching}
              allowEmpty={false}
              allowPrevDay={false}
              disablePrevMonth={true}
              selectedDay={false}
              current={moment().unix()}
              moveToDate={!!booking.start_date ? moment(booking.start_date).unix() : undefined}
              checkDates={true}
              dates={dates}
              availability={availability?.days?.filter(a => !!a.spots.length) || []}
              events={[ ...(events || []), ...(calendarEvents || []) ] as Event[]}
              onChange={handleSelectDay}
              onDateRange={onDateRangeChange}
              onSelect={() => setScreen("availability")}
            />
          </div>
        )}

        {!!dataValue.start_time &&
          <>
            <div className="px-5 my-5">
              <Card
                onClick={() => setDataValue({ ...dataValue, start_time: "" })}
                title={t("features.booking.forms.bookingEventCreateForm.time.title")}>
                <div className="w-full mb-2 pb-2 border-b-2 border-b-neutral-300 dark:border-b-neutral-700">
                  <div className="flex flex-row h-22 pb-2 items-center">
                    <div className="basis-1/2 h-22">
                      <div className="text-left pb-2 pt-2">
                        <p className="font-bold text-tg-theme-accent-text">
                          {t(`common.calendar.days.${moment(dataValue.day).format("ddd").toLowerCase()}`)}
                        </p>
                        <p className="font-bold text-tg-theme-accent-text">
                          {dataValue.day}
                        </p>
                      </div>
                    </div>

                    <div
                      className="basis-1/2 h-18 ml-auto py-2 pl-4 border-l-2 border-l-neutral-300 dark:border-l-neutral-700">
                      <div className="flex flex-row items-center gap gap-2">
                        <div className="basis-1/2 text-left">
                          <p className="text-tg-theme-hint text-xs">
                            {t("features.booking.forms.bookingEventCreateForm.time.interval.start")}
                          </p>
                          <p className="h-5 font-bold text-tg-theme-accent-text text-sm">
                            <Time variant="Time" time={dataValue.start_time} timezone={dataValue.timezone}/>
                          </p>
                        </div>
                        <div className="basis-1/2 text-left">
                          <p className="text-tg-theme-hint text-xs">
                            {t("features.booking.forms.bookingEventCreateForm.time.interval.end")}
                          </p>
                          <p className="h-5 font-bold text-tg-theme-accent-text text-sm">
                            <Time variant="Time"
                                  time={moment(dataValue.start_time, FormatSpot)
                                    .add(parseDuration(booking.duration), "minutes")
                                    .format(FormatSpot)}
                                  timezone={dataValue.timezone}/>
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="w-full">
                  <p className="text-tg-theme-hint">
                    {t("features.booking.forms.bookingEventCreateForm.time.change")}
                  </p>
                </div>
              </Card>
            </div>

            {!!booking.locations && !!booking.locations.length && (
              <div className="px-5 mb-5">
                <Card title={t("features.booking.forms.bookingEventCreateForm.location.title")}>
                  <BookingLocationCard location={location}
                                       bookingLocations={booking.locations}
                                       setScreen={setScreen}/>
                </Card>
              </div>
            )}

            <div className="mb-5">
              <Collapse title={t("features.booking.forms.bookingEventCreateForm.advanced")}>
                <>
                  <div className="px-5">
                    <Card onClick={() => setScreen("remind")}
                          title={t("features.booking.forms.bookingEventCreateForm.remind.title")}>
                      <div className="flex justify-between">
                        <h5 className="text-tg-theme-accent-text">
                          {t("features.booking.forms.bookingEventCreateForm.remind.placeholder")}
                        </h5>
                        <div className="inline-flex items-center justify-end">
                          <h5 className="pr-2">
                            {dataValue.remind || t("features.booking.forms.bookingEventCreateForm.remind.no-remind")}
                          </h5>
                          <i className="fa-solid fa-arrows-up-down"/>
                        </div>
                      </div>
                    </Card>
                  </div>

                  <div className="px-5 my-5">
                    <Card title={t("features.booking.forms.bookingEventCreateForm.note.title")}>
                      {!dataValue.note && (
                        <div onClick={() => setScreen("note")}>
                          <h5 className="text-tg-theme-accent-text mb-2">
                            {t("features.booking.forms.bookingEventCreateForm.note.placeholder")}
                          </h5>
                        </div>
                      )}
                      {!!dataValue.note && (
                        <div className="w-full inline-flex content-justify mb-2" onClick={() => setScreen("note")}>
                          <p className="font-light text-xs text-tg-theme-hint">
                            {dataValue.note}
                          </p>
                        </div>
                      )}
                    </Card>
                  </div>
                </>
              </Collapse>
            </div>
          </>
        }
      </div>
    </Screen>
  )
}

interface SpotList {
  start_time: string
  timezone: string
  spots: BookingTimeSpotItem[]
  onSelect: (time: string) => void
}

const SpotList: FC<SpotList> = ({ spots = [], start_time, timezone, onSelect }) => {
  return (
    <div className="w-full my-5 px-5">
      <div className="flex flex-col justify-center items-center ">
        {spots.map((spot, index) => {
          return (
            <Card
              key={index}
              className={`mb-5 w-44 text-center ${spot.start_time === start_time && "border border-tg-theme-subtitle"}`}
              onClick={() => onSelect(spot.start_time)}>
              <div className="flex items-center">
                <div className="w-auto h-auto">
                  {spot.status === "available" && <div className="w-3 h-3 bg-green-600 rounded-full"/>}
                  {spot.status === "unavailable" && <div className="w-3 h-3 bg-red-600 rounded-full"/>}
                </div>
                <div className="w-full text-center">
                  <Time variant="Time" time={spot.start_time} timezone={timezone}></Time>
                </div>
              </div>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

function isTimeConflict(eventStart: moment.Moment, eventEnd: moment.Moment, spotStart: moment.Moment, spotDuration: string): boolean {
  const spotEnd = spotStart.clone().add(parseDuration(spotDuration), "minutes");
  return spotStart.isBefore(eventEnd) && spotEnd.isAfter(eventStart);
}

function updateSpotsStatus(events: Event[], spots: BookingTimeSpotItem[], spotDuration: string): BookingTimeSpotItem[] {
  return spots.map(spot => {
    const spotStart = moment(spot.start_time);

    const isConflict = events.some(event => {
      const eventStart = moment(event.start_time);
      const eventEnd = moment(event.end_time);
      return isTimeConflict(eventStart, eventEnd, spotStart, spotDuration);
    });

    return {
      ...spot,
      status: isConflict ? "unavailable" : "available"
    };
  });
}

function groupEventsByDate(events: Event[]): { [date: string]: Event[] } {
  return events.reduce((acc: { [date: string]: Event[] }, event: Event) => {
    const date = moment(event.start_time).format("YYYY-MM-DD");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});
}

export default BookingEventCreateForm;
