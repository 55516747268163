import { useTranslation } from "react-i18next";
import Card from "widgets/components/card";

const ScreenDone = () => {

  const { t } = useTranslation()

  return (
    <div className="flex flex-col min-h-screen px-5">

      <h1 className="text-3xl font-bold pt-8 text-center">
        {t("features.welcome.components.screenDone.title")}
      </h1>

      <div className="text-center mb-5">
        {t("features.welcome.components.screenDone.desc")}
      </div>

      <div className="flex-1 mt-6">
        {Array.from({ length: 3 }, (_, i) => {
          const icons = [ "fa fa-user-clock", "fa-solid fa-user-tie", "fa fa-calendar-days" ]
          return (
            <Card key={i} title={t("features.welcome.components.screenDone.features." + i + ".title")} className="mb-5">
              <div className="flex justify-center items-center min-h-22">
                <div className="min-w-10 text-center">
                  <i className={`${icons[i]}`}/>
                </div>
                <div className="flex-auto px-4 text-sm">
                  {t("features.welcome.components.screenDone.features." + i + ".desc")}
                </div>
              </div>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export default ScreenDone
