import { ReactNode } from "react"

interface MenuProps {
  children: ReactNode
}

export const Menu = ({ children }: MenuProps) => (
  <>
    <div className="rounded-xl w-full">
      <div className="flex flex-row justify-between items-center">
        {children}
      </div>
    </div>
  </>
)

interface MenuItemProps {
  children: ReactNode
  onClick?: () => void
}

export const MenuItem = ({ children, onClick }: MenuItemProps) => (
  <>
    <div className="w-full p-4 items-center text-center text-tg-theme-link" onClick={onClick}>
      {children}
    </div>
  </>
)
