import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { ScheduleRule, ScheduleRuleInterval, ScheduleRuleWDayType } from "app/types/schedule";
import Dropdown from "widgets/forms/dropdown";
import Card from "widgets/components/card";
import { useTranslation } from "react-i18next";

interface AvailabilityProps {
  data?: { name: string, rules: ScheduleRule[] },
  onChange: (schedule: { name: string, rules: ScheduleRule[] }) => void;
}

const daysOfWeek: ScheduleRuleWDayType[] = [ "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun" ];
const dayOfWeekMap: Record<ScheduleRuleWDayType, string> = {
  "Mon": "Monday",
  "Tue": "Tuesday",
  "Wed": "Wednesday",
  "Thu": "Thursday",
  "Fri": "Friday",
  "Sat": "Saturday",
  "Sun": "Sunday"
};
const weekOfDayMap: Record<string, ScheduleRuleWDayType> = {
  "Monday": "Mon",
  "Tuesday": "Tue",
  "Wednesday": "Wed",
  "Thursday": "Thu",
  "Friday": "Fri",
  "Saturday": "Sat",
  "Sunday": "Sun"
};

const generateTimeOptions = (): { value: string; label: string }[] => {
  const times: { value: string; label: string }[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = String(hour).padStart(2, "0");
      const formattedMinute = String(minute).padStart(2, "0");
      times.push({ value: `${formattedHour}:${formattedMinute}`, label: `${formattedHour}:${formattedMinute}` });
    }
  }
  return times;
};

const timeOptions = generateTimeOptions();

const ScreenAvailability: FC<AvailabilityProps> = ({ data, onChange }) => {

  const { t } = useTranslation()
  const [ nameValue, setName ] = useState("Work schedule")
  const [ selectedDays, setSelectedDays ] = useState<ScheduleRuleWDayType[]>([ "Mon", "Tue", "Wed", "Thu", "Fri" ]);
  const [ timeRange, setTimeRange ] = useState<ScheduleRuleInterval>({ from: "09:00", to: "18:00" });
  const [ tab, setTab ] = useState<"early" | "default" | "late" | "custom">("default");

  useEffect(() => {
    if (!data) return
    setName(data.name)
    if (!!data?.rules) {
      setSelectedDays((data?.rules as any[]).filter(r => !!r.wday).map(item => {
        return weekOfDayMap[item.wday]
      }))
      const ranges = data?.rules[0]?.intervals[0]
      if (ranges.from === "07:00" && ranges?.to === "16:00") {
        setTab("early")
      } else if (ranges.from === "09:00" && ranges?.to === "18:00") {
        setTab("default")
      } else if (ranges.from === "12:00" && ranges?.to === "21:00") {
        setTab("late")
      } else {
        setTab("custom")
      }
      setTimeRange(ranges)
    }
  }, []);

  useEffect(() => {
    const result: ScheduleRule[] = selectedDays.map(d => ({
      type: "wday",
      wday: dayOfWeekMap[d],
      intervals: [ timeRange ]
    }) as any);
    onChange({ name: nameValue, rules: result });
  }, []);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const result: ScheduleRule[] = selectedDays.map(d => ({
      type: "wday",
      wday: dayOfWeekMap[d],
      intervals: [ timeRange ]
    }) as any);
    setName(e.target.value)
    onChange({ name: e.target.value, rules: result });
  }

  const toggleDay = useCallback((day: ScheduleRuleWDayType) => {
    setSelectedDays(prev => {
      const updatedDays = prev.includes(day) ? prev.filter(d => d !== day) : [ ...prev, day ];
      const result: ScheduleRule[] = updatedDays.map(d => ({
        type: "wday",
        wday: dayOfWeekMap[d],
        intervals: [ timeRange ]
      }) as any);
      onChange({ name: nameValue, rules: result });
      return updatedDays;
    });
  }, [ onChange, timeRange ]);

  const handleChange = useCallback((interval: ScheduleRuleInterval) => {
    setTimeRange(interval);
    const result: ScheduleRule[] = selectedDays.map(d => ({
      type: "wday",
      wday: dayOfWeekMap[d],
      intervals: [ interval ]
    }) as any);
    onChange({ name: nameValue, rules: result });
  }, [ onChange, selectedDays ]);

  const handleSelectTab = useCallback((t: "early" | "default" | "late" | "custom") => {
    if (t === tab) return
    switch (t) {
      case "early":
        handleChange({ from: "07:00", to: "16:00" });
        break;
      case "default":
        handleChange({ from: "09:00", to: "18:00" });
        break;
      case "late":
        handleChange({ from: "12:00", to: "21:00" });
        break;
      case "custom":
        handleChange({ from: "09:00", to: "18:00" });
        break;
    }
    setTab(t);
  }, [ handleChange, tab ]);

  const handlerUpdateInterval = useCallback((key: "from" | "to", value: string) => {
    handleChange({ ...timeRange, [key]: value });
  }, [ handleChange, timeRange ]);

  return (
    <div className="flex flex-col min-h-screen px-5">

      <h1 className="text-3xl font-bold pt-8 text-center">
        {t("features.welcome.components.screenAvailability.title")}
      </h1>

      <div className="mb-5 text-center">
        {t("features.welcome.components.screenAvailability.desc")}
      </div>

      <div className="flex-1">
        <Card
          title={t("features.schedule.forms.scheduleForm.name")}
          className="mb-5">
          <input
            value={nameValue}
            className="w-full text-tg-theme-text bg-tg-theme-section focus:outline-none"
            onChange={handleNameChange}
          />
        </Card>

        <div className="flex divide-x divide-neutral-200 dark:divide-neutral-800 mb-5">
          {daysOfWeek.map((day, index) => (
            <div
              key={day}
              onClick={() => toggleDay(day)}
              className={`min-w-8 flex-1 p-2 text-center
                ${index === 0 ? "rounded-l-xl" : ""}
                ${index === daysOfWeek.length - 1 ? "rounded-r-xl" : ""}
                ${selectedDays.includes(day) ? "bg-tg-theme-button text-tg-theme-text" : "bg-tg-theme-main text-tg-theme-text"}
              `}
            >
              {t(`features.welcome.components.screenAvailability.days.${day.toLowerCase()}`)}
            </div>
          ))}
        </div>

        <div className="flex flex-col mb-5">
          <div className="flex items-center justify-center grid-cols-3 gap-3 mb-4">
            {[ "early", "default", "late" ].map(tabName => (
              <div
                key={tabName}
                className={`flex flex-col items-center rounded-xl text-xs py-2 px-4 w-1/3 text-center ${tab === tabName ? "bg-tg-theme-button" : "bg-tg-theme-main"}`}
                onClick={() => handleSelectTab(tabName as "early" | "default" | "late")}
              >
                <div className="uppercase">
                  {t(`features.welcome.components.screenAvailability.${tabName}`)}
                </div>
                <div className="text-xs">
                  {tabName === "early" && "07:00 - 16:00"}
                  {tabName === "default" && "09:00 - 18:00"}
                  {tabName === "late" && "12:00 - 21:00"}
                </div>
              </div>
            ))}
          </div>
          <div
            className={`w-full rounded-xl text-xs text-center py-2 px-4 ${tab === "custom" ? "bg-tg-theme-button" : "bg-tg-theme-main"}`}
            onClick={() => handleSelectTab("custom")}
          >
            <div className="uppercase py-1">Custom</div>
          </div>
        </div>

        {tab === "custom" && (
          <div className="w-full flex flex-row grid-cols-2 gap-3 mb-5 items-center justify-center">
            <Dropdown
              key="from"
              name="from"
              options={timeOptions}
              value={timeRange.from}
              label={"--:--"}
              onChange={v => handlerUpdateInterval("from", v)}
            />
            <Dropdown
              key="to"
              name="to"
              options={timeOptions}
              value={timeRange.to}
              label={"--:--"}
              onChange={v => handlerUpdateInterval("to", v)}
            />
          </div>
        )}

        <div className="text-center pt-5 text-tg-theme-subtitle">
          {t("features.welcome.components.screenAvailability.info")}
        </div>
      </div>
    </div>
  );
};

export default ScreenAvailability;
