import React from "react";
import { PendingEvent } from "app/types/event";
import Card from "widgets/components/card";
import { LocationItem } from "widgets/forms/location";
import moment from "moment";
import { useTranslation } from "react-i18next";

export interface PendingEventItemProps {
  event: PendingEvent,
}

const PendingEventItem = ({ event }: PendingEventItemProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div className="flex items-center justify-between pb-2 border-b-2 border-gray-200 dark:border-gray-700">
        <div className="truncate">
          <div className="text-tg-theme-accent-text">{event.name}</div>
          <p className="text-tg-theme-subtitle pt-1 text-xs">
            {t("features.event.components.pendingEventItem.created")}: {moment(event.created * 1000).format("DD/MM/YYYY HH:mm")}
          </p>
        </div>
        <div className="w-8 text-right text-tg-theme-subtitle">
          <i className="fa fa-chevron-right"/>
        </div>
      </div>
      <div className="flex flex-wrap gap gap-1 mt-2">
        {event.locations?.map((loc, index) => (
          <div className="mb-0.5" key={index}>
            <LocationItem location={loc} ico={true}/>
          </div>
        ))}
      </div>
    </Card>
  )
}

export default PendingEventItem
