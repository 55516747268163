import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useCloudStorage, useWebApp } from "app/telegram";
import { useAccount } from "hooks/useAccount";
import { useScreen } from "context/screen.provider";
import { EventTypeList } from "features/eventtype/components/eventTypeList";
import ScheduleAccordionList from "widgets/components/scheduleAccordionList";
import { useTranslation } from "react-i18next";
import EventListCard from "../features/event/components/eventListCard";
import WelcomeForm from "../features/welcome/forms/welcomeForm";
import CalendarConnectForm from "../features/calendar/forms/calendarConnectForm";
import { useIntegrationListQuery } from "../app/services/integration";

const HomePage = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { account } = useAccount()
  const { setMainButtonProps, setBackButtonVisible } = useScreen()
  const cloudStorage = useCloudStorage()

  const [ showWelcome, setShowWelcome ] = useState<boolean>(false);

  const webApp = useWebApp()
  const startParam = webApp.initDataUnsafe.start_param;

  const {
    data: integrations,
    refetch: integrationRefetch,
    isLoading: isIntegrationsLoading,
    isError: isIntegrationsError,
  } = useIntegrationListQuery({ service: "calendar" }, {
    // pollingInterval: 5000,
    skipPollingIfUnfocused: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        integrationRefetch()
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [ integrationRefetch ]);

  useEffect(() => {
    setMainButtonProps(t("page.home.mainButton.create"), () => navigate("eventtypes/share"))
    setBackButtonVisible(false)

    if (startParam) {
      webApp.initDataUnsafe.start_param = ""

      const startapp = /(\w+)-(.*)/;
      const [ , target, id ] = startParam.match(startapp) || [];

      if (target === "eventtype" && id === "new") {
        return navigate(`eventtypes/create`);
      }
      if (target === "book" && id) return navigate(`booking/${id}/`);
      if (target === "event" && id) return navigate(`event/${id}/`);
    }

    // cloudStorage.removeItem("welcome")
    cloudStorage.getItem("welcome")
      .then((v) => {
        setShowWelcome(!v)
        setMainButtonProps(t("page.home.mainButton.create"), () => navigate("eventtypes/share"))
        setBackButtonVisible(false)
      })
  }, [])

  const handleWelcomeClose = async () => {
    await cloudStorage.setItem("welcome", "done")
    setShowWelcome(false)
    setMainButtonProps(t("page.home.mainButton.create"), () => navigate("eventtypes/share"))
    setBackButtonVisible(false)
  }

  if (startParam) {
    const startapp = /(\w+)-(.*)/;
    const [ , target ] = startParam.match(startapp) || [];
    if (!target) return <></>
  }

  if (showWelcome) {
    return <WelcomeForm onClose={handleWelcomeClose}/>
  }

  return (
    <div>
      <div className="w-full mb-10 px-5">
        <div className="flex justify-between w-full h-10 mb-2 py-5">
          <NavLink to="/settings">
            <div className="flex justify-center items-center bg-tg-theme-section rounded-full h-10 w-10 ">
              <i className="fa-solid fa-gear"/>
            </div>
          </NavLink>
          <span className="w-24 h-8 inline-flex items-center text-center justify-center rounded-md text-sm
                  font-medium text-tg-theme-accent-text ring-1 ring-inset ring-gray-500/10 cursor-pointer"
                onClick={() => webApp.openTelegramLink("https://t.me/CalenDucky")}>
            {t("page.home.beta")} <i className="fa-solid fa-arrow-up-right-from-square ml-2 text-xs"/>
          </span>
          <NavLink to="/events/pending">
            <div className="flex justify-center items-center bg-tg-theme-section rounded-full h-10 w-10">
              <i className="fa-solid fa-clock"/>
            </div>
          </NavLink>
        </div>
        <div className="w-full h-26 pt-10 text-center">
          <p className="font-light text-2xl leading-tight">
            {t("page.home.hello")}, {account.first_name}
          </p>
        </div>
      </div>

      {!integrations || !integrations.length && (
        <div className="mt-5 mb-6 px-5">
          <CalendarConnectForm hiddenDisabled={true}/>
        </div>
      )}

      <div className="mt-5 mb-6 px-5">
        <ScheduleAccordionList/>
      </div>

      <div className="mt-5 mb-6 px-5">
        <EventTypeList variant="horizontal"/>
        <div className="my-3 ms-2">
          <NavLink to="/eventtypes/create" className="flex text-sm text-tg-theme-accent-text">
            + {t("page.home.createEventTypeLink")}
          </NavLink>
        </div>
      </div>

      <EventListCard/>
    </div>
  );
};

export default HomePage;
