import * as Sentry from "@sentry/react";

export function handleError(err: unknown, title: string) {
  if (typeof err === "string") {
    // Handle string errors
    console.error(`${title}:`, err);
    Sentry.captureMessage(`${title}: ${err}`);
  } else if (err instanceof Error) {
    // Handle Error objects
    console.error(`${title}:`, err.message);
    console.error("Stack trace:", err.stack);
    Sentry.captureMessage(`${title}: ${err.message}`);
    Sentry.captureException(err);
  } else {
    // Handle any other types of errors
    console.error(`${title}:`, JSON.stringify(err, null, 2));
    Sentry.captureMessage(`${title}: Unknown error type`);
    Sentry.captureException(err);
  }
}
