export const LocationTypes = {
  Google: "google_conference" as const,
  Zoom: "zoom_conference" as const,
  InPerson: "in_person_meeting" as const,
  AskInvitee: "ask_invitee" as const,
  Phone: "phone_call" as const,
  Telegram: "telegram_call" as const,
  Teams: "microsoft_teams_conference" as const,
  Yandex: "yandex_telemost" as const,
};

export type LocationType = (typeof LocationTypes)[keyof typeof LocationTypes];

export interface LocationOption {
  value: LocationType;
  ico: string,
  disabled: boolean;
}

export const locations: { [key in LocationType]: LocationOption } = {
  [LocationTypes.InPerson]: {
    value: LocationTypes.InPerson,
    ico: "fa-solid fa-street-view",
    disabled: false
  },
  [LocationTypes.Phone]: {
    value: LocationTypes.Phone,
    ico: "fa-solid fa-phone",
    disabled: false
  },
  [LocationTypes.AskInvitee]: {
    value: LocationTypes.AskInvitee,
    ico: "fa-solid fa-question",
    disabled: false
  },
  [LocationTypes.Telegram]: {
    value: LocationTypes.Telegram,
    ico: "fa-solid fa-paper-plane",
    disabled: false
  },
  [LocationTypes.Google]: {
    value: LocationTypes.Google,
    ico: "fa-brands fa-google",
    disabled: false
  },
  [LocationTypes.Zoom]: {
    value: LocationTypes.Zoom,
    ico: "fa-solid fa-video",
    disabled: false
  },
  [LocationTypes.Teams]: {
    value: LocationTypes.Teams,
    ico: "fa-brands fa-microsoft",
    disabled: true
  },
  [LocationTypes.Yandex]: {
    value: LocationTypes.Yandex,
    ico: "fa-solid fa-video",
    disabled: true
  },
};

export type Location = LocationOnlineService
  | LocationInPersonalMeeting
  | LocationAskInvitee
  | LocationPhoneCall
  | LocationTelegramCall;

export type LocationOnlineService = {
  kind: typeof LocationTypes.Google
    | typeof LocationTypes.Zoom
    | typeof LocationTypes.Teams
    | typeof LocationTypes.Yandex
  note: string;
  link: string;
}

export type LocationInPersonalMeeting = {
  kind: typeof LocationTypes.InPerson;
  name: string;
  note: string;
  lat: string;
  lng: string;
}

export type LocationAskInvitee = {
  kind: typeof LocationTypes.AskInvitee;
  note?: string;
}

export type LocationPhoneCall = {
  kind: typeof LocationTypes.Phone;
  note: string;
  phone: string;
  outbound: boolean;
}

export type LocationTelegramCall = {
  kind: typeof LocationTypes.Telegram;
  note: string;
  video: boolean;
  outbound: boolean;
}

export type LocationZoom = {
  kind: typeof LocationTypes.Zoom,
}

export type LocationGoogle = {
  kind: typeof LocationTypes.Google,
}

export type LocationTeams = {
  kind: typeof LocationTypes.Teams,
}

export type LocationYandex = {
  kind: typeof LocationTypes.Yandex,
}

export const LocationDefault = {
  kind: "telegram_call",
  outbound: true,
  video: false,
  note: "",
}
