import React from "react";
import { useTranslation } from "react-i18next";

const FormSearch = () => {
  const { t } = useTranslation()
  return (
    <input
      className="w-full text-tg-theme-text bg-tg-theme-section focus:outline-none"
      placeholder={t("widgets.forms.search.placeholder")}/>
  )
}

export default FormSearch
