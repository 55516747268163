import React, { useRef, useState } from "react";

interface CollapseProps {
  title: string;
  children: React.ReactNode;
}

const Collapse: React.FC<CollapseProps> = ({ title, children }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <div
        className="w-full px-5 mb-5 text-left text-tg-theme-link"
        onClick={toggleCollapse}
      >
        <div className="flex gap-2">
          <div>{title}</div>
          <div>
            {isOpen
              ? <i className="fa fa-chevron-down"/>
              : <i className="fa fa-chevron-right"/>
            }
          </div>
        </div>

      </div>
      <div
        ref={contentRef}
        style={{
          maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : "0px",
          transition: "max-height 0.5s ease-out",
        }}
        className="overflow-hidden"
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
