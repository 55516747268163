import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useScreen } from "context/screen.provider";
import { useTranslation } from "react-i18next";
import { useEventTypeListQuery } from "app/services/eventtypes";
import { List, ListItem } from "widgets/components/list/list";
import { EventType, eventTypeGeneralList } from "app/types/eventType";
import EventTypeOptionsList from "../components/eventTypeOptionsList";
import { useWebApp } from "app/telegram";

function findIcoByKind(kind: string): any | undefined {
  for (const category in eventTypeGeneralList) {
    if (eventTypeGeneralList.hasOwnProperty(category)) {
      const events = eventTypeGeneralList[category as keyof typeof eventTypeGeneralList];
      for (const event of events) {
        if (event.kind === kind) {
          return event;
        }
      }
    }
  }
  return undefined;
}

const EventTypeSharePage: React.FC = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const webApp = useWebApp()
  const { setBackButtonOnClick, setBackButtonVisible, setMainButtonProps, setMainButtonVisible } = useScreen()
  const [ showList, setShowList ] = useState(false)

  const { data: eventtypes, isLoading: eventTypesAreLoading } = useEventTypeListQuery({})

  useEffect(() => {
    setBackButtonVisible(true)
    setBackButtonOnClick(() => navigate("/"))

    setMainButtonVisible(true)
    setMainButtonProps(t("features.eventtype.pages.share.mainButton"), () => navigate("/"))
  }, [ ])

  const handleShare = (eventType: EventType) => {
    const shareLink = `https://t.me/${window._env_.TG_APP_URL}?startapp=book-` + eventType?.hash
    const encodedUrl = encodeURIComponent(shareLink);
    webApp.openTelegramLink(`https://t.me/share/url?url=${encodedUrl}`)
    // switchInlineQuery(`book#${result?.hash}`, [ "users" ])
  }

  if (showList) return (
    <div className="p-5">
      <EventTypeOptionsList/>
    </div>
  )

  return (
    <div>

      <div className="mt-5 mb-6 px-5">
        <List title={t("page.home.on-off.title")}>
          <ListItem onClick={() => navigate("/eventtypes/create/on-off?type=custom")}>
            <div className="flex justify-between py-1">
              <div className="w-8">
                <i className="fa-solid fa-user-clock"/>
              </div>
              <div className="flex-1">
                {t("page.home.on-off.custom")}
              </div>
              <div className="w-8 text-right text-tg-theme-subtitle">
                <i className="fa-solid fa-chevron-right"/>
              </div>
            </div>
          </ListItem>
          <ListItem
            onClick={() => navigate(`/eventtypes/create/on-off?type=schedule&back=${encodeURIComponent("/eventtypes/share")}`)}>
            <div className="flex justify-between py-1">
              <div className="w-8">
                <i className="fa-solid fa-user-tie"/>
              </div>
              <div className="flex-1">
                {t("page.home.on-off.schedule")}
              </div>
              <div className="w-8 text-right text-tg-theme-subtitle">
                <i className="fa-solid fa-chevron-right"/>
              </div>
            </div>
          </ListItem>
        </List>
      </div>

      <div className="mt-5 mb-6 px-5">
        <List title={t("features.eventtype.pages.share.template-title")}>
          {(eventtypes || []).map((eventType, index) => {
            let iconClass = findIcoByKind(eventType.type)?.ico
            return (
              <ListItem key={index} onClick={() => handleShare(eventType)}>
                <div className="flex justify-between items-center py-1">
                  <div className="w-8">
                    <i className={iconClass}/>
                  </div>
                  <div className="flex-1 text-sm">
                    {eventType.name}
                  </div>
                  <div className="w-8 text-right text-tg-theme-subtitle">
                    <i className="fa-solid fa-share"/>
                  </div>
                </div>
              </ListItem>
            )
          })}
        </List>
        <div className="my-3 ms-2">
          <NavLink to="/eventtypes/create" className="flex text-sm text-tg-theme-accent-text">
            + {t("features.eventtype.pages.share.createEventTypeLink")}
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default EventTypeSharePage
