import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScheduleFormWeeklyHours } from "./scheduleWeeklyHours";
import { Screen, useScreen } from "context/screen.provider";
import { ScheduleFormDayOverrides } from "./scheduleDayOverrides";
import { useGetCalendarEventListQuery } from "app/services/calendar";
import { EventListParams, useEventListQuery } from "app/services/events";
import { useScheduleDeleteMutation } from "app/services/schedules";
import { Schedule, ScheduleRuleDay } from "app/types/schedule";
import { useHapticFeedback, useShowPopup } from "app/telegram";
import DatePicker from "widgets/components/datepicker";
import { Event } from "widgets/forms/timepicker/timepicker";
import Card from "widgets/components/card";
import Timezone from "widgets/forms/timezone";
import { useAccount } from "hooks/useAccount";
import { useTranslation } from "react-i18next";
import { handleError } from "utils";
import moment from "moment";

interface ScheduleProps {
  value: Schedule
  onSubmit: (v: Schedule) => void
}

const ScheduleForm = ({ value, onSubmit }: ScheduleProps) => {

  const { t } = useTranslation()
  const [ , , selectionChanged ] = useHapticFeedback();
  const account = useAccount()
  const navigate = useNavigate();
  const showPopup = useShowPopup()
  const [ scheduleValue, setScheduleValue ] = useState<Schedule>(value)
  const [ showDatepicker, setShowDatePicker ] = useState<boolean>(false)
  const [ day, setDay ] = useState<number>(moment().startOf("day").unix());
  const [ eventFilter, setEventFilter ] = useState<EventListParams>({
    from: moment().startOf("isoWeek").unix(),
    to: moment().endOf("isoWeek").unix(),
  })
  const { setMainButtonVisible, setBackButtonOnClick, setMainButtonProps, setScreen } = useScreen()

  const [ deleteSchedule, { isError: isDeleteError } ] = useScheduleDeleteMutation()

  const {
    data: events,
    refetch: eventRefetch,
    isLoading: isEventLoading
  } = useEventListQuery(eventFilter)
  const {
    data: calendarEvents,
    isLoading: isCalendarEventLoading,
    refetch: calendarEventRefetch,
  } = useGetCalendarEventListQuery(eventFilter)

  useEffect(() => {
    eventRefetch()
    calendarEventRefetch()
  }, [ eventFilter ])

  useEffect(() => {
    setScheduleValue(value)
  }, [ value ]);

  useEffect(() => {
    setMainButtonProps(scheduleValue.id
        ? t("features.schedule.forms.scheduleForm.mainButton.update")
        : t("features.schedule.forms.scheduleForm.mainButton.create"),
      () => handleSubmit())
  }, []);

  // При изменении состояния scheduleValue, требуется перерендераить MainButton.
  // Иначе не поменяется scheduleValue в функции handleSubmit
  useEffect(() => {
    if (showDatepicker) {
      setMainButtonProps(t("features.schedule.forms.scheduleForm.mainButton.close"), () => {
        setDay(moment().unix())
        setShowDatePicker(false)
        setMainButtonVisible(true)
        setMainButtonProps(scheduleValue.id
            ? t("features.schedule.forms.scheduleForm.mainButton.update")
            : t("features.schedule.forms.scheduleForm.mainButton.create"),
          () => handleSubmit())
      })

      setMainButtonVisible(true)
      setBackButtonOnClick(() => {
        setDay(moment().unix())
        setShowDatePicker(false)
        setScreen(undefined)
      })
      return
    }

    setMainButtonProps(scheduleValue.id
      ? t("features.schedule.forms.scheduleForm.mainButton.update")
      : t("features.schedule.forms.scheduleForm.mainButton.create"), handleSubmit)
    setMainButtonVisible(true)
    setBackButtonOnClick(() => navigate("/"))

  }, [ showDatepicker, scheduleValue ]);

  const handleShowDatepicker = () => {
    setShowDatePicker(true)
  }

  const handleSubmit = () => {
    selectionChanged()
    onSubmit(scheduleValue)
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setScheduleValue({ ...scheduleValue, name: e.target.value })
  }

  const handleChange = (v: Schedule) => {
    setScheduleValue(v)
  }

  const handleChangeTimezone = (v: string) => {
    setScheduleValue({ ...scheduleValue, timezone: v })
  }

  const handleChangeRules = (v: ScheduleRuleDay[]) => {
    const intervals = v.filter(i => !!i.intervals.length)
    let rules = scheduleValue.rules.filter((rule) => rule.type !== "date")
    rules = rules.concat(intervals)
    setScheduleValue({ ...scheduleValue, rules: rules })
  }

  const onDateRangeChange = (from: string, to: string) => {
    setEventFilter({
      from: moment(from).unix(),
      to: moment(to).unix()
    })
  }

  const handleDayChange = (v: string) => {
    setDay(moment(v).unix())
  }

  const showDeleteSchedulePopup = (id: string) => {
    showPopup({
      title: t("features.schedule.forms.scheduleForm.removeConfirmTitle"),
      message: t("features.schedule.forms.scheduleForm.removeConfirmDescription"),
      buttons: [
        { id: "close", type: "cancel", text: t("features.schedule.forms.scheduleForm.removeConfirmButtonCancel") },
        {
          id: "delete",
          type: "destructive",
          text: t("features.schedule.forms.scheduleForm.removeConfirmButtonRemove")
        },
      ]
    }).then(async buttonId => {
      if (buttonId === "delete") {
        try {
          const result = await deleteSchedule(id).unwrap()
          if (!isDeleteError && result) {
            navigate("/")
          }
        } catch (err) {
          handleError(err, "Failed to delete schedule")
        }
      }
    });
  }

  if (showDatepicker) {
    const rules = scheduleValue.rules.filter((rule) => rule.type === "date") as ScheduleRuleDay[]

    const makeDates = () => {
      return scheduleValue.rules.map((item: any) => {
        const date = moment(item.date, "YYYY-MM-DD").format("ll")
        return {
          date: date,
          status: "available",
        }
      })
    }

    return (
      <DatePicker day={day}
                  rules={rules}
                  dates={makeDates()}
                  events={[ ...(events || []), ...(calendarEvents || []) ] as Event[]}
                  onDateRange={onDateRangeChange}
                  onChange={handleChangeRules}
                  onChangeDay={handleDayChange}/>
    )
  }

  const screens = {
    timezone: <Timezone value={scheduleValue.timezone} onSelect={handleChangeTimezone}
                        timeFormat={account.account.format_time}/>,
  }

  return (
    <Screen screens={screens}>
      <div>
        <div className="my-8 mt-8 px-5 capitalize text-center">
          <p className="text-3xl font-medium text-tg-theme-text">
            {t("features.schedule.forms.scheduleForm.settings")}
          </p>
        </div>

        <div className="px-5 mb-5">
          <Card title={t("features.schedule.forms.scheduleForm.name")}>
            <input
              value={scheduleValue.name}
              className="w-full text-tg-theme-text bg-tg-theme-section focus:outline-none"
              onChange={handleNameChange}
            />
          </Card>
        </div>

        <div className="px-5 mb-5">
          <Card onClick={() => setScreen("timezone")} title={t("features.schedule.forms.scheduleForm.timezone")}>
            <div className="flex justify-between">
              <div className="flex-1">{t("features.eventtype.pages.info.details.timezone")}</div>
              <div className="text-tg-theme-subtitle">
                {scheduleValue.timezone}
              </div>
              <div className="w-8 text-right text-tg-theme-subtitle">
                <i className="fas fa-angle-right"/>
              </div>
            </div>
          </Card>
        </div>

        <div className="px-5 mb-5">
          <Card title={t("features.schedule.forms.scheduleForm.weekly-hours")}>
            <ScheduleFormWeeklyHours value={scheduleValue} onChange={handleChange}/>
          </Card>
        </div>

        <div className="px-5 mb-5">
          <Card title={t("features.schedule.forms.scheduleForm.overrides")} disabled={true}>
            <div className="w-full flex justify-center mb-4">
              <button className="py-1 px-10 rounded-md text-xs" /*onClick={() => handleShowDatepicker()}*/>
                {t("features.schedule.forms.scheduleForm.override-add")}
              </button>
            </div>

            <ScheduleFormDayOverrides schedule={scheduleValue}
                                      onSelectDay={(day) => {
                                        setDay(moment(day).startOf("day").unix())
                                        handleShowDatepicker()
                                      }}/>
          </Card>
        </div>


        {(!!scheduleValue.id) && (
          <div className="px-5 my-7">
            <Card
              hint={t("features.schedule.forms.scheduleForm.removeHint")}
              onClick={() => showDeleteSchedulePopup(scheduleValue.id)}>
              <div className="text-tg-theme-destructive">
                {t("features.schedule.forms.scheduleForm.removeButton")}
              </div>
            </Card>
          </div>
        )}
      </div>
    </Screen>
  )
}

export default ScheduleForm;
