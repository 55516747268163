import moment from "moment"

export const parseDuration = (duration: string): number => {
  let dt = moment.duration("PT" + duration?.toUpperCase())
  return dt.days() * 24 * 60 + dt.hours() * 60 + dt.minutes()
}

export const getColor = (type: string): string => {

  if (type === "one-off-meeting") {
    return "#C8E3FF"
  }

  if (type === "group") {
    return "#FFEFDD"
  }

  return "#E8F3EB"
}

export const getCDNURL = (file: string): string => {
  return !!window._env_ && !!window._env_?.CDN_HOST
    ? `${window._env_.CDN_HOST}/${file}`
    : `${window.location.origin}/cdn/${file}`;
}

export const generateWebAppLink = (query: string): string => {
  return !!window._env_ && !!window._env_?.TG_APP_URL
    ? `https://t.me/${window._env_.TG_APP_URL}?${query}`
    : `https://t.me/CalenDuckyBot/app?${query}`;
}

type TimeInput = string | number;

const parseTimeInput = (input: TimeInput): number => {
  if (typeof input === "number") {
    return input;
  }

  const timeRegex = /(\d+h)?(\d+m)?(\d+s)?/;
  const matches = timeRegex.exec(input);

  if (!matches) {
    throw new Error("Invalid time format");
  }

  const hours = matches[1] ? parseInt(matches[1], 10) : 0;
  const minutes = matches[2] ? parseInt(matches[2], 10) : 0;
  const seconds = matches[3] ? parseInt(matches[3], 10) : 0;

  return hours * 3600 + minutes * 60 + seconds;
};

interface TimeUnits {
  [key: string]: {
    hour: string;
    hours: string;
    minutes: string;
  };
}

const timeUnits: TimeUnits = {
  ru: { hour: "час", hours: "часа", minutes: "минут" },
  en: { hour: "hour", hours: "hours", minutes: "minutes" },
  fr: { hour: "heure", hours: "heures", minutes: "minutes" },
  de: { hour: "stunde", hours: "stunden", minutes: "minuten" },
  es: { hour: "hora", hours: "horas", minutes: "minutos" },
};

const timeShortUnits: TimeUnits = {
  ru: { hour: "ч", hours: "ч", minutes: "мин" },
  en: { hour: "hr", hours: "hrs", minutes: "min" },
  fr: { hour: "h", hours: "h", minutes: "min" },
  de: { hour: "std", hours: "std", minutes: "min" },
  es: { hour: "h", hours: "h", minutes: "min" },
};

export function formatMinutes(minutes: number, lang: string, short?: boolean) {
  const duration = moment.duration(minutes, "minutes").locale(lang);
  const hours = Math.floor(duration.asHours());
  const remainingMinutes = duration.minutes();
  const tmUnits = !short ? timeUnits : timeShortUnits
  const units = tmUnits[lang] || tmUnits["en"];

  let result = "";
  if (hours > 0) {
    result += `${hours} ${hours === 1 ? units.hour : units.hours}`;
  }
  if (remainingMinutes > 0) {
    if (hours > 0) {
      result += " ";
    }
    result += `${remainingMinutes} ${units.minutes}`;
  }

  return result;
}
