import React, { FC, useEffect, useState } from "react";
import { useScreen } from "context/screen.provider";
import { List, ListItem } from "widgets/components/list/list";
import { useTranslation } from "react-i18next";

export interface Props {
  onChange: (name: any, value: any) => void
  data: any
}

const AccountDateTimeForm: FC<Props> = ({ data, onChange }) => {

  const { t } = useTranslation()

  const [ dataValue, setDataValue ] = useState(data)
  const { setScreen } = useScreen();

  const handleSelectScreen = (screen: any) => {
    setScreen(screen)
  }

  useEffect(() => {
    setDataValue(data)
  }, [ data ])

  return (
    <List title={t("features.account.forms.accountDateTimeForm.title")}>
      <ListItem onClick={() => handleSelectScreen("formatdate")}>
        <div className="flex justify-between">
          <div className="flex-1">{t("features.account.forms.accountDateTimeForm.inputDate")}</div>
          <div className="text-tg-theme-subtitle">{dataValue.format_date}</div>
          <div className="w-8 text-right text-tg-theme-subtitle">
            <i className="fas fa-angle-right"/>
          </div>
        </div>
      </ListItem>
      <ListItem onClick={() => handleSelectScreen("formattime")}>
        <div className="flex justify-between">
          <div className="flex-1">{t("features.account.forms.accountDateTimeForm.inputTime")}</div>
          <div className="text-tg-theme-subtitle">{dataValue.format_time}</div>
          <div className="w-8 text-right text-tg-theme-subtitle">
            <i className="fas fa-angle-right"/>
          </div>
        </div>
      </ListItem>
    </List>
  )
}

export default AccountDateTimeForm;
