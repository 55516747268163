import React, { useEffect, useState } from "react";
import { useAccount } from "hooks/useAccount";
import { useScreen } from "context/screen.provider";
import {
  ScheduleCreateRequest,
  ScheduleUpdateRequest,
  useScheduleCreateMutation,
  useScheduleFetchQuery,
  useScheduleUpdateMutation
} from "app/services/schedules";
import { useNavigate, useParams } from "react-router-dom";
import { Schedule, ScheduleRuleWDay } from "app/types/schedule";
import ScheduleForm from "../forms/scheduleForm";
import ScheduleLoading from "../components/scheduleLoading";
import ScheduleNotFound from "../components/scheduleNotFound";
import { handleError } from "utils";
import { useHapticFeedback } from "../../../app/telegram";

const defaultSchedule: any = {
  name: "Work schedule",
  rules: []
}

const SchedulePage: React.FC = () => {

  const [ , , selectionChanged ] = useHapticFeedback();
  const { schedule_id } = useParams<{ schedule_id?: string; }>();
  const navigate = useNavigate();
  const auth = useAccount()
  const {
    screen,
    setBackButtonVisible,
    setBackButtonOnClick,
  } = useScreen();
  const [ createSchedule, {
    isLoading: isScheduleCreateLoading,
    isError: isScheduleCreateError
  } ] = useScheduleCreateMutation();
  const [ updateSchedule, {
    isLoading: isScheduleUpdateLoading,
    isError: isScheduleUpdateError
  } ] = useScheduleUpdateMutation();
  const {
    data: schedule,
    isError: isFetchError,
    isLoading: isFetchLoading
  } = useScheduleFetchQuery(schedule_id!, { skip: !schedule_id })

  const [ scheduleData, setScheduleData ] = useState<Schedule>(defaultSchedule)

  useEffect(() => {
    setBackButtonVisible(true)
    setBackButtonOnClick(() => navigate("/"))
  }, []);

  useEffect(() => {
    if (!!screen) return;
    setBackButtonVisible(true)
    setBackButtonOnClick(() => navigate("/"))
  }, [ screen ]);

  useEffect(() => {
    if (!schedule) return
    setScheduleData(schedule)
  }, [ schedule ]);

  const handleSubmit = async (v: Schedule) => {
    selectionChanged()

    if (!v.id) {
      v.profile_id = auth.profile.id
      v.timezone = auth.profile.timezone

      const daysOfWeek = [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday" ];
      const updatesRules = [ ...v.rules ]
      daysOfWeek.forEach((day) => {
        const existingRule = updatesRules.find(
          (rule) => rule.type === "wday" && (rule as ScheduleRuleWDay).wday === day
        );
        if (!existingRule) {
          updatesRules.push({
            type: "wday",
            wday: day,
            date: "",
            disabled: false,
            intervals: [],
          } as ScheduleRuleWDay);
        }
      });
      v.rules = updatesRules

      try {
        const result = await createSchedule(v as ScheduleCreateRequest).unwrap()
        if (!isScheduleCreateError && result) {
          // navigate(`/schedule/${result.id}`)
          navigate(-1)
        }
      } catch (err) {
        handleError(err, "Failed to create schedule")
      }
    } else {
      try {
        const result = await updateSchedule({ id: v.id, data: v as ScheduleUpdateRequest }).unwrap()
        if (!isScheduleUpdateError && result) {
          navigate(-1)
        }
      } catch (err) {
        handleError(err, "Failed to update schedule")
      }
    }
  }

  if (isFetchLoading) return <ScheduleLoading/>
  if (!!schedule_id && !schedule) return <ScheduleNotFound/>

  return (
    <ScheduleForm value={scheduleData} onSubmit={handleSubmit}/>
  )
}

export default SchedulePage
