import { useAccount } from "hooks/useAccount";
import { useTranslation } from "react-i18next";

const ScreenHello = () => {

  const { t } = useTranslation()
  const account = useAccount()

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-3xl font-bold mb-6">
        {t("features.welcome.components.screenHello.hello")}, <span
          className="capitalize">{account.account.first_name || account.account.username}!</span>
      </h1>

      <img src="/assets/welcome.png" className="w-1/2 h-auto"/>

      <div className="mt-5 px-8 text-center text-tg-theme-subtitle">
        {t("features.welcome.components.screenHello.desc")}
      </div>
    </div>
  )
}

export default ScreenHello
