import { ReactNode } from "react";
import { HomePage, SettingsPage } from "./pages";
import { EventInfoPage, EventPendingPage, EventRequestsPage } from "features/event/pages"
import EventTypePage from "features/eventtype/pages/eventtype";
import EventTypeListPage from "features/eventtype/pages/eventtype.list";
import EventTypeSharePage from "features/eventtype/pages/eventtype.share";
import BookingPage from "features/booking/pages/booking";
import CalendarSettingsPage from "./features/calendar/pages/calendar.settings";
import IntegrationSettingsPage from "./features/integration/pages/integration.settings";
import SchedulePage from "./features/schedule/pages/schedule";

export type RouteGroup = {
  layout: string,
  pages: Route[]
}

export type Route = {
  path: string,
  element: ReactNode
}

export const routes: RouteGroup[] = [
  {
    layout: "protected",
    pages: [
      {
        path: "/",
        element: <HomePage/>,
      },
      {
        path: "/settings",
        element: <SettingsPage/>,
      },
      {
        path: "/calendar",
        element: <CalendarSettingsPage/>,
      },
      {
        path: "/integration",
        element: <IntegrationSettingsPage/>,
      },
      {
        path: "/booking/:hash",
        element: <BookingPage/>,
      },
      {
        path: "/eventtypes/share",
        element: <EventTypeSharePage/>,
      },
      {
        path: "/eventtypes/create",
        element: <EventTypePage/>,
      },
      {
        path: "/eventtypes/create/:type",
        element: <EventTypePage/>,
      },
      {
        path: "/eventtype/:eventtype_id",
        element: <EventTypePage/>,
      },
      {
        path: "/eventtypes",
        element: <EventTypeListPage/>,
      },
      {
        path: "/event/:event_id",
        element: <EventInfoPage/>,
      },
      {
        path: "/events/pending",
        element: <EventPendingPage/>,
      },
      {
        path: "/event/requests",
        element: <EventRequestsPage/>,
      },
      {
        path: "/schedules/create",
        element: <SchedulePage/>,
      },
      {
        path: "/schedule/:schedule_id",
        element: <SchedulePage/>,
      },
    ]
  }
];

export default routes;
