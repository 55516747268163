import { FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useAccount } from "../hooks/useAccount"

import routes from "../routes";

const Main: FC = () => {

  const auth = useAccount()
  const location = useLocation()

  if (!auth.session) return <Navigate to="/loading" state={{ from: location }} />

  return (
    <div>
      <Routes>
        {routes.map(
          ({ layout, pages }) =>
            layout === "protected" &&
            pages.map(({ path, element }) => (
              <Route path={path} element={element} />
            ))
        )}
      </Routes>
    </div>
  )
}

export default Main;
