import { useNavigate } from "react-router-dom";
import React, { MouseEvent, useEffect } from "react";
import {  useScreen } from "context/screen.provider";
import { useTranslation } from "react-i18next";
import IntegrationLoading from "../components/integrationLoading";
import IntegrationFetchError from "../components/integrationFetchError";
import {
  useIntegrationDeleteMutation,
  useIntegrationListQuery,
  useLazyIntegrationConnectQuery
} from "app/services/integration";
import { List, ListItem } from "widgets/components/list/list";
import { useShowPopup, useWebApp } from "app/telegram";
import { Integration } from "app/types/integration";
import { handleError } from "utils";


const integrationOptions = [
  // {
  //   ico: <i className="fa-brands fa-google"/>,
  //   service: "google",
  //   title: "Google Meet",
  //   disabled: true
  //   connected: false
  // },
  {
    ico: <i className="fa-solid fa-headset"/>,
    service: "zoom",
    title: "Zoom Meeting",
    disabled: true,
    connected: false
  },
  {
    ico: <i className="fa-brands fa-microsoft"/>,
    service: "teams",
    title: "Microsoft Teams",
    disabled: true,
    connected: false
  },
  {
    ico: <i className="fa-solid fa-video"/>,
    service: "yandex",
    title: "Yandex.Telemost",
    disabled: true,
    connected: false
  }
]

const IntegrationSettingsPage = () => {

  const { t } = useTranslation();
  const { screen, setScreen, setBackButtonVisible, setBackButtonOnClick, setMainButtonVisible } = useScreen();
  const webApp = useWebApp()
  const navigate = useNavigate();
  const showPopup = useShowPopup()

  const [ triggerLogin, {
    data: loginData, isLoading: isLoginLoading, isError: isLoginError
  } ] = useLazyIntegrationConnectQuery();
  const [ deleteIntegration, {
    isLoading: isDeleteLoading,
    isError: isDeleteError
  } ] = useIntegrationDeleteMutation();
  const {
    data: integrations,
    refetch,
    isLoading: isIntegrationsLoading,
    isError: isIntegrationsError,
  } = useIntegrationListQuery({ service: "location" }, {
    // pollingInterval: 5000,
    // skipPollingIfUnfocused: true,
    // refetchOnMountOrArgChange: true,
    // refetchOnFocus: true,
  })

  useEffect(() => {
    setBackButtonVisible(true)
    setBackButtonOnClick(() => navigate("/settings"))
    setMainButtonVisible(false)
  }, []);

  useEffect(() => {
    if (screen) return
    setBackButtonVisible(true)
    setMainButtonVisible(false)
    setBackButtonOnClick(() => navigate("/settings"))
  }, [ screen ]);

  if (isIntegrationsLoading) return <IntegrationLoading/>;
  if (isIntegrationsError) return <IntegrationFetchError/>;

  const handleLogin = async (provider: string) => {
    const result = await triggerLogin(provider);
    if (result.data) {
      webApp.openLink(result.data.url);
    }
  };

  const showDeleteIntegrationPopup = (e: MouseEvent, id: string) => {
    e.stopPropagation()

    showPopup({
      title: t("features.integration.pages.settings.popupConfirmRemove.title"),
      message: t("features.integration.pages.settings.popupConfirmRemove.message"),
      buttons: [
        {
          id: "close",
          type: "cancel",
          text: t("features.integration.pages.settings.popupConfirmRemove.buttons.close")
        },
        {
          id: "delete",
          type: "destructive",
          text: t("features.integration.pages.settings.popupConfirmRemove.buttons.delete")
        },
      ]
    }).then(async buttonId => {
      if (buttonId === "delete") {
        try {
          const result = await deleteIntegration(id).unwrap()
          if (!isDeleteError && result) {
            refetch()
          }
        } catch (err) {
          handleError(err, "Failed to delete integration")
        }
      }
    });
  }

  const groupIntegrationByService = (integrations: Integration[]): Record<string, Integration> => {
    const groupedIntegrations: Record<string, Integration> = {};

    integrations.forEach(integration => {
      if (!groupedIntegrations[integration.service]) {
        groupedIntegrations[integration.service] = integration
      }
    });

    return groupedIntegrations;
  };

  const groupedIntegrations = groupIntegrationByService(integrations || []);

  return (
    <div className="p-5">

      <div className="my-8 mt-8 px-6 capitalize text-center">
        <p className="text-3xl font-medium text-tg-theme-text">
          {t("features.integration.pages.settings.title")}
        </p>
      </div>

      <List>
        {integrationOptions.map((i, index) => (
          <ListItem key={index}
            disabled={i.disabled}
            prefix={i.ico}>
            <div className="flex justify-between">
              <div className="flex-1">
                {i.title}
              </div>
              <div>
                {i.disabled
                    ? (
                      <div>
                        {t("features.integration.pages.settings.soon")}
                      </div>
                    )
                    : !!groupedIntegrations[i.service]
                      ? (
                        <button className="rounded-xl text-xs py-1 px-4 bg-tg-theme-danger"
                          onClick={(e) => showDeleteIntegrationPopup(e, groupedIntegrations[i.service].id)}>
                          {t("features.integration.pages.settings.removeButton")}
                        </button>
                      )
                      : (
                        <button className="rounded-xl text-xs py-1 px-4" onClick={() => handleLogin(i.service)}>
                          {t("features.integration.pages.settings.connectButton")}
                        </button>
                      )
                }
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default IntegrationSettingsPage
