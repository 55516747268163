import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useWebApp } from "app/telegram";
import { Profile } from "app/types/profile";
import i18n from "app/i18n";
import Preloader from "widgets/components/preloader";
import Oops from "widgets/components/oops";
import { handleError } from "utils";
import { setAccount, setCredentials, setProfile } from "features/auth/authSlice";
import { useLazyAccountFetchQuery } from "app/services/account";
import { useLazyProfileListQuery } from "app/services/profiles";
import { useCreateSessionMutation } from "app/services/session";
import { Session } from "app/types/session";

const Loader = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [ isAuthorizeFailed, setIsAuthorizeFailed ] = useState(false)

  const webApp = useWebApp()
  webApp.ready()
  webApp.expand()

  const [ createSession, sessionRequest ] = useCreateSessionMutation();
  const [ accountFetch, accountRequest ] = useLazyAccountFetchQuery();
  const [ profileList, profileRequest ] = useLazyProfileListQuery();

  const fetchAccount = useCallback(async (session: Session) => {
    try {
      dispatch(setCredentials(session));

      const account = await accountFetch({}).unwrap();

      dispatch(setAccount(account));

      await i18n.changeLanguage(account.language || "en");

      const profiles: Profile[] = await profileList({}).unwrap();
      const profile = profiles.slice().find(profile => profile.type === 'personal') || {} as Profile

      dispatch(setProfile(profile));

    } catch (err) {
      handleError(err, "Failed to fetch account")
      throw err
    }
  }, [ dispatch, accountFetch, profileList ]);

  const authorize = useCallback(async (data: string) => {
    try {
      const session = await createSession({
        init_data: data,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        locale: navigator.language.split("-")[0] || Intl.DateTimeFormat().resolvedOptions().locale,
      }).unwrap();

      await fetchAccount(session)

    } catch (err) {
      handleError(err, "Failed to create session")
      throw err
    }
  }, [ dispatch, createSession ]);

  useEffect(() => {
    if (webApp.initData) {
      authorize(webApp.initData)
        .then(() => {
          navigate("/");
        })
        .catch(err => {
          console.error("Unhandled authorization error:", err);
          setIsAuthorizeFailed(true)
        });
      return
    }

    const queryParams = new URLSearchParams(location.state.from.search);
    const session = { token: queryParams.get('access_token') } as Session

    dispatch(setCredentials(session))

    fetchAccount(session)
      .then(() => {
        navigate(location.state.from.pathname);
      })
      .catch(error => {
        console.error("Unhandled authorization error:", error);
        setIsAuthorizeFailed(true)
      });

  }, [ webApp.initData ]);

  if (isAuthorizeFailed) return (
    <div className="flex flex-col justify-center items-center h-[calc(100dvh)] max-w-72 m-auto">
      <Oops/>
    </div>
  )

  return <Preloader/>
};

export default Loader;
