import { useThemeParams, useWebApp } from "app/telegram";
import React, { createContext, useState, useContext, useEffect, ReactNode } from "react";

const ThemeContext = createContext<{ isDarkMode: boolean; toggleTheme: () => void } | undefined>(undefined);
type ThemeProviderProps = {
  children: ReactNode;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {

  const [ colorScheme ] = useThemeParams()
  const WebApp = useWebApp();

  const [ isDarkMode, setIsDarkMode ] = useState<boolean>(() => {
    return colorScheme === "dark";
  });

  useEffect(() => {
    const root = window.document.documentElement;
    const variableValue = getComputedStyle(root).getPropertyValue("--tg-theme-secondary-bg-color");

    if (colorScheme === "dark") {
      root.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      root.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }

    WebApp.setHeaderColor(variableValue)
  }, [ isDarkMode ]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

