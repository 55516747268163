import React, { FC, useEffect } from "react";
import { useScreen } from "context/screen.provider";
import { List, ListItem } from "widgets/components/list/list";
import { useHapticFeedback } from "app/telegram";
import { Schedule } from "app/types/schedule";
import { useTranslation } from "react-i18next";

export type AvailabilityType = "schedule" | "custom"

interface AvailabilityProps {
  allowCustom: boolean;
  schedules: Schedule[]
  onSubmit?: (type: AvailabilityType, data: any) => void
}

const Availability: FC<AvailabilityProps> = ({
  allowCustom,
  schedules = [],
  onSubmit
}) => {
  const { t } = useTranslation()
  const [ , , selectionChanged ] = useHapticFeedback();
  const {
    setScreen,
    setMainButtonVisible,
    isVisibleMainButton
  } = useScreen();

  useEffect(() => {
    const preVisibleState = isVisibleMainButton;
    setMainButtonVisible(false)
    return () => {
      setMainButtonVisible(preVisibleState)
    }
  }, []);

  const handleChange = (type: AvailabilityType, data: any) => {
    selectionChanged();
    setScreen(undefined)
    onSubmit && onSubmit(type, data)
  }

  return (
    <div className="p-5">
      <List title={t("features.eventtype.forms.availabilityForm.useSchedule")} className="mb-6">
        {schedules.map((item, index) => (
          <ListItem key={index} onClick={() => handleChange("schedule", item.id)}>
            <div className="flex items-center justify-between">
              <span>{item.name}</span>
              <div className="w-8 text-right text-tg-theme-subtitle">
                <i className="fa-solid fa-chevron-right"/>
              </div>
            </div>
          </ListItem>
        ))}
      </List>

      {allowCustom && (
        <List title={t("features.eventtype.forms.availabilityForm.useDateAndTime")}>
          <ListItem onClick={() => handleChange("custom", null)}>
            <div className="flex items-center justify-between">
              <span className="capitalize">{t("features.eventtype.forms.availabilityForm.custom")}</span>
              <div className="w-8 text-right text-tg-theme-subtitle">
                <i className="fa-solid fa-chevron-right"/>
              </div>
            </div>
          </ListItem>
        </List>
      )}
    </div>
  )
}

export default Availability;
