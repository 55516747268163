import { EventType } from "app/types/eventType";
import { Schedule } from "app/types/schedule";
import { NavLink } from "react-router-dom";
import { EventIcon } from "widgets/components/eventicon/eventicon";
import Card from "widgets/components/card";
import { ListItem } from "widgets/components/list/list";
import { useTranslation } from "react-i18next";

export interface EventTypeItemProps {
  eventtype: EventType,
  schedule?: Schedule,
  onSelect?: (id: string) => void
}

export const EventTypeItemVertical = ({ eventtype, onSelect }: EventTypeItemProps) => {

  const { t } = useTranslation();
  const onClick = () => {
    onSelect && onSelect(eventtype.id)
  }

  return (
    <Card onClick={onClick}>
      <div className="w-full p-5">
        <div className="flex flex-row flex-nowrap my-5">
          <div className="w-12 h-12">
            <EventIcon kind={eventtype.type}></EventIcon>
          </div>
          <div className="ml-3 flex-grow flex-row w-full overflow-hidden">
            <div className="w-[4.5rem]">
              <span className="inline-flex gap-1">
                <i className="fa-regular fa-clock text-inherit" />
                <p className="h-5 text-xs font-bold text-tg-theme-hint">{eventtype.duration}</p>
              </span>
            </div>
            <div className="w-[4.5rem]">
              <span className="inline-flex gap-1">
                <i className="fa-regular fa-calendar text-inherit" />
                <p className="h-5 text-xs font-bold text-tg-theme-hint">
                  {eventtype.invitees_counter.limit - eventtype.invitees_counter.total}
                  {t("features.eventtype.components.eventTypeItem.vertical.slots")}
                </p>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-row truncate pt-2">
          <p color="blue-gray" className="truncate normal-case">
            {eventtype.name}
          </p>
        </div>
        <div className="">
          <span className="inline-flex gap-1">
            <i className="fa-solid fa-street-view text-inherit" />
            <p className="h-6 text-xs font-bold text-tg-theme-hint">{eventtype.type}</p>
          </span>
        </div>
        <div className="pt-3">
          <p className="text font-bold text-xs">
            <NavLink to={"/booking/{booking.hash}/link"} className="text-blue-500">
              {t("features.eventtype.components.eventTypeItem.vertical.view-booking")}
            </NavLink>
          </p>
        </div>
      </div>
      <div className="p-3 text-center mt-3 mb-2">
        <button className="rounded-xl m-auto py-3 px-6">
          {t("features.eventtype.components.eventTypeItem.vertical.share-link")}
        </button>
      </div>
    </Card>
  )
}

export const EventTypeItemHorizontal = ({ eventtype, onSelect }: EventTypeItemProps) => {

  const onClick = () => {
    onSelect && onSelect(eventtype.id)
  }

  return (
    <ListItem onClick={onClick}>
      <div className="w-full">
        <div className="flex flex-row flex-nowrap">
          <div className="w-12 h-12">
            <EventIcon kind={eventtype.type}></EventIcon>
          </div>
          <div className="ml-3 flex-grow flex-col w-full overflow-hidden">
            <div className="flex flex-row truncate">
              <p color="blue-gray" className="truncate normal-case">
                {eventtype.name}
              </p>
            </div>
            <div className="flex justify-start flex-row gap-2">
              <div className="w-[4.5rem]">
                <span className="inline-flex gap-1">
                  <i className="fa-regular fa-clock text-tg-theme-hint"/>
                  <p className="h-6 text-xs font-bold text-tg-theme-hint">{eventtype.duration}</p>
                </span>
              </div>
              <div className="">
                <span className="inline-flex gap-1">
                  <i className="fa-solid fa-street-view text-tg-theme-hint" />
                  <p className="h-6 text-xs font-bold text-tg-theme-hint">{eventtype.type}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  )
}
