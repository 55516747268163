import React, { useEffect } from "react";
import { useHapticFeedback } from "app/telegram";
import { List, ListItem } from "../../components/list/list";
import { useScreen } from "../../../context/screen.provider";

const durationOptions =
  [
    { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
    { value: "YYYY/MM/DD", label: "YYYY/MM/DD" },
  ]

interface DurationProps {
  value: string
  onSelect?: (v: string) => void
}

const FormatDate = ({ value, onSelect }: DurationProps) => {
  const [ , , selectionChanged ] = useHapticFeedback();
  const { setScreen, setMainButtonVisible, isVisibleMainButton } = useScreen();

  useEffect(() => {
    const preVisibleState = isVisibleMainButton;
    setMainButtonVisible(false)
    return () => {
      setMainButtonVisible(preVisibleState)
    }
  }, []);

  const handleChange = (v: string) => {
    selectionChanged();
    onSelect && onSelect(v)
    setScreen(undefined)
  }

  return (
    <div className="p-5">
      <List>
        {durationOptions.map((item, index) => (
          <ListItem key={index} onClick={() => handleChange(item.value)}>
            <div className="flex items-center justify-between">
              <span>{item.label}</span>
              <div className="w-8 text-right text-tg-theme-subtitle">
                <i className="fa-solid fa-chevron-right"/>
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default FormatDate;
