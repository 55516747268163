import { Location } from "./location"
import { ScheduleRule } from "./schedule"

export type EventType = {
  id: string,

  profile: {
    id: string
    name: string
    avatar: string
    slug: string
  },

  hosts: EventTypeHost[],

  hash: string,
  name: string,
  type: string,
  secret: boolean,
  timezone: string,

  note?: string,
  duration: string,

  start_date?: string,
  end_date?: string,

  locations: Location[],

  invitees_counter: {
    total: number,
    limit: number,
  }

  invitees?: {
    account_id: string,
    name: string,
    username: string
  }[],

  status: string,
  created: number,
  updated: number,
}

export type EventTypeHost = {
  account_id: string,
  name: string,
  avatar: string,
  role: string,
  schedule_id?: string,
  rules?: ScheduleRule[],
  remind?: string
}

export const eventTypeGeneralList = {
  // "one-off": [
  //   {
  //     id: "one-off-meeting-custom",
  //     ico: "fa-solid fa-user-clock",
  //     kind: "one-off-meeting",
  //     path: "/eventtypes/create/on-off?type=custom",
  //     prefix: "custom",
  //     disabled: false
  //   },
  //   {
  //     id: "one-off-meeting-schedule",
  //     ico: "fa-solid fa-user-tie",
  //     kind: "one-off-meeting",
  //     path: "/eventtypes/create/on-off?type=schedule",
  //     prefix: "schedule",
  //     disabled: false
  //   },
  // ],
  "regular": [
    {
      id: "one-on-one",
      ico: "fa-solid fa-people-arrows",
      kind: "one-on-one",
      path: "/eventtypes/create/solo",
      prefix: "",
      disabled: false
    },
    {
      id: "meeting-poll",
      ico: "fa-solid fa-users-line",
      kind: "meeting-poll",
      path: "/eventtypes/create/meeting-poll",
      prefix: "",
      disabled: true
    },
    {
      id: "group",
      ico: "fa-solid fa-user-group",
      kind: "group",
      path: "/eventtypes/create/group",
      prefix: "",
      disabled: true
    },
    {
      id: "collective",
      ico: "fa-solid fa-people-group",
      kind: "collective",
      path: "/eventtypes/create/collective",
      prefix: "",
      disabled: true
    },
    {
      id: "round-robin",
      ico: "fa-solid fa-street-view",
      kind: "round-robin",
      path: "/eventtypes/create/round-robin",
      prefix: "",
      disabled: true
    }
  ]
}
