import React, { ChangeEvent, useEffect, useState } from "react";
import { useHapticFeedback } from "app/telegram";
import { List, ListItem } from "widgets/components/list/list";
import { useScreen } from "context/screen.provider";
import { useTranslation } from "react-i18next";
import { FormatTime } from "app/types/common";
import { timezones } from "./timezones";

interface TimezoneProps {
  value: string;
  onSelect?: (v: string) => void;
  timeFormat?: FormatTime;
}

const Timezone = ({ value, onSelect, timeFormat = "24h" }: TimezoneProps) => {
  const [ , , selectionChanged ] = useHapticFeedback();
  const [ filter, setFilter ] = useState("");
  const [ timezoneValue, setTimezoneValue ] = useState(value);
  const [ timeFormatValue, setTimeFormatValue ] = useState(timeFormat);
  const { setScreen, setMainButtonVisible, isVisibleMainButton } = useScreen();
  const [ currentTimes, setCurrentTimes ] = useState<{ [key: string]: string }>({});
  const { t } = useTranslation();

  useEffect(() => {
    const preVisibleState = isVisibleMainButton;
    setMainButtonVisible(false);
    return () => {
      setMainButtonVisible(preVisibleState);
    };
  }, []);

  useEffect(() => {
    setTimezoneValue(value);
  }, [ value ]);

  useEffect(() => {
    setTimeFormatValue(timeFormat);
  }, [ timeFormat ]);

  useEffect(() => {
    // Function to update time in all timezones
    const updateTimes = () => {
      const newTimes: { [key: string]: string } = {};
      timezones.forEach((region) => {
        region.zone.forEach((item) => {
          newTimes[item] = getTimeInZone(item);
        });
      });
      setCurrentTimes(newTimes);
    };

    // Initial time update
    updateTimes();

    // Update every minute
    const intervalId = setInterval(updateTimes, 1000);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [ timeFormatValue ]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value.toLowerCase());
  };

  const handleSelect = (v: string) => {
    selectionChanged();
    onSelect && onSelect(v);
    setScreen(undefined);
  };

  const getTimeInZone = (timezone: string) => {
    return new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: timeFormatValue === "12h",
      timeZone: timezone,
    });
  };

  return (
    <div className="container w-full h-full overflow-y-auto p-5">
      <div className="flex justify-center mb-4">
        <div className="w-full">
          <input
            type="text"
            className="w-full p-3 text-tg-theme-text bg-tg-theme-section focus:outline-none"
            placeholder={t("widgets.forms.timezone.search")}
            onChange={handleSearch}
          />
        </div>
      </div>

      <div>
        {timezones.map((region) => (
          region.zone.filter(
            (item) =>
              item.toLowerCase().includes(filter.toLowerCase()) ||
              item.toLowerCase().includes(filter.toLowerCase())
          ).length > 0 && (
            <div key={region.name}>
              <div className="uppercase text-sm px-2 mt-3 mb-2 text-tg-theme-hint truncate">
                {t("common.timezone." + region.name + ".name")}
              </div>
              <List>
                {region.zone.filter(
                  (item) =>
                    item.toLowerCase().includes(filter.toLowerCase()) ||
                    item.toLowerCase().includes(filter.toLowerCase())
                ).map((item, index) => (
                  <ListItem key={index} onClick={() => handleSelect(item)}>
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row content-center">
                        <div className="truncate">
                          <div>{t("common.timezone." + region.name + ".zone." + item)}</div>
                          <div className="text-sm text-tg-theme-accent-text">{currentTimes[item]}</div>
                        </div>
                      </div>
                      <div className="w-8 text-right text-tg-theme-subtitle">
                        <i className="fas fa-angle-right"/>
                      </div>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default Timezone;
