import { TimeSpot } from "./timepicker";
import { ScheduleRuleInterval } from "app/types/schedule";
import { parseDuration } from "./timespot";
import moment from "moment";

export const newTimeSpot = (time: string, duration: string): TimeSpot => {
  return {
    start: time,
    duration: duration,
  }
}

export const getTimeSpotsFromDayIntervals = (intervals: ScheduleRuleInterval[] = []): TimeSpot[] => {
  return intervals.map(interval => {
    const duration = moment.utc(moment(interval.to, "HH:mm")
      .diff(moment(interval.from, "HH:mm")))
      .format("H[h]m[m]");
    return newTimeSpot(interval.from, duration);
  })
}

export const getDayIntervalFromTimeSpot = (spot: TimeSpot): ScheduleRuleInterval => {
  const duration = parseDuration(spot.duration)
  const start = moment(spot.start, "HH:mm").format("HH:mm");
  const end = moment(spot.start, "HH:mm").add(duration, "minutes").format("HH:mm")
  return { from: start, to: end }
}

export function splitTimeSpots(defaultSpots: TimeSpot[], spots: TimeSpot[]): TimeSpot[] {
  // Convert duration string to minutes
  const parseDuration = (duration: string): number => {
    const match = duration.match(/(\d+)h(?:0*(\d+)m)?/);
    if (!match) return 0;
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2] || "0", 10);
    return hours * 60 + minutes;
  };

  // Normalize time spots
  const normalizeTimeSpots = (spots: TimeSpot[]): { start: moment.Moment; end: moment.Moment }[] => {
    return spots.map((spot) => {
      const start = moment(spot.start, "HH:mm");
      const end = start.clone().add(parseDuration(spot.duration), "minutes");
      return { start, end };
    });
  };

  const normalizedDefaultSpots = normalizeTimeSpots(defaultSpots);
  const normalizedSpots = normalizeTimeSpots(spots);

  // Check if two intervals overlap
  const intervalsOverlap = (interval1: { start: moment.Moment; end: moment.Moment }, interval2: {
    start: moment.Moment;
    end: moment.Moment
  }): boolean => {
    return interval1.start.isBefore(interval2.end) && interval1.end.isAfter(interval2.start);
  };

  // Split time spot if it overlaps with any of the spots
  const splitTimeSpot = (timeSpot: { start: moment.Moment; end: moment.Moment }, spots: {
    start: moment.Moment;
    end: moment.Moment
  }[]): { start: moment.Moment; end: moment.Moment }[] => {
    const result: { start: moment.Moment; end: moment.Moment }[] = [];
    let currentStart = timeSpot.start;

    for (const spot of spots) {
      if (intervalsOverlap({ start: currentStart, end: timeSpot.end }, spot)) {
        if (currentStart.isBefore(spot.start)) {
          result.push({ start: currentStart, end: spot.start });
        }
        currentStart = moment.max(currentStart, spot.end);
      }
    }

    if (currentStart.isBefore(timeSpot.end)) {
      result.push({ start: currentStart, end: timeSpot.end });
    }

    return result;
  };

  // Process each default spot and split if necessary
  const result: TimeSpot[] = [];
  for (const spot of normalizedDefaultSpots) {
    let remaining = [ spot ];
    for (const s of normalizedSpots) {
      const newRemaining: { start: moment.Moment; end: moment.Moment }[] = [];
      for (const r of remaining) {
        newRemaining.push(...splitTimeSpot(r, [ s ]));
      }
      remaining = newRemaining;
    }
    for (const r of remaining) {
      const start = r.start.format("HH:mm");
      const durationMinutes = r.end.diff(r.start, "minutes");
      const durationHours = Math.floor(durationMinutes / 60);
      const durationRemainderMinutes = durationMinutes % 60;
      const duration = `${durationHours.toString().padStart(2, "0")}h${durationRemainderMinutes.toString().padStart(2, "0")}m`;
      result.push({ start, duration });
    }
  }

  return result;
}

export function formatDuration(start: string, end: string): string {
  const startTime = moment(start);
  const endTime = moment(end);

  // Расчет разницы в миллисекундах
  const durationMs = endTime.diff(startTime);

  // Создание объекта длительности Moment.js
  const duration = moment.duration(durationMs);

  // Форматирование длительности в указанном формате
  const hours = duration.hours();
  const minutes = duration.minutes();

  // Формируем строку длительности
  let durationString = "";
  if (hours > 0) {
    durationString += hours + "h ";
  }
  if (minutes > 0 || (hours === 0 && duration.asSeconds() > 0)) {
    durationString += (minutes < 10 && hours > 0 ? "0" : "") + minutes + "m";
  }

  return durationString.trim(); // Обрезаем лишние пробелы
}


