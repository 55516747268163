import { Schedule, ScheduleRuleDay, ScheduleRuleInterval } from "app/types/schedule";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ScheduleFormDayOverridesProps {
  schedule: Schedule,
  onSelectDay?: (date: string) => void
}

export const ScheduleFormDayOverrides = ({ schedule, onSelectDay }: ScheduleFormDayOverridesProps) => {

  const { t } = useTranslation()

  let days: Record<string, ScheduleRuleInterval[]> = {}
  let rules: ScheduleRuleDay[] = []

  schedule.rules.map((rule) => {
    if (rule.type === "date" && !!rule.intervals.length) {
      rules.push(rule as ScheduleRuleDay)
    }
  })

  rules.map((rule) => {
    if (!days[rule.date]) {
      days[rule.date] = []
    }
    days[rule.date].push(...rule.intervals)
    return rule
  })

  if (!rules?.length) return (
    <div className="w-full text-center text-tg-theme-subtitle">
      {t("features.schedule.forms.scheduleForm.scheduleDayOverrides.no-rules")}
    </div>
  )

  return (
    <div className="flex flex-wrap gap-4">
      {Object.keys(days)
        .sort((left, right) => moment(left).diff(moment(right)))
        .map((date) => (
          <div
            key={"schedule-rule-group-" + date}
            className="flex flex-col"
            onClick={() => onSelectDay ? onSelectDay(date) : null}>
            <div className="mb-2">
              <div className="mb-2">
                {moment(date).format("dddd, DD MMM YYYY")}
              </div>
              <div className="grid grid-cols-3 gap-2 text-center w-auto">
                {(days[date] as ScheduleRuleInterval[]).map((interval, index) => (
                  <div key={index} className="border border-tg-theme-accent-text rounded">
                    <div className="px-1 text-nowrap text-xs">
                      {interval.from} - {interval.to}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
