import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";

interface ScreenContextType {
  screen: string | undefined;
  setScreen: (screen: string | undefined) => void;

  isVisibleMainButton: boolean;
  setMainButtonProps: (text: string, onClick: () => void) => void;
  setMainButtonVisible: (visible: boolean) => void;
  setMainButtonDisabled: (disabled: boolean) => void;

  isVisibleBackButton: boolean;
  setBackButtonVisible: (visible: boolean) => void;
  setBackButtonOnClick: (onClick: () => void) => void;
}

const ScreenContext = createContext<ScreenContextType | undefined>(undefined);

export interface ScreenProviderProps {
  children: ReactNode;
}

export const ScreenProvider = ({ children }: ScreenProviderProps) => {
  const Telegram = window.Telegram ? window.Telegram.WebApp : {};

  const [ isReady, setIsReady ] = useState<boolean>(false);
  const [ screen, setScreen ] = useState<string | undefined>(undefined);
  const [ mainButtonClickHandler, setMainButtonClickHandler ] = useState<() => void | null>();
  const [ backButtonClickHandler, setBackButtonClickHandler ] = useState<() => void | null>();

  useEffect(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    if (mainButtonClickHandler) {
      Telegram.MainButton.onClick(mainButtonClickHandler);
      return () => {
        Telegram.MainButton.offClick(mainButtonClickHandler);
      };
    }
  }, [ mainButtonClickHandler ]);

  useEffect(() => {
    if (backButtonClickHandler) {
      Telegram.BackButton.onClick(backButtonClickHandler);
      return () => {
        Telegram.BackButton.offClick(backButtonClickHandler);
      };
    }
  }, [ backButtonClickHandler ]);

  const setMainButtonProps = useCallback((text: string, onClick: () => void) => {
    setMainButtonClickHandler(() => onClick);
    Telegram.MainButton.text = text.toUpperCase();
    Telegram.MainButton.isVisible = !!(text && onClick);
  }, []);

  const setMainButtonVisible = (visible: boolean) => {
    Telegram.MainButton.isVisible = visible;
  };
  const setMainButtonDisabled = (disabled: boolean) => {
    if (disabled) {
      Telegram.MainButton.disable();
      return
    }
    Telegram.MainButton.enable()
  };

  const setBackButtonVisible = (visible: boolean) => {
    Telegram.BackButton.isVisible = visible;
  };

  const setBackButtonOnClick = useCallback((onClick: () => void) => {
    setBackButtonClickHandler(() => onClick);
  }, []);

  if (!isReady) return <div/>;

  return (
    <ScreenContext.Provider
      value={{
        screen,
        setScreen,
        isVisibleMainButton: Telegram.MainButton.isVisible,
        setMainButtonVisible,
        setMainButtonDisabled,
        setMainButtonProps,
        isVisibleBackButton: Telegram.BackButton.isVisible,
        setBackButtonVisible,
        setBackButtonOnClick,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreen = () => {
  const context = useContext(ScreenContext);
  if (!context) {
    throw new Error("useScreen must be used within a ScreenProvider");
  }
  return context;
};

export interface ScreenProps {
  screens?: { [key: string]: ReactNode };
  children: ReactNode;
  className?: string;
  disableBack?: boolean;
}

export const Screen = ({ children, screens = {}, disableBack, className }: ScreenProps) => {
  const { screen, setScreen, setBackButtonVisible, setBackButtonOnClick } = useScreen();

  useEffect(() => {
    if (disableBack || !screen) return;
    setBackButtonVisible(true);
    setBackButtonOnClick(() => setScreen(undefined));
  }, [ screen ]);

  useEffect(() => {
    // Отключаем скролл при рендере screen и
    // включаем скролл, если screen не отображается
    document.body.style.overflow = !!screen ? "hidden" : "";

    return () => {
      // Включаем скролл при размонтировании компонента
      document.body.style.overflow = "";
    };
  }, [ screen ]);

  return (
    <>
      <div className="h-screen overflow-auto">{children}</div>

      {(screens && screen && screens[screen]) && (
        <div className="fixed inset-0 z-50 flex flex-col bg-tg-theme-secondary">
          <div className="flex-1 max-h-screen overflow-auto">
            <div className={className}>
              {screens[screen]}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const withScreenProvider = (Component: React.ComponentType) => {
  const WrappedComponent: React.FC = (props) => {
    return (
      <ScreenProvider>
        <Component {...props} />
      </ScreenProvider>
    );
  };

  return WrappedComponent;
};
