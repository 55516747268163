import React, { FC, useEffect, useState } from "react";
import { useScreen } from "context/screen.provider";
import ScreenHello from "../components/screenHello";
import ScreenProfile, { AccountInfo } from "../components/screenProfile";
import ScreenAvailability from "../components/screenAvailability";
import { AccountUpdateRequest, useAccountUpdateMutation } from "app/services/account";
import { useAccount } from "hooks/useAccount";
import { Schedule, ScheduleRule } from "app/types/schedule";
import {
  ScheduleCreateRequest,
  ScheduleUpdateRequest,
  useScheduleCreateMutation,
  useScheduleListQuery,
  useScheduleUpdateMutation
} from "app/services/schedules";
import ScreenDone from "../components/screenDone";
import { useTranslation } from "react-i18next";
import { handleError } from "utils";

export interface Props {
  onClose(): void
}

const WelcomeForm: FC<Props> = ({ onClose }) => {

  const { t } = useTranslation()
  const auth = useAccount()
  const { data: schedules } = useScheduleListQuery({ profile_id: auth.profile.id })
  const [ createSchedule, {
    isLoading: isScheduleCreateLoading,
    isError: isScheduleCreateError
  } ] = useScheduleCreateMutation();
  const [ updateSchedule, {
    isLoading: isScheduleUpdateLoading,
    isError: isScheduleUpdateError
  } ] = useScheduleUpdateMutation();

  const [ currentStep, setCurrentStep ] = useState(0)
  const { setBackButtonVisible, setBackButtonOnClick, setMainButtonVisible, setMainButtonProps } = useScreen();
  const [ accountInfo, setAccountInfo ] = useState<AccountInfo>(auth.account)
  const [ schedule, setSchedule ] = useState<Schedule>()
  const [ updateAccount, { isLoading, isError } ] = useAccountUpdateMutation();

  useEffect(() => {
    schedules && schedules.length && setSchedule(schedules[0])
  }, [ schedules ]);

  useEffect(() => {
    if (currentStep + 1 === screens.length) {
      setMainButtonProps(t("features.welcome.forms.welcomeForm.buttons.done"), () => {
        onClose()
      })
      return
    }
  }, [ currentStep ]);

  useEffect(() => {
    setBackButtonVisible(currentStep !== 0)
    setBackButtonOnClick(prevStep)
    setMainButtonVisible(true)

    if (currentStep === 0) {
      setMainButtonProps(t("features.welcome.forms.welcomeForm.buttons.continue"), nextStep)
    }
    if (currentStep === 1) {
      setMainButtonProps(t("features.welcome.forms.welcomeForm.buttons.continue"), () => {
        updateProfile().then(nextStep)
      })
    }
    if (currentStep === 2) {
      setMainButtonProps(t("features.welcome.forms.welcomeForm.buttons.continue"), () => {
        handleScheduleChange().then(nextStep)
      })
    }
  }, [ currentStep, accountInfo, schedule ]);

  const nextStep = () => {
    if (currentStep + 1 === screens.length) {
      return
    }
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleAccountInfoChange = (d: AccountInfo) => {
    setAccountInfo(d)
  }

  const handleAvailabilityChange = (data: { name: string, rules: ScheduleRule[] }) => {
    const s = {
      ...schedule,
      name: data.name,
      rules: data.rules,
      profile_id: auth.profile.id,
      timezone: auth.profile.timezone
    }
    setSchedule(s as Schedule)
  }

  const updateProfile = async () => {
    try {
      const data = { ...auth.account, ...accountInfo }
      const result = await updateAccount(data as AccountUpdateRequest).unwrap()
      if (!isError && result) {
        setAccountInfo(result)
      }
    } catch (err) {
      handleError(err, "Failed to update account")
    }
  };

  const handleScheduleChange = async () => {
    if (!schedule?.id) {
      try {
        const result = await createSchedule(schedule as ScheduleCreateRequest).unwrap()
        if (!isScheduleCreateError && result) {
          setSchedule(result)
        }
      } catch (err) {
        handleError(err, "Failed to create schedule")
      }
    } else {
      try {
        const result = await updateSchedule({ id: schedule.id, data: schedule as ScheduleUpdateRequest }).unwrap()
        if (!isScheduleUpdateError && result) {
          setSchedule(result)
        }
      } catch (err) {
        handleError(err, "Failed to update schedule")
      }
    }
  }

  const screens = [
    <ScreenHello/>,
    <ScreenProfile data={accountInfo} onChange={handleAccountInfoChange}/>,
    <ScreenAvailability data={schedule} onChange={handleAvailabilityChange}/>,
    <ScreenDone/>
  ]

  return (
    <div className="w-full min-h-screen">
      {screens[currentStep]}
    </div>
  )
}

export default WelcomeForm
