export interface RemindOption {
  label: string;
  value: string;
}

export const remindOptions: RemindOption[] = [
  { value: "15m", label: "15 min" },
  { value: "30m", label: "30 min" },
  { value: "45m", label: "45 min" },
  { value: "60m", label: "60 min" },
  { value: "90m", label: "1 hour 30 min" },
  { value: "120m", label: "2 hour" },
]
