import React from "react";
import { List, ListItem } from "../list/list";
import { Schedule } from "app/types/schedule";
import { useTranslation } from "react-i18next";

interface ScheduleListProps {
  schedules?: Schedule[]
  onSelect: (id: string) => void
}

const ScheduleList: React.FC<ScheduleListProps> = ({ schedules = [], onSelect }) => {
  const { t } = useTranslation();

  return (
    <List title={t("widgets.components.scheduleList.title")}>
      {schedules?.map((item, index) => (
        <ListItem key={index} onClick={() => onSelect(item.id)}>
          <div className="flex items-center justify-between">
            <span>{item.name}</span>
            <div className="w-8 text-right text-tg-theme-subtitle">
              <i className="fa-solid fa-chevron-right"/>
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  )
}

export default ScheduleList
