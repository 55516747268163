import { Location, LocationTypes } from "app/types/location"

export function getLocationFromResponse(src: LocationResponse): Location {
  return {
    ...src
  } as Location
}

export function getLocationsFromResponse(src: LocationResponse[]): Location[] {
  return src.map((location) => getLocationFromResponse(location))
}

export type LocationResponse = LocationOnlineResponse
  | LocationInPersonResponse
  | LocationAskInviteeResponse
  | LocationPhoneCallResponse
  | LocationTelegramCallResponse

export type LocationOnlineResponse = {
  kind: typeof LocationTypes.Google
    | typeof LocationTypes.Zoom
    | typeof LocationTypes.Teams
    | typeof LocationTypes.Yandex
  link: string,
}

export type LocationInPersonResponse = {
  kind: typeof LocationTypes.InPerson;
  name: string
  lat: string
  lng: string
}

export type LocationAskInviteeResponse = {
  kind: typeof LocationTypes.AskInvitee;
  info: string
}

export type LocationPhoneCallResponse = {
  kind: typeof LocationTypes.AskInvitee;
  outbound: boolean
  phone: string
}

export type LocationTelegramCallResponse = {
  kind: typeof LocationTypes.AskInvitee;
  outbound: boolean
  video: boolean
}
