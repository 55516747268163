import { useNavigate } from "react-router-dom";
import { eventTypeGeneralList } from "app/types/eventType";
import { List, ListItem } from "widgets/components/list/list";
import { useTranslation } from "react-i18next";

const EventTypeOptionsList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  const handleNavigate = (url: string) => {
    url && navigate(url)
  }

  return (
    <>
      {Object.entries({ ...eventTypeGeneralList })
        .map(([ category, eventTypes ]) => {
          return (
            <List key={category} className="mb-5" /*title={t("common.eventtypes.groups." + category + ".title")}*/>
              {eventTypes.map((item, index) => {
                return (
                  <ListItem key={index} disabled={item.disabled} onClick={() => handleNavigate(item.path!)}>
                    <div className="flex justify-center items-center min-h-10">
                      <div>
                        <i className={item.ico}/>
                      </div>
                      <div className="flex-auto px-4">
                        <p color="blue-gray h5">
                          {t("common.eventtypes.groups." + category + "." + item.id + ".title")}
                        </p>
                        <p color="gray" className="text-sm">
                          {t("common.eventtypes.groups." + category + "." + item.id + ".label")}
                        </p>
                        <p color="gray" className="text-sm">
                          {t("common.eventtypes.groups." + category + "." + item.id + ".notes")}
                        </p>
                      </div>
                      <div className="w-8 text-right text-tg-theme-subtitle">
                        <i className="fas fa-angle-right"/>
                      </div>
                    </div>
                  </ListItem>
                )
              })}
            </List>
          )
        }
        )}
    </>
  )
}

export default EventTypeOptionsList;
