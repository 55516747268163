import React, { FC, useEffect, useState } from "react";
import { useHapticFeedback } from "app/telegram";
import { Calendar } from "app/types/calendar";
import { List, ListItem } from "widgets/components/list/list";
import { useScreen } from "context/screen.provider";

interface Props {
  value?: string
  calendars: Calendar[]
  onSelect?: (v: string) => void
}

const SetCalendarPrimaryForm: FC<Props> = ({ calendars, value, onSelect }) => {

  const [ valueData, setValueData ] = useState(value)
  const [ , , selectionChanged ] = useHapticFeedback();
  const { setScreen, setMainButtonVisible, isVisibleMainButton } = useScreen();

  useEffect(() => {
    const preVisibleState = isVisibleMainButton;
    setMainButtonVisible(false)
    return () => {
      setMainButtonVisible(preVisibleState)
    }
  }, []);

  useEffect(() => {
    setValueData(value)
  }, [ value ]);

  const handleChange = (v: string) => {
    selectionChanged();
    onSelect && onSelect(v)
    setScreen(undefined)
  }

  return (
    <List>
      {calendars.map((item, index) => {
        return (
          <ListItem key={index} onClick={() => handleChange(item.id)}>
            <div className="flex items-center justify-between">
              <div>
                {item.name}
              </div>
              <div className="flex">
                {item.id === valueData && (
                  <div className="w-6 text-right text-tg-theme-subtitle">
                    <i className="fas fa-check"/>
                  </div>
                )}
                <div className="w-8 text-right text-tg-theme-subtitle">
                  <i className="fas fa-angle-right"/>
                </div>
              </div>
            </div>
          </ListItem>
        )
      })}
    </List>
  )
}

export default SetCalendarPrimaryForm;
