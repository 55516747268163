import { api } from "./api"
import { EmptySuccessResponse } from "./empty";
import { getLocationFromResponse, getLocationsFromResponse, LocationResponse } from "./locations";

import { Event, PendingEvent } from "app/types/event";

export function getEventFromResponse(src: EventResponse): Event {
  return {
    ...src,
    location: (src.location) ? getLocationFromResponse(src.location) : undefined,
    event_memberships: src.event_memberships?.map(member => ({
      ...member
    })),
  }
}

export function getPendingEventFromResponse(src: PendingEventResponse): PendingEvent {
  return {
    ...src,
    locations: src.locations ? getLocationsFromResponse(src.locations) : [],
  }
}

export type EventCreateRequest = {
  booking_id: string,
  start_time: string,
  remind?: string,
  note?: string,
}

export type EventUpdateRequest = {
  status?: string,
  start_time?: string
  duration?: string,
  location?: LocationResponse
  reschedule?: string
  invitees_counter?: {
    limit?: number,
  }
}

export type EventRescheduleRequest = {
  start_time: string,
  remind?: string,
  note?: string,
}

export type EventCancelRequest = {
  reason?: string,
}

export type EventResponse = {

  id: string,
  calendar_id: string,
  event_type_id?: string,
  booking_id?: string,

  name: string,
  note: string,
  type: string,

  all_day: boolean,
  start_time: string,
  end_time: string,

  status: string,
  duration: string,
  timezone: string,

  schedule_id?: string,

  location: LocationResponse

  invitees_counter: {
    total: number,
    limit: number,
  }

  event_memberships: {
    account_id: string,
    name: string,
    avatar: string,
    username: string,
    role: string,
    remind?: string,
    note?: string,
    language: string,
    timezone: string
  }[]

  cancellation: {
    canceled_by: string,
    reason: string,
    canceler_type: string,
    canceled_at: number
  }

  created: number,
  updated: number,
}

export type EventListResponse = {
  total: number,

  cursor: string,
  order: string,

  items: EventResponse[]
}

export interface EventListParams {
  event_type_id?: string
  type?: string
  status?: string
  from?: number
  to?: number
  limit?: number
}

export type PendingEventResponse = {

  id: string,

  name: string,

  start_time: string,
  end_time: string,

  duration: string,
  timezone: string,

  locations?: LocationResponse[],

  created: number,
  updated: number,
}

export type PendingEventListResponse = {
  total: number,

  cursor: string,
  order: string,

  items: PendingEventResponse[]
}

export interface PendingEventListParams {
  from?: number
  to?: number
  limit?: number
  cursor_order?: string
}

export const eventApi = api.injectEndpoints({
  endpoints: (build) => ({
    eventList: build.query<Event[], EventListParams>({
      query: ({ event_type_id, type, status, from, to, limit }) => {
        return {
          url: "v1/events",
          method: "GET",
          params: { event_type_id, type, status, from, to, limit }
        }
      },
      transformResponse: (response: EventListResponse): Event[] => {
        return response.items.map(getEventFromResponse);
      },
      providesTags: (result: Event[] = []) => [
        ...result.map(({ id }) => ({ type: "Events", id }) as const),
        { type: "Events" as const, id: "LIST" },
      ],
    }),
    eventCreate: build.mutation<Event, EventCreateRequest>({
      query: (data: EventCreateRequest) => ({
        url: "v1/event",
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response: { status: number; data: any }) => {
        if (response.status !== 200) {
          return response.data;
        }
        return response.data;
      },
      transformResponse: (response: EventResponse): Event => {
        return getEventFromResponse(response);
      },
      invalidatesTags: [ { type: "Events", id: "LIST" } ],
    }),
    eventFetch: build.query<Event, string>({
      query: (id: string) => ({
        url: `v1/event/${id}`,
        method: "GET",
      }),
      transformResponse: (response: EventResponse): Event => {
        return getEventFromResponse(response);
      },
      providesTags: (result, error, id) => [ { type: "Events", id } ],
    }),
    eventUpdate: build.mutation<Event, { id: string, data: EventUpdateRequest }>({
      query: ({ id, data }) => ({
        url: `v1/event/${id}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: EventResponse): Event => {
        return getEventFromResponse(response);
      },
      invalidatesTags: (result, error, { id }) => [ { type: "Events", id } ],
    }),
    eventDelete: build.mutation<EmptySuccessResponse, string>({
      query: (id) => ({
        url: `v1/event/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [ { type: "Events", id } ],
    }),
    eventCancel: build.mutation<Event, { id: string, data: EventCancelRequest }>({
      query: ({ id, data }) => ({
        url: `v1/event/${id}/cancel`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: EventResponse): Event => {
        return getEventFromResponse(response);
      },
      invalidatesTags: (result, error, { id }) => [ { type: "Events", id } ],
    }),
    pendingEventList: build.query<PendingEvent[], PendingEventListParams>({
      query: ({ from, to, limit, cursor_order }) => {
        return {
          url: "v1/pending_events",
          method: "GET",
          params: { from, to, limit, cursor_order }
        }
      },
      transformResponse: (response: PendingEventListResponse): PendingEvent[] => {
        return response.items.map(getPendingEventFromResponse);
      },
      providesTags: (result: PendingEvent[] = []) => [
        ...result.map(({ id }) => ({ type: "PendingEvents", id }) as const),
        { type: "PendingEvents" as const, id: "LIST" },
      ],
    }),
  }),
})

export const {
  useEventListQuery,
  useLazyEventListQuery,
  useEventCreateMutation,
  useEventCancelMutation,
  useEventFetchQuery,
  useLazyEventFetchQuery,
  useEventUpdateMutation,
  useEventDeleteMutation,
  usePendingEventListQuery,
  useLazyPendingEventListQuery,
} = eventApi

export const {
  endpoints: {
    eventList,
    eventCreate,
    eventFetch,
    eventUpdate,
    eventDelete
  },
} = eventApi

