import { FC } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "widgets/components/avatar";
import { useAccountAvatarUpdateMutation } from "app/services/account";
import ImageUpload from "widgets/forms/uploader";
import { handleError } from "utils";
import { useHapticFeedback } from "../../../app/telegram";

export interface IProps {
  name: string
  src: string,
}

export const AccountAvatarForm: FC<IProps> = ({ name, src }) => {

  const { t } = useTranslation()
  const [ uploadFile, { isLoading } ] = useAccountAvatarUpdateMutation();

  const handleUpload = async (formData: FormData, onMessage: (message: {
    state: "failed" | "success";
    message: string
  }) => void) => {
    try {
      await uploadFile(formData).unwrap();
      onMessage({ state: "success", message: t("features.account.forms.accountAvatarForm.handleUpload.success") });
    } catch (err) {
      onMessage({ state: "failed", message: t("features.account.forms.accountAvatarForm.handleUpload.error") });
      handleError(err, "Failed to upload file")
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <Avatar name={name} rounded src={src} size={5}/>
      <div className="text-xl text-[#e44] md:text-xl">
        <ImageUpload accept="image/jpeg, image/png, image/gif"
          maxSize={5 * 1024 * 1024} // Максимальный размер файла 5MB
          name={t("features.account.forms.accountAvatarForm.uploadButton")}
          isLoading={isLoading}
          onUpload={handleUpload}
        />
      </div>
      <div className="text-xs text-tg-theme-subtitle">
        {t("features.account.forms.accountAvatarForm.format")}
      </div>
    </div>
  )
}
