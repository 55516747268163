import {
  Location,
  LocationAskInvitee,
  LocationInPersonalMeeting,
  LocationPhoneCall,
  locations,
  LocationType,
  LocationTypes
} from "app/types/location"
import { useTranslation } from "react-i18next"

export interface LocationItemProps {
  location?: Location
  ico?: boolean
  showInfo?: boolean
}

export const LocationItem = ({ location, ico = true, showInfo = false }: LocationItemProps) => {
  const { t } = useTranslation()

  if (!location) {
    return <></>
  }

  const renderInfo = (location: Location) => {
    if (!showInfo) return <div>{t("common.locations." + location.kind + ".title")} </div>
    switch (true) {
      case location.kind === LocationTypes.AskInvitee && !!location.note:
        return <div>{(location as LocationAskInvitee).note}</div>
      case location.kind === LocationTypes.InPerson && !!location.note:
        return <div>{(location as LocationInPersonalMeeting).note}</div>
      case location.kind === LocationTypes.Phone && !!location.phone:
        return <div>{(location as LocationPhoneCall).phone}</div>
      default:
        return <div>{t("common.locations." + location.kind + ".title")} </div>
    }
  }

  return (
    <span className="inline-flex items-center rounded-md bg-tg-theme-section text-tg-theme-subtitle px-2 py-1 text-xs
                    font-medium ring-1 ring-inset ring-gray-500/10">
      {ico && (
        <span className="w-5">
          <i className={locations[(location.kind as LocationType)]?.ico + " mr-1 w-4 m-auto"}/>
        </span>
      )}
      {renderInfo(location)}
    </span>
  )
}

export default LocationItem;
