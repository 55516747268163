import { useEventTypeListQuery } from "app/services/eventtypes";
import { useNavigate } from "react-router-dom";
import { EventTypeItemHorizontal, EventTypeItemVertical } from "./eventTypeItem";
import { List } from "widgets/components/list/list";
import { useTranslation } from "react-i18next";

export type variant = "horizontal" | "vertical" | "labels";

export interface EventTypeListProps {
  selectedEventType?: string,
  onClick?: (id?: string) => void,
  variant: variant,
}

export const EventTypeList = ({ variant, selectedEventType, onClick }: EventTypeListProps) => {

  const { data: eventtypes, isLoading: eventTypesAreLoading } = useEventTypeListQuery({})
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (eventTypesAreLoading) return <div/>
  if (!eventtypes) return <div/>

  const handleClick = (id: string | undefined) => {
    !!onClick && onClick(id);
  }

  switch (variant) {
    case "horizontal":
      return (
        <>
          {!!eventtypes.length && (
            <List title={t("features.eventtype.components.eventTypeList.group")}>
              {
                eventtypes.map((eventtype) => (
                  <EventTypeItemHorizontal
                    key={eventtype.id}
                    eventtype={eventtype}
                    onSelect={(id) => navigate(`/eventtype/${id}`)}
                  />
                ))}
            </List>
          )}
        </>
      )
    case "vertical":
      return (
        <>
          {!!eventtypes.length && (
            <div className="flex flex-row flex-nowrap px-5 gap-4 mb-4 pb-2 overflow-x-scroll no-scrollbar">
              {
                eventtypes.map((eventtype) => (
                  <EventTypeItemVertical
                    key={eventtype.id}
                    eventtype={eventtype}
                    onSelect={(id) => navigate(`/eventtype/${id}`)}
                  />
                ))}
            </div>
          )}
        </>
      )

    case "labels":
      return (
        <>
          {!!eventtypes.length && (
            <div className="flex space-x-4 overflow-x-scroll no-scrollbar px-5">
              <div
                className={`text-xs whitespace-nowrap py-2 px-4 rounded-xl ${!selectedEventType ? "border text-tg-theme-accent-text border-tg-theme-accent-text" : "bg-tg-theme-section"}`}
                onClick={() => handleClick(undefined)}
              >
                {t("features.eventtype.components.eventTypeList.labels.all")}
              </div>
              {
                eventtypes.map((eventtype) => (
                  <div
                    key={eventtype.id}
                    onClick={() => handleClick(eventtype.id)}
                    className={`bg-tg-theme-section text-xs whitespace-nowrap py-2 px-4 rounded-xl ${selectedEventType === eventtype.id ? "border text-tg-theme-accent-text border-tg-theme-accent-text" : "bg-tg-theme-section"}`}>
                    {eventtype.name}
                  </div>
                ))
              }
            </div>
          )}
        </>
      )
  }

}
