import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useScreen } from "../../../context/screen.provider";
import { useWebApp } from "../../../app/telegram";

const EventNotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { setBackButtonOnClick, setMainButtonProps } = useScreen();

  useEffect(() => {
    setMainButtonProps(t("features.event.components.eventNotFound.mainButton"), () => navigate("/"))
    setBackButtonOnClick(() => navigate("/"))
  }, [ navigate ])

  const webApp = useWebApp()
  webApp.initDataUnsafe.start_param = ""

  return (
    <>
      <div className="px-5">
        <div className="my-5 mt-16 px-6 capitalize text-center">
          <p className="text-3xl font-medium text-tg-theme-text">
            {t("features.event.components.eventNotFound.title")}
          </p>
        </div>

        <div className="px-5 my-5 text-center">
          <p>
            {t("features.event.components.eventNotFound.description")}
          </p>
        </div>

      </div>
    </>
  )
}

export default EventNotFound
