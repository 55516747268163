import React from "react";
import { Event } from "app/types/event";
import Time from "widgets/components/time";
import { useTranslation } from "react-i18next";
import { formatMinutes } from "app/utils";
import { parseDuration } from "widgets/forms/timepicker/timespot";
import { useAccount } from "hooks/useAccount";
import { LocationItem } from "widgets/forms/location";

export interface EventItemProps {
  event: Event,
  className?: string,
  onSelect?: (id: string) => void
}

export const EventItem = ({ event, onSelect, className }: EventItemProps) => {

  const { t } = useTranslation()
  const account = useAccount()

  const onClick = () => {
    if (!!event?.service) return
    onSelect && onSelect(event.id)
  }

  return (
    <div className="w-full py-5" onClick={onClick}>
      <div className="border-l-2 border-b-cyan-700 pl-2">
        <div className={`flex items-center ${className}`}>
          <div className="flex w-full">
            <div className="flex flex-col w-[5rem] text-xs">
              <Time variant="Time" time={event.start_time} className="text-tg-theme-subtitle"/>
              <div className="text-tg-theme-subtitle">
                {formatMinutes(parseDuration(event.duration), account.account.language, true)}
              </div>
            </div>
            <div className="flex-1">
              <div className="w-full max-w-xs">
                <p className="text-ellipsis overflow-hidden text-sm text-tg-theme-text flex items-center">
                  {event?.service === "google" && <i className="fa-brands fa-google w-4 mr-2"/>}
                  {event.name}
                </p>
              </div>
              {event.note && (
                <div className="w-full max-w-xs mt-2">
                  <p className="text-ellipsis overflow-hidden text-xs text-tg-theme-subtitle">
                    {event.note}
                  </p>
                </div>
              )}
              {event.location && (
                <div className="w-full mt-2">
                  <LocationItem location={event.location} showInfo={true}/>
                </div>
              )}
            </div>
          </div>
          {!event?.service && (
            <div className="w-8 text-right text-tg-theme-subtitle">
              <i className="fa-solid fa-chevron-right"/>
            </div>
          )}
        </div>
      </div>
    </div>

  )
}
