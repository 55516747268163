import React, { useEffect, useState } from 'react';

type TimeInputProps = {
  value: string;
  onChange: (newValue: string) => void;
  format?: '12h' | '24h'; // Обновленные значения формата
};

const TimeInput: React.FC<TimeInputProps> = ({ value, onChange, format = '24h' }) => {
  const [time, setTime] = useState<string>(value);
  const [backupTime, setBackupTime] = useState<string>(value);

  useEffect(() => {
    setTime(value);
    setBackupTime(value);
  }, [value]);

  const formatTime = (val: string): string => {
    if (val.length >= 3 && !val.includes(':')) {
      val = `${val.slice(0, 2)}:${val.slice(2)}`;
    }
    return val.slice(0, 5); // Ограничиваем длину до 5 символов
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value.replace(/[^0-9]/g, ''); // Удаляем все нецифровые символы

    val = formatTime(val); // Форматируем значение

    setTime(val);

    if (/^\d{2}:\d{2}$/.test(val)) {
      const [hours, minutes] = val.split(':').map(Number);

      let correctedHours = hours;
      if (format === '24h') {
        correctedHours = Math.min(hours, 23); // Ограничиваем часы до 23 для формата 24ч
      } else {
        correctedHours = hours > 12 ? 12 : hours; // Ограничиваем часы до 12 для формата 12ч
      }

      const formattedTime = `${correctedHours.toString().padStart(2, '0')}:${Math.min(minutes, 59).toString().padStart(2, '0')}`;
      setTime(formattedTime);
      onChange(formattedTime);
    }
  };

  const handleFocus = () => {
    setBackupTime(time);
    setTime('');
  };

  const handleBlur = () => {
    if (/^\d{2}:\d{2}$/.test(time)) {
      const [hours, minutes] = time.split(':').map(Number);

      let correctedHours = hours;
      if (format === '24h') {
        correctedHours = Math.min(hours, 23); // Ограничиваем часы до 23 для формата 24ч
      } else {
        correctedHours = hours > 12 ? 12 : hours; // Ограничиваем часы до 12 для формата 12ч
      }

      const formattedTime = `${correctedHours.toString().padStart(2, '0')}:${Math.min(minutes, 59).toString().padStart(2, '0')}`;
      setTime(formattedTime);
      onChange(formattedTime);
    } else {
      setTime(backupTime);
      onChange(backupTime);
    }
  };

  return (
    <input
      type="text"
      value={time}
      onChange={handleInputChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      placeholder={format === '24h' ? "HH:mm" : "hh:mm"}
      maxLength={5}
      className="w-full text-tg-theme-text bg-tg-theme-section focus:outline-none text-center"
      inputMode="numeric"
    />
  );
};

export default TimeInput;
