import React, { useState } from "react";

interface ToggleProps {
  checked?: boolean;
  onToggle?: (checked: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({ checked = false, onToggle }) => {
  const [ checkedValue, setCheckedValue ] = useState(checked);

  const handleToggle = () => {
    setCheckedValue(!checkedValue);
    onToggle && onToggle(!checkedValue);
  };

  return (
    <button onClick={handleToggle}
      className={`relative inline-flex items-center h-5 rounded-full w-10 transition-colors duration-200 ${
              checkedValue ? "bg-tg-theme-button" : "bg-tg-theme-secondary"
      }`}
    >
      <span
        className={`absolute left-0 inline-flex items-center justify-center w-5 h-5 transition-transform 
          transform bg-white rounded-full 
          ${checkedValue ? "translate-x-5" : "translate-x-0"}
          `}
      />
    </button>
  );
};

export default Toggle;
