import React, { useState } from 'react';
import TimeInput from 'widgets/forms/timeinput';

type TimeRangeProps = {
  startTime: string;
  endTime: string;
  onChange: (startTime: string, endTime: string) => void;
  format?: '12h' | '24h';
};

const TimeRange: React.FC<TimeRangeProps> = ({ startTime, endTime, onChange, format = '24h' }) => {
  const [ start, setStart ] = useState<string>(startTime);
  const [ end, setEnd ] = useState<string>(endTime);

  const handleStartChange = (newStart: string) => {
    setStart(newStart);
    if (end && newStart > end) {
      setEnd(newStart);
    }
    onChange(newStart, end);
  };

  const handleEndChange = (newEnd: string) => {
    setEnd(newEnd);
    if (newEnd < start) {
      setStart(newEnd);
    }
    onChange(start, newEnd);
  };

  return (
    <div className="flex gap-4">
      <div className="min-w-14 w-14">
        <TimeInput
          value={start}
          onChange={handleStartChange}
          format={format}
        />
      </div>
      <span className="text-tg-theme-text">
        <i className="fa fa-arrow-right-long"/>
      </span>
      <div className="min-w-14 w-14">
        <TimeInput
          value={end}
          onChange={handleEndChange}
          format={format}
        />
      </div>
    </div>
  );
};

export default TimeRange;
