import { FC } from "react"
import { Location, locations, LocationType, LocationTypes } from "app/types/location";
import { useTranslation } from "react-i18next";

interface Props {
  location: {
    data?: Location;
  };
  bookingLocations?: Location[];
  setScreen: (screen: string) => void;
}

const BookingLocationCard: FC<Props> = ({ location, bookingLocations = [], setScreen }) => {
  const { t } = useTranslation();

  const renderLocationTitle = () => {
    const loc = (location.data || {}) as Location;

    switch (loc.kind) {
      case LocationTypes.AskInvitee:
        return (
          <span className="font-bold text-tg-theme-accent-text">
            {t("features.booking.components.bookingLocationCard.askInviteeTitle")}
          </span>
        );
      case LocationTypes.Phone:
        return (
          <span className="font-bold text-tg-theme-accent-text">
            {!loc.outbound
              ? t("features.booking.components.bookingLocationCard.phoneOutboundTitle")
              : t("features.booking.components.bookingLocationCard.phoneIncomingTitle")
            }
          </span>
        );
      case LocationTypes.Telegram:
        return (
          <span className="font-bold text-tg-theme-accent-text">
            {!loc.outbound
              ? loc.video
                ? t("features.booking.components.bookingLocationCard.telegramOutboundVideoTitle")
                : t("features.booking.components.bookingLocationCard.telegramOutboundTitle")
              : loc.video
                ? t("features.booking.components.bookingLocationCard.telegramIncomingVideoTitle")
                : t("features.booking.components.bookingLocationCard.telegramIncomingTitle")
            }
          </span>
        );
      default:
        return t(`common.locations.${loc.kind}.title`);
    }
  };

  const renderLocationNote = () => {
    const loc = (location.data || {}) as Location;

    if (loc.kind === LocationTypes.AskInvitee) {
      return (
        <div onClick={() => setScreen("location_place")}
          className="mt-3 pt-3 border-t-2 border-t-neutral-300 dark:border-t-neutral-700">
          <p className="text-tg-theme-hint">
            {loc.note ? loc.note : t("features.booking.components.bookingLocationCard.askInviteeTapButton")}
          </p>
        </div>
      );
    }

    if (loc.kind === LocationTypes.Phone && loc.outbound) {
      return (
        <div onClick={() => setScreen("location_phone")}
          className="mt-3 pt-3 border-t-2 border-t-neutral-300 dark:border-t-neutral-700">
          <p className="text-tg-theme-hint">
            {loc.phone
              ? `${loc.phone}`
              : t("features.booking.components.bookingLocationCard.phoneTapButton")
            }
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="flex justify-between w-full">
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center gap-1">
            <span className="w-6 text-left">
              <i className={locations[location.data?.kind as LocationType]?.ico}/>
            </span>
            {renderLocationTitle()}
          </div>
          {bookingLocations.length > 1 && (
            <div>
              <button className="rounded-xl text-xs py-1 px-4" onClick={() => setScreen("location")}>
                {t("features.booking.forms.bookingEventCreateForm.buttonLocationChange")}
              </button>
            </div>
          )}
        </div>
      </div>
      {renderLocationNote()}
    </>
  );
};

export default BookingLocationCard;
