import { useNavigate } from "react-router-dom";
import React, { MouseEvent, useEffect } from "react";
import { Screen, useScreen } from "context/screen.provider";
import { useTranslation } from "react-i18next";
import { useShowPopup } from "app/telegram";
import CalendarLoading from "../components/calendarLoading";
import CalendarFetchError from "../components/calendarFetchError";
import { List, ListItem } from "widgets/components/list/list";
import Card from "widgets/components/card";
import { useIntegrationDeleteMutation, useIntegrationListQuery } from "app/services/integration";
import { useLazyProfileListQuery, useProfileUpdateMutation } from "app/services/profiles";
import { useCalendarUpdateMutation, useGetCalendarListQuery } from "app/services/calendar";
import CalendarConnectForm from "../forms/calendarConnectForm";
import CalendarSetPrimaryForm from "../forms/calendarSetPrimaryForm";
import { useAccount } from "hooks/useAccount";
import { Profile } from "app/types/profile";
import { setProfile } from "../../auth/authSlice";
import { useDispatch } from "react-redux";
import { handleError } from "utils";

const CalendarSettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showPopup = useShowPopup()
  const { profile } = useAccount()
  const { screen, setScreen, setBackButtonVisible, setBackButtonOnClick, setMainButtonVisible } = useScreen();
  const dispatch = useDispatch()

  const [ deleteIntegration, {
    isLoading: isDeleteLoading,
    isError: isDeleteError
  } ] = useIntegrationDeleteMutation();
  const {
    data: calendars,
    refetch: calendarRefetch,
    isLoading: isCalendarsLoading,
    isError: isCalendarsError
  } = useGetCalendarListQuery({}, {
    // pollingInterval: 5000,
    skipPollingIfUnfocused: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })
  const {
    data: integrations,
    refetch: integrationRefetch,
    isLoading: isIntegrationsLoading,
    isError: isIntegrationsError,
  } = useIntegrationListQuery({ service: "calendar" }, {
    // pollingInterval: 5000,
    skipPollingIfUnfocused: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })
  const [ profileList, profileRequest ] = useLazyProfileListQuery();

  const [ updateProfile, {
    isLoading: isProfileUpdateLoading,
    isError: isProfileUpdateError
  } ] = useProfileUpdateMutation()
  const [ updateCalendar, {
    isLoading: isCalendarUpdateLoading,
    isError: isCalendarUpdateError
  } ] = useCalendarUpdateMutation()

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        calendarRefetch()
        integrationRefetch()
        const profiles: Profile[] = await profileList({}).unwrap();
        // TODO: if no profiles = goto welcome
        dispatch(setProfile((profiles || []).slice().find(profile => profile.type === "personal") || profiles[0]));
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [ calendarRefetch, integrationRefetch ]);

  useEffect(() => {
    setBackButtonVisible(true)
    setBackButtonOnClick(() => navigate(-1))
    setMainButtonVisible(false)
  }, []);

  useEffect(() => {
    if (screen) return
    setBackButtonVisible(true)
    setMainButtonVisible(false)
    setBackButtonOnClick(() => navigate(-1))

  }, [ screen ]);

  if (isCalendarsLoading) return <CalendarLoading/>;
  if (isCalendarsError) return <CalendarFetchError/>;

  const showDeleteIntegrationPopup = (e: MouseEvent, id: string) => {
    e.stopPropagation()

    showPopup({
      title: t("features.calendar.pages.settings.popupConfirmRemove.title"),
      message: t("features.calendar.pages.settings.popupConfirmRemove.message"),
      buttons: [
        {
          id: "close",
          type: "cancel",
          text: t("features.calendar.pages.settings.popupConfirmRemove.buttons.close")
        },
        {
          id: "delete",
          type: "destructive",
          text: t("features.calendar.pages.settings.popupConfirmRemove.buttons.delete")
        },
      ]
    }).then(async buttonId => {
      if (buttonId === "delete") {
        try {
          const result = await deleteIntegration(id).unwrap()
          if (!isDeleteError && result) {
            calendarRefetch()
            integrationRefetch()
          }
        } catch (err) {
          handleError(err, "Failed to delete integration")
        }
      }
    });
  }

  if (!calendars || !calendars.length) {
    return (
      <>
        <div className="px-6 mt-10 capitalize text-center">
          <p className="text-3xl font-medium text-tg-theme-text">
            {t("features.calendar.pages.settings.connectCalendar")}
          </p>
        </div>

        <div className="p-5">
          <CalendarConnectForm/>
        </div>
      </>
    )
  }

  const handleSetPrimary = async (v: string) => {
    const data = { ...profile, calendar_id: v }
    try {
      const result = await updateProfile({ id: profile.id, data: data }).unwrap()
      if (!isProfileUpdateError && result) {
      }
    } catch (err) {
      handleError(err, "Failed to update profile")
    }
  }

  const screens = {
    calendar: <CalendarConnectForm/>,
    set_calendar: <CalendarSetPrimaryForm calendars={calendars}
                                          value={profile.calendar_id}
                                          onSelect={handleSetPrimary}/>,
  };

  return (
    <Screen screens={screens} className="p-5">
      <div>

        <div className="capitalize text-center my-6">
          <p className="text-3xl font-medium text-tg-theme-text">
            {t("features.calendar.pages.settings.title")}
          </p>
        </div>

        <div className="px-5">
          <Card className="flex justify-between mb-5">
            <div>
              {t("features.calendar.pages.settings.myCalendars")}
            </div>
            <div>
              <button
                className="rounded-xl text-xs py-1 px-4 bg-tg-theme-button"
                onClick={() => setScreen("calendar")}
              >
                {t("features.calendar.pages.settings.buttonAddCalendar")}
              </button>
            </div>
          </Card>
        </div>

        <div className="px-5">
          <List title={t("features.calendar.pages.settings.calendarsList.title")} className="mb-5">
            {(integrations || []).filter(i => i.type === "calendar").map((integration, index) => {
              const items = calendars.filter(c => !c.disabled)
              return (
                <ListItem key={index}>
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="capitalize">{integration.service}</div>
                      <div className="text-xs ca">{integration.name}</div>
                    </div>
                    <div>
                      <button
                        className="rounded-xl text-xs py-1 px-4 bg-tg-theme-danger"
                        onClick={(e) => showDeleteIntegrationPopup(e, integration.id)}>
                        {t("features.calendar.pages.settings.calendarsList.buttonRemoveCalendar")}
                      </button>
                    </div>
                  </div>
                  <div className="pt-2 mt-2 border-t border-dotted border-t-neutral-300">
                    <div>
                      {!items.length && t("features.calendar.pages.settings.calendarsList.noSelected")}
                      {!!items.length && items.map((c, index) => {
                        return <div key={index}>{c.name}</div>
                      })}
                    </div>
                  </div>
                </ListItem>
              )
            })}
          </List>
        </div>

        <div className="px-5">
          <Card title={t("features.calendar.pages.settings.defaultCalendarCard.title")}
                onClick={() => setScreen("set_calendar")}>
            <div className="flex items-center">
              <div className="w-6">
                <i className="fa fa-calendar"/>
              </div>
              <div className="w-full flex items-center justify-between">
                <div>{t("features.calendar.pages.settings.defaultCalendarCard.addTo")}</div>
                <button className="rounded-xl text-xs py-1 px-4 bg-tg-theme-button">
                  {t("features.calendar.pages.settings.buttonChangeCalendar")}
                </button>
              </div>
            </div>
            <div className="pt-2 mt-2 border-t border-dotted border-t-neutral-300">
              {calendars.find(c => c.id === profile.calendar_id)?.name}
            </div>
          </Card>
        </div>

      </div>
    </Screen>
  )
}

export default CalendarSettingsPage
