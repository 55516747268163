export interface LanguageItem {
  label: string;
  hint: string;
  value: string;
  soon: boolean;
}

export const languages: LanguageItem[] = [
  { label: "Russian", hint: "Русский", value: "ru", soon: false },
  { label: "English", hint: "English", value: "en", soon: false },
  { label: "Indonesian", hint: "Bahasa Indonesia (segera)", value: "id", soon: true },
  { label: "Spanish", hint: "Español (pronto)", value: "es", soon: true },
  { label: "Korean", hint: "한국어 (곧)", value: "ko", soon: true },
  { label: "Italian", hint: "Italiano (presto)", value: "it", soon: true },
  { label: "Chinese (Simplified)", hint: "中文 (简体) (即将)", value: "zh-CN", soon: true },
  { label: "Chinese (Traditional)", hint: "中文 (繁體) (即將)", value: "zh-TW", soon: true },
  { label: "Persian", hint: "فارسی (به زودی)", value: "fa", soon: true },
  { label: "Turkish", hint: "Türkçe (yakında)", value: "tr", soon: true },
  { label: "Uzbek", hint: "Oʻzbekcha (tez orada)", value: "uz", soon: true },
  { label: "Bengali", hint: "বাংলা (শীঘ্রই)", value: "bn", soon: true },
  { label: "Hindi", hint: "हिन्दी (जल्द ही)", value: "hi", soon: true },
  { label: "Portuguese (Brazil)", hint: "Português (Brasil) (em breve)", value: "pt-BR", soon: true }
];