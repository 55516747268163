import React, { ReactNode, useState } from "react";
import Card from "../card";
import { useTranslation } from "react-i18next";

interface OptionValue {
  label: string | ReactNode;
  value: string | ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

interface AccordionProps {
  title?: string;
  name: string;
  className?: string;
  value?: string | ReactNode;
  options: OptionValue[];
  footer?: ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, name, value, className, options, footer }) => {
  const [ collapsed, setCollapsed ] = useState(true);
  const { t } = useTranslation();
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={className}>
      {collapsed && title && <div className="h-4 text-tg-theme-subtitle text-xs uppercase ml-3 mb-2">{title}</div>}
      <div
        className={`w-full ${collapsed ? "relative transform" : ""}`}
        style={{ zIndex: options.length + 1, top: 0 }}>

        <div className={`${collapsed ? "rounded-xl p-4 bg-tg-theme-section" : "px-2"}`}>
          <div className="flex items-center justify-between" onClick={toggleCollapse}>
            {
              (collapsed || !title)
                ? <div>{name}</div>
                : <div className="h-4 text-tg-theme-subtitle text-xs uppercase ml-1 mb-2">{title}</div>
            }
            {!collapsed
              ? (
                <div className="flex flex-row items-center h-4 mb-2">
                  <div><i className="fa-solid fa-chevron-up mr-2"></i></div>
                  <div className="mb-[0.35rem]">{t("widgets.components.accordion.collapse")}</div>
                </div>
              )
              : value
                ? <div>{value}</div>
                : (
                  <div className="w-8 text-right text-tg-theme-subtitle">
                    <i className="fa-solid fa-chevron-down mr-2"></i>
                  </div>
                )}
          </div>
        </div>

        {collapsed && (
          <div className="absolute w-full top-0 -z-10">
            {Array(3).fill(null).map((_, index) => {
              const widths = [ "w-[calc(100%*0.9)]", "w-[calc(100%*0.93)]", "w-[calc(100%*0.96)]" ];
              const opacities = [ "opacity-[0.05]", "opacity-[0.1]", "opacity-[0.15]" ];
              return (
                <div
                  key={index}
                  className={`w-full absolute ${opacities[index]}`}
                  style={{ zIndex: index, top: (3 - index) * 3 }}>
                  <Card className={`border border-tg-theme-subtitle m-auto h-14 ${widths[index]}`}/>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out 
            ${collapsed ? "max-h-0 opacity-0" : "max-h-auto opacity-100"}`}
      >
        {!collapsed && options.map(({ label, value, disabled, onClick }, index) => (
          <React.Fragment key={index}>
            <div
              className={`w-full ${index === options.length - 1 ? "mb-3" : "mb-5"}`}
              style={{
                zIndex: index,
                top: (options.length - index) * 4,
                opacity: collapsed ? (index + 1) * 0.1 : 1
              }}>
              <Card className="flex items-center justify-between m-auto"
                    disabled={disabled}
                    onClick={!disabled ? onClick : undefined}>
                <div>{label}</div>
                <div className="ml-1">{value}</div>
              </Card>
            </div>
          </React.Fragment>
        ))}

        {footer && <footer>{footer}</footer>}
      </div>
    </div>
  );
};

export default Accordion;
