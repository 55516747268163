import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { LocationTelegramCall } from "app/types/location";
import { useTranslation } from "react-i18next";
import Card from "widgets/components/card";
import { List, ListItem } from "../../../components/list/list";

interface LocationTelegramCallProps {
  value: LocationTelegramCall;
  onChange: (data: LocationTelegramCall) => void;
  onRemove?: () => void
  connected: boolean
}

const LocationTelegramCallForm: FC<LocationTelegramCallProps> = ({ value, onChange, onRemove, connected }) => {

  const patchData = (v: LocationTelegramCall) => {
    const data = { ...v }
    if (!data.video) data.video = false
    if (!data.outbound) data.outbound = true
    if (!data.note) data.note = ""
    return data
  }

  const { t } = useTranslation();
  const [ isOutbound, setIsOutbound ] = useState<boolean>(!!value?.outbound);
  const [ data, setData ] = useState<LocationTelegramCall>(patchData(value));

  useEffect(() => {
    onChange(patchData(value))
  }, [])

  useEffect(() => {
    setData(patchData(value))
  }, [ value ])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = { ...data, [e.target.name]: e.target.value }
    setData(value)
    onChange(value)
  };

  const handleOutboundChange = (v: boolean) => {
    setIsOutbound(v);
    const updatedData = { ...data, outbound: v };
    setData(updatedData);
    onChange(updatedData);
  }

  return (
    <div className="px-5">

      <div className="font-bold pt-5 pb-8 text-center">
        {t("widgets.forms.location.form.locationTelegramCallForm.title")}
      </div>

      <div className="mb-5">
        <List className="flex flex-col mb-2"
          title={t("widgets.forms.location.form.locationTelegramCallForm.host.label")}>
          <ListItem>
            <div className="flex items-center" onClick={() => handleOutboundChange(true)}>
              <input
                name="outbound"
                type="radio"
                value="false"
                checked={isOutbound}
                onChange={() => handleOutboundChange(true)}
              />
              <div className="ml-2">
                <p>
                  {t("widgets.forms.location.form.locationTelegramCallForm.host.title")}
                </p>
                <small>
                  {t("widgets.forms.location.form.locationTelegramCallForm.host.description")}
                </small>
              </div>
            </div>
          </ListItem>
          <ListItem>
            <div className="flex items-center" onClick={() => handleOutboundChange(false)}>
              <input
                name="outbound"
                type="radio"
                value="true"
                checked={!isOutbound}
                onChange={() => handleOutboundChange(false)}
              />
              <div className="ml-2">
                <p>
                  {t("widgets.forms.location.form.locationTelegramCallForm.member.title")}
                </p>
                <small>
                  {t("widgets.forms.location.form.locationTelegramCallForm.member.description")}
                </small>
              </div>
            </div>
          </ListItem>
        </List>
      </div>

      <Card className="flex flex-col mb-2"
        title={t("widgets.forms.location.form.locationTelegramCallForm.video.title")}>
        <div className="flex items-center" onClick={() => {
          const updatedData = { ...data, video: !data.video };
          setData(updatedData);
          onChange(updatedData);
        }}>
          <input
            name="video"
            type="checkbox"
            value="video"
            checked={data.video}
            onChange={() => {
              const updatedData = { ...data, video: !data.video };
              setData(updatedData);
              onChange(updatedData);
            }}
          />
          <div className="ml-2">
            <small>
              {t("widgets.forms.location.form.locationTelegramCallForm.video.description")}
            </small>
          </div>
        </div>
      </Card>


      <Card title={t("widgets.forms.location.form.locationTelegramCallForm.note.title")} className="mb-2 p-0">
        <textarea
          name="note"
          className="w-full rounded-xl text-tg-theme-text bg-tg-theme-section focus:outline-none"
          value={data.note}
          onChange={handleChange}
        />
      </Card>

      {connected && onRemove && (
        <List className="mt-5">
          <ListItem
            onClick={onRemove}>
            <div className="text-tg-theme-destructive">
              {t("widgets.forms.location.buttons.remove")}
            </div>
          </ListItem>
        </List>
      )}

    </div>
  )
}

export default LocationTelegramCallForm
