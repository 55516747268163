import React, { useEffect, useState } from "react";
import { Screen, useScreen } from "context/screen.provider";
import { useNavigate } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import Timezone from "widgets/forms/timezone";
import Language from "widgets/forms/language";
import FormatDate from "widgets/forms/formatdate";
import FormatTime from "widgets/forms/formattime";
import AccountDetailsForm from "features/account/forms/accountDetailsForm";
import { AccountAvatarForm } from "features/account/forms/accountAvatarForm";
import AccountDateTimeForm from "features/account/forms/accountDateTimeForm";
import AccountPersonalInfoForm from "features/account/forms/accountPersonalInfoForm";
import { AccountUpdateRequest, useAccountUpdateMutation } from "app/services/account";
import { usePaymentCreateMutation } from "app/services/payment";
import { useTranslation } from "react-i18next";
import { useHapticFeedback, useWebApp } from "app/telegram";
import Card from "widgets/components/card";
import { handleError } from "utils";
import i18n from "../app/i18n";

type FormData = {
  first_name: string,
  last_name: string,
  timezone: string,
  language: string,
  format_date: string,
  format_time: string,
};

const SettingsPage = () => {
  const navigate = useNavigate();
  const auth = useAccount()
  const webApp = useWebApp()
  const {
    screen,
    setBackButtonVisible,
    setBackButtonOnClick,
    setMainButtonVisible,
    setMainButtonProps,
    setScreen
  } = useScreen();
  const [ , , selectionChanged ] = useHapticFeedback();

  const [ createPayment, {
    isLoading: isCreatePaymentLoading,
    isError: isCreatePymentError
  } ] = usePaymentCreateMutation();

  const [ updateAccount, { isLoading, isError } ] = useAccountUpdateMutation();
  const [ formData, setFormData ] = useState<FormData>(auth.account)
  const { t } = useTranslation();
  const [ shouldSend, setShouldSend ] = useState(false);

  const goToHome = () => {
    navigate("/")
  }

  useEffect(() => {
    if (shouldSend && formData) {
      const timer = setTimeout(async () => {
        try {
          await updateAccount(formData as AccountUpdateRequest).unwrap();
        } catch (err) {
          handleError(err, "Failed to update account")
        }
      }, 300);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [ shouldSend, formData, updateAccount ]);

  const handleChange = async (name: any, data: any) => {
    const patch = { ...formData, [name]: data }

    setFormData(patch)
    setScreen(undefined)
    setShouldSend(true);

    if (name === "language") {
      await i18n.changeLanguage(data || "en");
    }
  }

  const handlePaymentCreate = async () => {
    try {
      const link = await createPayment({}).unwrap()
      if (!isError) {
        webApp.openInvoice(link)
      }
    } catch (err) {
      handleError(err, "Failed to create payment")
    }
  };

  // Configure navigation routing
  useEffect(() => {
    if (!!screen) return
    setBackButtonVisible(true)
    setBackButtonOnClick(goToHome)
    setMainButtonVisible(false)
  }, [ screen, formData ])

  const screens = {
    timezone: <Timezone value={formData.timezone} onSelect={(v) => handleChange("timezone", v)}
                        timeFormat={auth.account.format_time}/>,
    language: <Language value={formData.language} onSelect={(v) => handleChange("language", v)}/>,
    formatdate: <FormatDate value={formData.format_date} onSelect={(v) => handleChange("format_date", v)}/>,
    formattime: <FormatTime value={formData.format_date} onSelect={(v) => handleChange("format_time", v)}/>,
  };

  return (
    <Screen screens={screens}>
      <div>

        {/*<button onClick={handlePaymentCreate}>PAYMENT</button>*/}

        <div className="my-5 px-5">
          <AccountAvatarForm name={auth.account.first_name + " " + auth.account.last_name} src={auth.account.avatar}/>
        </div>

        <div className="mb-5 px-5">
          <AccountPersonalInfoForm data={formData} onChange={handleChange}/>
        </div>

        <div className="mb-5 px-5">
          <Card title={t("features.account.forms.calendarInfoForm.title")}
                onClick={() => navigate("/calendar")}>
            <div className="flex justify-between">
              <div className="flex-1">{t("features.account.forms.calendarInfoForm.label")}</div>
              <div className="w-8 text-right text-tg-theme-subtitle">
                <i className="fas fa-angle-right"/>
              </div>
            </div>
          </Card>
        </div>

        <div className="mb-5 px-5">
          <Card title={t("features.account.forms.integrationInfoForm.title")}
                onClick={() => navigate("/integration")}>
            <div className="flex justify-between">
              <div className="flex-1">{t("features.account.forms.integrationInfoForm.label")}</div>
              <div className="w-8 text-right text-tg-theme-subtitle">
                <i className="fas fa-angle-right"/>
              </div>
            </div>
          </Card>
        </div>

        <div className="mb-5 px-5">
          <AccountDetailsForm data={formData} onChange={handleChange}/>
        </div>

        <div className="mb-5 px-5">
          <AccountDateTimeForm data={formData} onChange={handleChange}/>
        </div>

      </div>
    </Screen>
  )
};

export default SettingsPage;
