import React, { FC, useEffect, useState } from "react";
import { useScreen } from "context/screen.provider";
import { List, ListItem } from "widgets/components/list/list";
import { languages } from "widgets/forms/language/languages";
import { useTranslation } from "react-i18next";

export interface Props {
  onChange: (name: any, value: any) => void
  data: any
}

const AccountDetailsForm: FC<Props> = ({ data, onChange }) => {

  const { t } = useTranslation()
  const [ dataValue, setDataValue ] = useState(data)
  const { setScreen } = useScreen();

  const handleSelectScreen = (screen: any) => {
    setScreen(screen)
  }

  useEffect(() => {
    setDataValue(data)
  }, [ data ])

  return (
    <List title={t("features.account.forms.accountDetailsForm.title")}>
      <ListItem onClick={() => handleSelectScreen("timezone")}
        prefix={<i className="fa fa-clock"/>}>
        <div className="flex justify-between">
          <div className="flex-1">{t("features.account.forms.accountDetailsForm.inputTimezone")}</div>
          <div className="text-tg-theme-subtitle">{dataValue.timezone}</div>
          <div className="w-8 text-right text-tg-theme-subtitle">
            <i className="fas fa-angle-right"/>
          </div>
        </div>
      </ListItem>
      <ListItem onClick={() => handleSelectScreen("language")}
        prefix={<i className="fa-solid fa-globe"/>}>
        <div className="flex justify-between">
          <div className="flex-1">{t("features.account.forms.accountDetailsForm.inputLanguage")}</div>
          <div className="text-tg-theme-subtitle">
            {languages.find(i => dataValue.language === i.value)?.label || ""}
          </div>
          <div className="w-8 text-right text-tg-theme-subtitle">
            <i className="fas fa-angle-right"/>
          </div>
        </div>
      </ListItem>
    </List>
  )
}
export default AccountDetailsForm;
