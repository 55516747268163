import React, { ChangeEvent, useEffect, useState } from "react";
import { useScreen } from "context/screen.provider";
import { useTranslation } from "react-i18next";

interface CountryProps {
  title?: string;
  value?: string;
  btnName?: string;
  lineHeight?: number; // высота строки
  onChange?: (v: string) => void;
  onApply?: (v: string) => void;
}

const Note = ({ title, lineHeight, value, onChange, btnName, onApply }: CountryProps) => {
  const { t } = useTranslation();
  const [ text, setText ] = useState<string>(value || "");
  const [ rows, setRows ] = useState<number>(1);
  const [ minHeight, setMinHeight ] = useState<number>(0);
  const { setScreen, setMainButtonVisible, setMainButtonProps, setMainButtonDisabled } = useScreen();

  useEffect(() => {
    setMainButtonVisible(true)
    setMainButtonDisabled(false)
    setMainButtonProps(btnName || t("widgets.forms.note.mainButton"), () => {
      onChange && onChange(text);
      onApply && onApply(text);
      setScreen(undefined)
    })
  }, [ text ]);

  useEffect(() => {
    setText(value || "");
  }, [ value ]);

  useEffect(() => {
    calculateRows();
    calculateMinHeight();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ text ]);

  const handleResize = () => {
    calculateRows();
    calculateMinHeight();
  };

  const calculateRows = () => {
    const screenHeight = window.innerHeight;
    const availableHeight = screenHeight;
    const lineHeightPx = lineHeight || 28;
    const newRows = Math.floor(availableHeight / lineHeightPx);
    setRows(newRows);
  };

  const calculateMinHeight = () => {
    const screenHeight = window.innerHeight - 50;
    setMinHeight(screenHeight);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  return (
    <div className="w-full h-full overflow-y-auto p-5">
      {title && (
        <div className="mb-3 text-center">
          <p className="font-medium text-tg-theme-text">{title}</p>
        </div>
      )}
      <textarea
        className="p-5 w-full rounded-xl text-tg-theme-text bg-tg-theme-section focus:outline-none
           transition-height duration-300 ease-in-out"
        value={text}
        autoFocus
        onChange={handleChange}
        rows={rows} // Добавлено для корректной работы с auto-sizing textarea
        style={{
          height: `${rows * (lineHeight || 28)}px`,
          minHeight: `${minHeight}px`,
          maxHeight: `${minHeight}px`, // Установка максимальной высоты
        }}
      />
    </div>
  );
};

export default Note;
