import { api } from "./api"
import { Session } from "app/types/session"

export type SessionRequest = {
  init_data: string
  timezone: string
  locale: string
}

export type SessionResponse = {
  token: string
}

export const sessionApi = api.injectEndpoints({
  endpoints: (build) => ({
    createSession: build.mutation<Session, SessionRequest>({
      query: (credentials: any) => ({
        url: "v1/session",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (response: SessionResponse): Session => {
        return {
          token: response.token,
        } as Session
      },
      extraOptions: { maxRetries: 10 }
    }),
  }),
})

export const {
  useCreateSessionMutation,
} = sessionApi

export const {
  endpoints: { createSession },
} = sessionApi

