import { LocationAskInvitee } from "app/types/location";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Card from "../../../components/card";
import { useTranslation } from "react-i18next";

interface LocationAskInviteeProps {
  value: LocationAskInvitee,
  onChange: (v: LocationAskInvitee) => void
  onRemove?: () => void
  connected: boolean
}

const LocationAskInviteeForm: FC<LocationAskInviteeProps> = ({
  value,
  onChange,
  onRemove,
  connected,
}) => {
  const [ data, setData ] = useState<LocationAskInvitee>({
    ...value
  })

  const { t } = useTranslation()

  useEffect(() => {
    setData({
      ...value
    })
  }, [ value ])

  return (
    <div className="px-5">

      <div className="font-bold pt-5 pb-8 text-center">
        {t("widgets.forms.location.form.locationAskInviteeForm.title")}
      </div>

      <Card title={t("widgets.forms.location.form.locationAskInviteeForm.details")}>
        {t("widgets.forms.location.form.locationAskInviteeForm.info")}
      </Card>

      {connected && onRemove && (
        <Card className="mt-5" onClick={onRemove}>
          <div className="text-tg-theme-destructive">
            {t("widgets.forms.location.buttons.remove")}
          </div>
        </Card>
      )}
    </div>
  )
}

export default LocationAskInviteeForm

