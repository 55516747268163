import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { TimeSpot } from "./timepicker";
import moment from "moment";
import { useHapticFeedback } from "app/telegram";

interface IFormTimeSpot {
  xOnly?: boolean,
  yOnly?: boolean,
  xStep?: number,
  yStep?: number,

  onChange?: (spot: TimeSpot) => void,
  onSelect?: (spot: TimeSpot) => void,
  onRemove?: (spot: TimeSpot) => void,

  onDragStart?: (spot: TimeSpot) => void,
  onDragMove?: (spot: TimeSpot) => void
  onDragEnd?: (spot: TimeSpot) => void

  onScaleStart?: (spot: TimeSpot) => void,
  onScaleMove?: (spot: TimeSpot) => void,
  onScaleEnd?: (spot: TimeSpot) => void,

  resizable?: boolean,
  editable?: boolean,
  active?: boolean,

  timeSpot: TimeSpot
}

const offset = 24; // -- смещения сетки
const stepSize = 24; // -- высота блока размещения spot

export const parseTime = (time: string): { hours: number, minutes: number } => {
  const [ hours, minutes ] = time.split(":")

  return {
    hours: Number(hours) || 0,
    minutes: Number(minutes) || 0
  }
}

export const parseDuration = (duration: string): number => {
  let dt = moment.duration("PT" + duration?.toUpperCase())
  return dt.days() * 24 * 60 + dt.hours() * 60 + dt.minutes()
}

export const calcSpotPosition = (t: string): Number => {
  let time = parseTime(t)
  return time.hours * (4 * stepSize) + (time.minutes / 15) * stepSize + offset
}

export const calculateTimeFromSpotPosition = (top: number): string => {
  // Вычитаем offset из top
  const topAdjusted = top - offset;
  // Находим количество часов
  const timeHours = Math.floor(topAdjusted / (4 * stepSize));
  // Находим оставшуюся часть, которая соответствует минутам
  const remainingForMinutes = topAdjusted % (4 * stepSize);
  // Находим количество минут
  const timeMinutes = (remainingForMinutes / stepSize) * 15;

  return moment(timeHours + ":" + timeMinutes.toLocaleString("en-US", { minimumIntegerDigits: 2 }), "HH:mm").format("HH:mm")
}

export const getHeight = (duration: string) => {
  return ((parseDuration(duration) / 15) * stepSize).toString()
}

const TimeSpotItem: FC<IFormTimeSpot> = ({
  xOnly, yOnly, xStep, yStep,
  onChange, onSelect, onRemove,
  onDragStart, onDragEnd, onDragMove, onScaleStart, onScaleEnd, onScaleMove,
  editable, active, resizable,
  timeSpot
}) => {

  const [ , , selectionChanged ] = useHapticFeedback();
  const dragRef = useRef<HTMLDivElement>(null);
  const [ spot, setSpot ] = useState<TimeSpot>(timeSpot)

  useEffect(() => {
    setSpot(timeSpot)
  }, [ timeSpot ]);

  let isMouseDown: boolean = false;
  let isTouchedUp: boolean = false;
  let isTouchedDown: boolean = false;

  let offset: Array<number> = [ 0, 0 ];

  const setContainerContext = (content: string) => {
    if (!dragRef.current) return;
    let container = dragRef.current.querySelector<HTMLElement>(":scope>div>div>span#content-spot")
    if (!container) return;
    container.textContent = content
  }

  const calculateSpotPositionY = (y: number): Number => {
    if (!yStep) yStep = 1;
    return (Math.floor((y + offset[1]) / yStep) * yStep)
  }

  const calculateSpotPositionX = (x: number): Number => {
    if (!xStep) xStep = 1;
    return (Math.floor((x + offset[0]) / xStep) * xStep)
  }

  const calculateSpotPosition = (spot: TimeSpot) => {

    if (!dragRef.current) return;

    const dragDiv = dragRef.current
    if (!dragDiv) return;

    let top = Number(dragDiv.style.top.match(/^[0-9]+/g)?.[0]) || 0;
    let height = Number(dragDiv.style.height.match(/^[0-9]+/g)?.[0]) || 0;

    let start = calculateTimeFromSpotPosition(top)

    let duration = height / stepSize * 15 + "m"

    // обновляем контент внутри span при drag&drop карточки
    setContainerContext(start + "-" + moment(start, "HH:mm:ss")
      .add(parseDuration(duration), "minutes").format("HH:mm") + " (" + duration + ")")

    let arrowUp = dragRef.current.querySelector<HTMLElement>(":scope>div#scale-up-spot")
    if (arrowUp) {
      arrowUp.style.top = top - 20 + "px"
    }

    let arrowDown = dragRef.current.querySelector<HTMLElement>(":scope>div#scale-down-spot")
    if (arrowDown) {
      arrowDown.style.top = height + "px"
    }
  }

  const finalizeSpotPosition = (spot: TimeSpot) => {
    if (!dragRef.current) return;

    const dragDiv = dragRef.current
    if (!dragDiv) return;

    let top = Number(dragDiv.style.top.match(/^[0-9]+/g)?.[0]) || 0;
    let height = Number(dragDiv.style.height.match(/^[0-9]+/g)?.[0]) || 0;

    spot.start = calculateTimeFromSpotPosition(top)
    spot.duration = height / stepSize * 15 + "m"

    setSpot(spot)
    onScaleEnd && onScaleEnd(spot)
    onChange && onChange(spot)
  }

  const setOffset = (dragDiv: HTMLDivElement, e: any) => {

    const isTouch: boolean = /touch/g.test(e.type);
    const x: number = isTouch ? e.touches[0].clientX : e.clientX;
    const y: number = isTouch ? e.touches[0].clientY : e.clientY;

    offset = [
      dragDiv.offsetLeft - x,
      dragDiv.offsetTop - y
    ];
  }

  const onScaleUpStart = (e: any) => {

    selectionChanged()
    e.preventDefault()

    isTouchedUp = true;
    isTouchedDown = false;

    const dragDiv = dragRef.current
    if (!dragDiv) return;

    setOffset(dragDiv, e)

    dragDiv.children[1].addEventListener("mouseup", onScaleUpEnd, true);
    dragDiv.children[1].addEventListener("touchend", onScaleUpEnd, true);

    document.addEventListener("contextmenu", onContextMenu, false);
    document.addEventListener("touchmove", onScaleUpScale, true);
    document.addEventListener("mousemove", onScaleUpScale, true);

    onScaleStart && onScaleStart(spot)
  }

  const onScaleUpEnd = () => {

    selectionChanged()

    const dragDiv = dragRef.current
    if (!dragDiv) return;

    if (isTouchedUp && dragRef.current) {
      finalizeSpotPosition(spot)
    }

    dragDiv.children[1].removeEventListener("mouseup", onScaleUpEnd, true);
    dragDiv.children[1].removeEventListener("touchend", onScaleUpEnd, true);

    document.removeEventListener("touchmove", onScaleUpScale, true);
    document.removeEventListener("mousemove", onScaleUpScale, true);
    document.removeEventListener("contextmenu", onContextMenu, false);
  }

  const onScaleUpScale = useCallback((e: any) => {

    const isTouch: boolean = /touch/g.test(e.type);

    if (!isTouch) {
      e.preventDefault();
    }

    const dragDiv = dragRef.current
    if (!dragDiv) return;

    if (isTouchedUp && dragRef.current) {
      const y: number = isTouch ? e.touches[0].clientY : e.clientY;

      // устновили новую высоту
      dragRef.current.style.top = calculateSpotPositionY(y) + "px"
      // высчитываем высоту элемента
      dragRef.current.style.height = (Number(calcSpotPosition(spot.start)) -
        Math.floor((y + offset[1]) / stepSize) * stepSize) + (parseDuration(spot.duration) / 15 * stepSize) + "px"

      calculateSpotPosition(spot)
    }

  }, [])

  const onScaleDownStart = (e: any) => {

    selectionChanged()
    e.preventDefault()

    isTouchedDown = true;
    isTouchedUp = false;

    const dragDiv = dragRef.current
    if (!dragDiv) return;

    setOffset(dragDiv, e)

    dragDiv.children[2].addEventListener("mouseup", onScaleDownEnd, true);
    dragDiv.children[2].addEventListener("touchend", onScaleDownEnd, true);

    document.addEventListener("contextmenu", onContextMenu, false);
    document.addEventListener("touchmove", onScaleDownScale, true);
    document.addEventListener("mousemove", onScaleDownScale, true);

    onScaleStart && onScaleStart(spot)
  }

  const onScaleDownEnd = () => {

    selectionChanged()

    const dragDiv = dragRef.current
    if (!dragDiv) return;

    if (isTouchedDown && dragRef.current) {
      finalizeSpotPosition(spot)
    }

    dragDiv.children[2].removeEventListener("mouseup", onScaleDownEnd, true);
    dragDiv.children[2].removeEventListener("touchend", onScaleDownEnd, true);

    document.removeEventListener("touchmove", onScaleDownScale, true);
    document.removeEventListener("mousemove", onScaleDownScale, true);
    document.removeEventListener("contextmenu", onContextMenu, false);
  }

  const onScaleDownScale = useCallback((e: any) => {

    const isTouch: boolean = /touch/g.test(e.type);

    if (!isTouch) {
      e.preventDefault();
    }

    const dragDiv = dragRef.current
    if (!dragDiv) return;

    if (isTouchedDown && dragRef.current) {
      const y: number = isTouch ? e.touches[0].clientY : e.clientY;

      let start = Number(dragDiv.style.top.match(/^[0-9]+/g)?.[0]) || 0
      dragRef.current.style.height = Math.floor((y + offset[1] - start) / stepSize) * stepSize +
        (parseDuration(spot.duration) / 15 * stepSize) + "px"

      calculateSpotPosition(spot)
    }

  }, [ isTouchedDown, offset, spot ]);

  const onMouseDown = (e: any) => {
    selectionChanged()
    isMouseDown = true;

    const dragDiv = dragRef.current
    if (!dragDiv) return;

    setOffset(dragDiv, e)

    if (onDragStart) {
      onDragStart(spot);
    }

    e.preventDefault()

    dragDiv.children[0].addEventListener("mouseup", onMouseUp, true);
    dragDiv.children[0].addEventListener("touchend", onMouseUp, true);

    document.addEventListener("contextmenu", onContextMenu, false);
    document.addEventListener("touchmove", onMouseMove, true);
    document.addEventListener("mousemove", onMouseMove, true);
  }

  const setSpotStart = () => {

    const dragDiv = dragRef.current;
    if (!dragDiv) return;

    let pos = [ 0, 0 ];
    pos[0] = Number(dragDiv.style.left.match(/^[0-9]+/g)?.[0]) || 0;
    pos[1] = Number(dragDiv.style.top.match(/^[0-9]+/g)?.[0]) || 0;

    spot.start = calculateTimeFromSpotPosition(pos[1])
    setSpot(spot)
  }

  const onMouseUp = () => {
    selectionChanged()
    isMouseDown = false;

    setSpotStart()

    if (!isMouseDown) {
      onDragEnd && onDragEnd(spot)
      onChange && onChange(spot)
    }

    document.removeEventListener("touchmove", onMouseMove, true);
    document.removeEventListener("mousemove", onMouseMove, true);
    document.removeEventListener("contextmenu", onContextMenu, false);
  }

  const onMouseMove = useCallback((e: any) => {
    const isTouch: boolean = /touch/g.test(e.type);

    if (!isTouch) {
      e.preventDefault();
    }

    if (isMouseDown && dragRef.current) {
      let x: number = isTouch ? e.touches[0].clientX : e.clientX;
      let y: number = isTouch ? e.touches[0].clientY : e.clientY;

      !yOnly && (dragRef.current.style.left = calculateSpotPositionX(x) + "px");
      !xOnly && (dragRef.current.style.top = calculateSpotPositionY(y) + "px");
    }

    setSpotStart()

    const startTime = moment(spot.start, "HH:mm")
    const endTime = startTime.clone().add(parseDuration(spot.duration), "minutes")

    // обновляем контент внутри span при drag&drop карточки
    setContainerContext(startTime + "-" + endTime.format("HH:mm") + " (" + spot.duration + ")")

    onDragMove && onDragMove(spot)

  }, []);

  const onContextMenu = () => {
    document.removeEventListener("mousemove", onMouseMove, true);
    document.removeEventListener("touchmove", onMouseMove, true);

    document.removeEventListener("mousemove", onScaleUpScale, true);
    document.removeEventListener("touchmove", onScaleUpScale, true);

    document.removeEventListener("mousemove", onScaleDownScale, true);
    document.removeEventListener("touchmove", onScaleDownScale, true);
  }

  const handleSelect = (e: React.MouseEvent | React.TouchEvent, spot: TimeSpot) => {
    e.preventDefault()
    onSelect && onSelect(spot)
  }

  const handleRemove = (e: React.MouseEvent | React.TouchEvent, spot: TimeSpot) => {
    e.preventDefault()
    onRemove && onRemove(spot)
  }

  useEffect(() => {

    const dragDiv = dragRef.current;

    dragDiv?.children[0].addEventListener("touchstart", onMouseDown, true);
    dragDiv?.children[0].addEventListener("mousedown", onMouseDown, true);

    dragDiv?.children[1].addEventListener("touchstart", onScaleUpStart, true);
    dragDiv?.children[1].addEventListener("mousedown", onScaleUpStart, true);

    dragDiv?.children[2].addEventListener("touchstart", onScaleDownStart, true);
    dragDiv?.children[2].addEventListener("mousedown", onScaleDownStart, true);

    return () => {

      // перемещение блока
      if (!isTouchedUp && !isTouchedDown) {
        dragDiv?.children[0].removeEventListener("mousedown", onMouseDown, true);
        dragDiv?.children[0].removeEventListener("mouseup", onMouseUp, true);
        document.removeEventListener("mousemove", onMouseMove, true);

        dragDiv?.children[0].removeEventListener("touchstart", onMouseDown, true);
        dragDiv?.children[0].removeEventListener("touchend", onMouseUp, true);
        document.removeEventListener("touchmove", onMouseMove, true);
      }
      // масштабирование вверх
      if (isTouchedUp) {
        dragDiv?.children[1]?.removeEventListener("mousedown", onScaleUpStart, true);
        dragDiv?.children[1]?.removeEventListener("mouseup", onScaleUpEnd, true);
        document.removeEventListener("mousemove", onScaleUpScale, true);

        dragDiv?.children[1].removeEventListener("touchstart", onScaleUpStart, true);
        dragDiv?.children[1].removeEventListener("touchend", onScaleUpEnd, true);
        document.removeEventListener("touchmove", onScaleUpScale, true);
      }
      // масштабирование вниз
      if (isTouchedDown) {
        dragDiv?.children[2].removeEventListener("mousedown", onScaleDownStart, true);
        dragDiv?.children[2].removeEventListener("mouseup", onScaleDownEnd, true);
        document.removeEventListener("mousemove", onScaleDownScale, true);

        dragDiv?.children[2].removeEventListener("touchstart", onScaleDownStart, true);
        dragDiv?.children[2].removeEventListener("touchend", onScaleDownEnd, true);
        document.removeEventListener("touchmove", onScaleDownScale, true);
      }

      document.removeEventListener("contextmenu", onContextMenu, false);
    }
  }, []);

  const start = moment(spot.start, "HH:mm")
  const end = start.add(parseDuration(spot.duration), "minutes").format("HH:mm")

  return (
    <div
      ref={dragRef}
      id={spot.start}
      className="w-full absolute cursor-pointer"
      onClick={(e) => handleSelect(e, spot)}
      onTouchEnd={(e) => handleSelect(e, spot)}
      style={{
        zIndex: 10,
        top: Number(calcSpotPosition(spot.start)) + "px",
        height: Number(getHeight(spot.duration)) + "px"
      }}
    >
      <div className="flex h-full">

        <div className="w-14 items-center border-r"></div>
        <div
          className="w-full border-tg-theme-button-text border-2 font-bold
            order-tg-theme-button-text text-tg-theme-background-main bg-tg-theme-button"
          style={{ fontSize: "10px" }}
        >
          <span id={"content-" + spot.start} className="inline-block font-bold p-1">
            {spot.start} - {end} ({spot.duration})
          </span>
        </div>
        <div className="absolute" style={{ top: "50%", "marginTop": "-12px", right: "40%", "zIndex": 10 }}>
          <i className="fa-solid fa-arrows-up-down"/>
        </div>
      </div>

      <div id={"scale-top-spot-" + spot.start}
        className={`absolute ${!resizable ? "hidden" : ""}`}
        style={{ top: "-20px", right: "20px", "zIndex": 10 }}>
        <i className="fa-solid fa-circle"/>
      </div>

      <div
        id={"scale-down-spot-" + spot.start}
        className={`absolute ${!resizable ? "hidden" : ""}`}
        style={{ top: (Number(getHeight(spot.duration))) + "px", left: "70px", "zIndex": 10 }}
      >
        <i className="fa-solid fa-circle"/>
      </div>

      {!!onRemove && (
        <div className="flex items-center justify-center absolute text-tg-theme-destructive w-6 h-6"
          style={{ top: "50%", "marginTop": "-12px", right: "2%", "zIndex": 10 }}
          onClick={(e) => handleRemove(e, spot)}
          onTouchEnd={(e) => handleRemove(e, spot)}
        >
          <i className="fa fa-times"/>
        </div>
      )}

    </div>
  )
}

export default TimeSpotItem;
