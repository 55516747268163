import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { List, ListItem } from "../../../components/list/list";

interface LocationZoomProps {
  onRemove?: () => void
  connected: boolean
}

const LocationZoomForm: FC<LocationZoomProps> = ({ onRemove, connected }) => {
  const { t } = useTranslation()
  return (
    <div className="px-5">

      <div className="font-bold pt-5 pb-8 text-center">
        {t("widgets.forms.location.form.locationZoomForm.title")}
      </div>

      {connected && onRemove && (
        <List className="mt-5">
          <ListItem
            onClick={onRemove}>
            <div className="text-tg-theme-destructive">
              {t("widgets.forms.location.buttons.remove")}
            </div>
          </ListItem>
        </List>
      )}
    </div>
  )
}

export default LocationZoomForm

