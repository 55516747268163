import React, { ReactNode } from "react"

interface Card {
  title?: string
  hint?: string
  className?: string
  onClick?: () => void
  children?: ReactNode
  disabled?: boolean
}

const Card = ({ title, hint, children, onClick, className, disabled }: Card) => {
  return (
    <>
      {title && <div className="text-tg-theme-subtitle text-xs uppercase ml-3 mb-2">{title}</div>}
      <div
        className={`rounded-xl p-4 bg-tg-theme-section ${className} ${disabled ? "opacity-50 cursor-not-allowed" : ""}
        `}
        onClick={!disabled ? onClick : undefined}
      >
        {children}
      </div>
      {hint && <div className="text-xs font-light text-tg-theme-hint ml-3 py-1">{hint}</div>}
    </>
  )
}

export default Card
