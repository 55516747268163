import { retry } from "@reduxjs/toolkit/query/react"
import { Account } from "app/types/account"
import { api } from "./api"
import { EmptyRequest } from "./empty"
import { setAccount } from "features/auth/authSlice";
import { handleError } from "utils";
import { FormatTime } from "../types/common";

function getAccountFromResponse(src: AccountResponse): Account {
  return {
    ...src
  }
}

export type AccountResponse = {
  id: string,
  username: string,
  first_name: string,
  last_name: string,
  avatar: string,
  timezone: string,
  language: string,
  country: string,
  format_date: string,
  format_time: FormatTime,
  created: number,
  updated: number,
}

export type AccountUpdateRequest = {
  username: string,
  first_name: string,
  last_name: string,
  timezone: string,
  language: string,
  country: string,
  format_date: string,
  format_time: string,
}

export type AccountAvatarUploadRequest = {
  file: BinaryData
}

export const accountApi = api.injectEndpoints({
  endpoints: (build) => ({

    accountFetch: build.query<Account, EmptyRequest>({
      query: () => ({
        url: "v1/account",
        method: "GET",
      }),
      transformResponse: (response: AccountResponse): Account => {
        return getAccountFromResponse(response);
      },
    }),

    accountUpdate: build.mutation<Account, AccountUpdateRequest>({
      query: (account: AccountUpdateRequest) => ({
        url: "v1/account",
        method: "PUT",
        body: account,
      }),
      transformResponse: (response: AccountResponse): Account => {
        return getAccountFromResponse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccount(data));
        } catch (err) {
          handleError(err, "Service: Failed to update avatar")
        }
      },
    }),

    accountAvatarUpdate: build.mutation<any, FormData>({
      query: (formData) => ({
        url: "v1/account/avatar",
        method: "POST",
        body: formData,
      }),
      transformResponse: (response: AccountResponse): Account => {
        return getAccountFromResponse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccount(data));
        } catch (err) {
          handleError(err, "Service: Failed to update avatar")
        }
      },
    }),
  }),
})

export const {
  useAccountFetchQuery,
  useLazyAccountFetchQuery,
  useAccountUpdateMutation,
  useAccountAvatarUpdateMutation
} = accountApi

export const {
  endpoints: { accountFetch, accountUpdate, accountAvatarUpdate },
} = accountApi

