import { api } from "./api"
import { EmptySuccessResponse } from "./empty"
import {
  Schedule,
  ScheduleRule,
  ScheduleRuleDay,
  ScheduleRuleType,
  ScheduleRuleWDay,
  ScheduleRuleWDayType
} from "app/types/schedule";

export function getScheduleRulesFromResponse(src: ScheduleRuleResponse[]): ScheduleRule[] {
  return src ? src.map(getScheduleRuleFromResponse) : []
}

export function getScheduleRuleFromResponse(rule: ScheduleRuleResponse): ScheduleRule {
  switch (rule.type) {
    case "wday":
    {
      return {
        type: rule.type,
        wday: rule.wday,
        disabled: rule.disabled,

        intervals: rule.intervals?.map(interval => ({
          from: interval.from,
          to: interval.to
        }))
      } as ScheduleRuleWDay
    }
    case "date":
    {
      return {
        type: "date",
        date: rule.date,
        intervals: rule.intervals?.map(interval => ({
          from: interval.from,
          to: interval.to
        }))
      } as ScheduleRuleDay
    }
  }
}

export function getScheduleFromResponse(src: ScheduleResponse): Schedule {

  return {
    id: src.id,

    profile_id: src.profile_id,
    name: src.name,
    timezone: src.timezone,

    rules: getScheduleRulesFromResponse(src.rules),

    created: src.created,
    updated: src.updated,
  }
}

export type ScheduleCreateRequest = {
  id: string,
  name: string,
  timezone: string,
  rules: ScheduleRuleRequest[],
}

export type ScheduleUpdateRequest = ScheduleCreateRequest

export type ScheduleResponse = {
  id: string,
  profile_id: string,
  name: string,
  timezone: string,
  rules: ScheduleRuleResponse[],
  created: number,
  updated: number,
}

export type ScheduleListResponse = {
  total: number,

  cursor: string,
  order: string,

  items: ScheduleResponse[]
}

export type ScheduleRuleRequest = {
  type: ScheduleRuleType,

  wday?: ScheduleRuleWDayType,
  date?: string,
  disabled?: boolean,

  intervals: {
    from: string
    to: string
  }[]
}

export type ScheduleRuleResponse = {
  type: ScheduleRuleType,

  wday?: ScheduleRuleWDayType,
  date?: string,
  disabled?: boolean,

  intervals: {
    from: string
    to: string
  }[]
}

export interface ScheduleListParams {
  profile_id?: string
}

export const scheduleApi = api.injectEndpoints({
  endpoints: (build) => ({
    scheduleList: build.query<Schedule[], ScheduleListParams>({
      query: ({ profile_id }) => ({
        url: "v1/schedules",
        method: "GET",
        params: { profile_id }
      }),
      transformResponse: (response: ScheduleListResponse): Schedule[] => {
        return response.items.map(getScheduleFromResponse);
      },
      providesTags: (result: Schedule[] = []) => [
        ...result.map(({ id }) => ({ type: "Schedules", id }) as const),
        { type: "Schedules" as const, id: "LIST" },
      ],
    }),
    scheduleCreate: build.mutation<Schedule, ScheduleCreateRequest>({
      query: (data: ScheduleCreateRequest) => ({
        url: "v1/schedule",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: ScheduleResponse): Schedule => {
        return getScheduleFromResponse(response);
      },
      invalidatesTags: [ { type: "Schedules", id: "LIST" } ],
    }),
    scheduleFetch: build.query<Schedule, string>({
      query: (id: string) => ({
        url: `v1/schedule/${id}`,
        method: "GET",
      }),
      transformResponse: (response: ScheduleResponse): Schedule => {
        return getScheduleFromResponse(response);
      },
      providesTags: (result, error, id) => [ { type: "Schedules", id } ],
    }),
    scheduleUpdate: build.mutation<Schedule, { id: string, data: ScheduleUpdateRequest }>({
      query: ({ id, data }) => ({
        url: `v1/schedule/${id}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: ScheduleResponse): Schedule => {
        return getScheduleFromResponse(response);
      },
      invalidatesTags: (result, error, { id }) => [ { type: "Schedules", id } ],
    }),
    scheduleDelete: build.mutation<EmptySuccessResponse, string>({
      query: (id) => ({
        url: `v1/schedule/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [ { type: "Schedules", id } ],
    }),
  }),
})

export const {
  useScheduleListQuery,
  useScheduleCreateMutation,
  useScheduleFetchQuery,
  useLazyScheduleFetchQuery,
  useScheduleUpdateMutation,
  useScheduleDeleteMutation
} = scheduleApi

export const {
  endpoints: {
    scheduleList,
    scheduleCreate,
    scheduleFetch,
    scheduleUpdate,
    scheduleDelete
  },
} = scheduleApi

