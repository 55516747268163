import React, { FC } from "react"
import { Location, locations, LocationType, LocationTypes } from "app/types/location";
import { useWebApp } from "app/telegram";
import { useTranslation } from "react-i18next";

interface Props {
  location: Location;
  isInvitee: boolean
}

const EventLocationCard: FC<Props> = ({ location, isInvitee }) => {
  const { t } = useTranslation();
  const webApp = useWebApp()

  const renderLocationTitle = () => {
    const loc = (location || {}) as Location;

    switch (loc.kind) {
      case LocationTypes.AskInvitee:
        return (
          <span className="font-bold text-tg-theme-accent-text">
            {isInvitee
              ? t("features.event.components.eventLocationCard.suggestedMeetingPlaceInTitle")
              : t("features.event.components.eventLocationCard.suggestedMeetingPlaceOutTitle")
            }
          </span>
        );
      case LocationTypes.Phone:
        return (
          <span className="font-bold text-tg-theme-accent-text">
            {isInvitee ?
              loc.outbound
                ? t("features.event.components.eventLocationCard.phoneOutboundTitle")
                : t("features.event.components.eventLocationCard.phoneIncomingTitle")
              : loc.outbound
                ? t("features.event.components.eventLocationCard.phoneIncomingTitle")
                : t("features.event.components.eventLocationCard.phoneOutboundTitle")
            }
          </span>
        );
      case LocationTypes.Telegram:
        return (
          <span className="font-bold text-tg-theme-accent-text">
            {isInvitee
              ? !loc.outbound
                ? loc.video
                  ? t("features.event.components.eventLocationCard.telegramOutboundVideoTitle")
                  : t("features.event.components.eventLocationCard.telegramOutboundTitle")
                : loc.video
                  ? t("features.event.components.eventLocationCard.telegramIncomingVideoTitle")
                  : t("features.event.components.eventLocationCard.telegramIncomingTitle")
              : !loc.outbound
                ? loc.video
                  ? t("features.event.components.eventLocationCard.telegramIncomingVideoTitle")
                  : t("features.event.components.eventLocationCard.telegramIncomingTitle")
                : loc.video
                  ? t("features.event.components.eventLocationCard.telegramOutboundVideoTitle")
                  : t("features.event.components.eventLocationCard.telegramOutboundTitle")
            }
          </span>
        );
      default:
        return t(`common.locations.${loc.kind}.title`);
    }
  };

  const handleOpenLink = (link: string) => {
    webApp.openLink(link)
  }

  return (
    <>

      <div className="flex flex-inline content-center gap gap-1">
        <span className="w-4 text-left">
          <i
            className={locations[(location?.kind as LocationType)]?.ico + " fa-xs w-4 m-auto text-tg-theme-accent-text"}/>
        </span>
        <span className="font-bold text-tg-theme-accent-text">
          {renderLocationTitle()}
        </span>
      </div>

      <div>
        {!!location.note && (
          <p className="text-xs text-tg-theme-hint mt-2">
            <span className="text-xs">{location.note}</span>
          </p>
        )}

        {location.kind === LocationTypes.Phone && !!location?.phone && (
          <p className="text-xs text-tg-theme-hint mt-2">
            <span className="text-xs">{location?.phone}</span>
          </p>
        )}

        {!!(location as any)?.link && (
          <p className="text-xs text-tg-theme-link mt-2" onClick={() => handleOpenLink((location as any)?.link)}>
            <span className="text-xs">
              {(location as any)?.link}
            </span>
          </p>
        )}
      </div>

    </>
  );
};

export default EventLocationCard;
