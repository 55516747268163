import { useTranslation } from "react-i18next";
import EventLoading from "./eventLoading";
import { usePendingEventListQuery } from "app/services/events";
import { PendingEvent } from "app/types/event";
import PendingEventItem from "./pendingEventItem";
import { useNavigate } from "react-router-dom";

export type PendingEventFilter = {
  createdAfter?: number,
  createdBefore?: number,
  updatedAfter?: number,
  limit?: number,
}

interface PendingEventListProps {
  filter?: PendingEventFilter
}

export const PendingEventList = ({ filter }: PendingEventListProps) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const { data: pendingEvents, isLoading } = usePendingEventListQuery({ cursor_order: "desc" })

  if (isLoading) return <EventLoading/>
  if (!pendingEvents || !pendingEvents.length) {
    return (
      <div className="text-center">
        {t("features.event.components.pendingEventList.no-pendingEvents")}
      </div>
    )
  }

  return (
    <div>
      {pendingEvents.map((event: PendingEvent, index) => (
        <div key={index} className="mb-3" onClick={() => navigate(`/eventtype/${event.id}`)}>
          <PendingEventItem key={event.id} event={event}/>
        </div>
      ))}
    </div>
  )
}
