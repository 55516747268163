import React, { useEffect } from "react";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "context/theme.provider";
import { ScreenProvider } from "context/screen.provider";
import Oops from "widgets/components/oops";
import App from "App";
import { store } from "./app/store";
import * as Sentry from "@sentry/react";
import "app/i18n";

import "assets/theme/main.scss";


if (window._env_?.SENTRY && !!window._env_?.SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: window._env_.SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    release: window._env_.SENTRY_RELEASE,
    dist: window._env_.SENTRY_RELEASE,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById("root")!;
const root = createRoot(container);

let appReady = Promise.resolve();

appReady.then(() => {
  root.render(
    // <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<Oops/>}>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider>
            <ScreenProvider>
              <App/>
            </ScreenProvider>
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
    // </React.StrictMode>
  );
});
