import React, { ReactNode, useEffect, useState } from "react";
import {
  Location,
  LocationAskInvitee,
  LocationInPersonalMeeting,
  LocationOption,
  LocationPhoneCall,
  locations,
  LocationTelegramCall,
  LocationType,
  LocationTypes,
} from "app/types/location";
import { List, ListItem } from "widgets/components/list/list";
import { useScreen } from "context/screen.provider";
import {
  LocationAskInviteeForm,
  LocationGoogleForm,
  LocationInPersonalMeetingForm,
  LocationPhoneCallForm,
  LocationTeamsForm,
  LocationTelegramCallForm,
  LocationYandexForm,
  LocationZoomForm
} from "./form";
import { useTranslation } from "react-i18next";

export interface LocationFormProps {
  value: Location | undefined
  onSelect: (v: Location | undefined) => void
  onRemove?: () => void
}

const validateFill = (loc: Location) => {
  // TODO: Validate form
  return true
}

function copyProperties<T, K extends keyof T>(source: T, properties: K[]): Pick<T, K> {
  const result = {} as Pick<T, K>;
  properties.forEach((property) => {
    result[property] = source[property];
  });
  return result;
}

export const LocationForm = ({ value, onSelect, onRemove }: LocationFormProps) => {

  const { t } = useTranslation();
  const [ locationValue, setLocationValue ] = useState<Location | undefined>(value)
  const [ form, setForm ] = useState<ReactNode>(null)
  const {
    setScreen,
    setBackButtonVisible,
    setBackButtonOnClick,
    setMainButtonProps,
    setMainButtonVisible
  } = useScreen();

  useEffect(() => {
    setLocationValue(value)
    value && handleSelect(value?.kind)
  }, [ value ]);

  useEffect(() => {
    setMainButtonVisible(!!form)
  }, [ form ])

  useEffect(() => {
    setBackButtonVisible(true)

    if (!!form) {
      setBackButtonOnClick(() => {
        if (!!value?.kind) {
          setScreen(undefined)
        } else {
          setLocationValue(undefined)
          setForm(undefined)
        }
      })
      setMainButtonProps(t("widgets.forms.location.buttons.apply"), () => {
        if (!locationValue || !validateFill(locationValue)) return
        onSelect(locationValue)
        setScreen(undefined)
      })
      return
    }

    setBackButtonOnClick(() => {
      setLocationValue(value)
      setScreen(undefined)
      setForm(undefined)
    })
  }, [ value, form, locationValue ]);

  const handleRemove = () => {
    onRemove && onRemove()
    setScreen(undefined)
  }

  const handleSelect = (type: LocationType) => {

    let data = { kind: type } as Location
    if (!locationValue || locationValue?.kind !== type) {
      if (type === LocationTypes.Phone) data = { ...data, outbound: true } as LocationPhoneCall
      if (type === LocationTypes.Telegram) data = { ...data, outbound: true } as LocationTelegramCall
    } else {
      data = locationValue as Location
    }

    setLocationValue(data)

    switch (type) {
      case LocationTypes.Phone:
        const locationPhoneCallValue = copyProperties(data as LocationPhoneCall, [ "kind", "note", "phone", "outbound" ])
        setForm(<LocationPhoneCallForm
          onChange={(data: LocationPhoneCall) => setLocationValue({ ...data, kind: type })}
          connected={value?.kind === LocationTypes.Phone}
          onRemove={handleRemove}
          value={locationPhoneCallValue}
        />)
        break;
      case LocationTypes.InPerson:
        const locationInPersonalMeetingValue = copyProperties(data as LocationInPersonalMeeting, [ "kind", "name", "note", "lat", "lng" ])
        setForm(<LocationInPersonalMeetingForm
          onChange={(data: LocationInPersonalMeeting) => setLocationValue({ ...data, kind: type })}
          value={locationInPersonalMeetingValue}
          onRemove={handleRemove}
          connected={value?.kind === LocationTypes.InPerson}
        />)
        break;
      case LocationTypes.AskInvitee:
        const locationAskInviteeValue = copyProperties(data as LocationAskInvitee, [ "kind", "note" ])
        setForm(<LocationAskInviteeForm
          onChange={(data: LocationAskInvitee) => setLocationValue({ ...data, kind: type })}
          value={locationAskInviteeValue}
          onRemove={handleRemove}
          connected={value?.kind === LocationTypes.AskInvitee}
        />)
        break;
      case LocationTypes.Telegram:
        if (!locationValue) data = { ...data, outbound: true } as LocationTelegramCall
        const locationTelegramCallValue = copyProperties(data as LocationTelegramCall, [ "kind", "note", "video", "outbound" ])
        setForm(<LocationTelegramCallForm
          onChange={(data: LocationTelegramCall) => setLocationValue({ ...data, kind: type })}
          value={locationTelegramCallValue}
          onRemove={handleRemove}
          connected={value?.kind === LocationTypes.Telegram}
        />)
        break;
      case LocationTypes.Google:
        setForm(<LocationGoogleForm
          onRemove={handleRemove}
          connected={value?.kind === LocationTypes.Telegram}
        />)
        break
      case LocationTypes.Zoom:
        setForm(<LocationZoomForm
          onRemove={handleRemove}
          connected={value?.kind === LocationTypes.Telegram}
        />)
        break
      case LocationTypes.Teams:
        setForm(<LocationTeamsForm
          onRemove={handleRemove}
          connected={value?.kind === LocationTypes.Telegram}
        />)
        break
      case LocationTypes.Yandex:
        setForm(<LocationYandexForm
          onRemove={handleRemove}
          connected={value?.kind === LocationTypes.Telegram}
        />)
        break
      default:
    }
  }

  if (form) return <div className="py-5">{form}</div>

  return (
    <div className="p-5">

      <div className="font-bold pt-5 pb-8 text-center">
        {t("widgets.forms.location.title")}
      </div>

      <List title={t("widgets.forms.location.label")}>
        {
          Object.keys(locations).map((locType, index) => {
            const location: LocationOption = locations[locType as LocationType]
            const locationType = locationValue?.kind as LocationType;
            return (
              <ListItem
                disabled={location.disabled}
                key={index}
                onClick={() => handleSelect(location.value as LocationType)}>
                <div className="flex justify-between">
                  <div className="">
                    <span className="w-6">
                      <i className={location.ico + " mx-2 fa-xs w-4 m-auto"}/>
                    </span>
                    {t("common.locations." + locType + ".title")}
                  </div>
                  {locationType && locType === locationType &&
                      <div><i className="fa-solid fa-check"/></div>
                  }
                </div>
              </ListItem>
            )
          }
          )}
      </List>
    </div>
  )
};

