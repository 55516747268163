import { retry } from "@reduxjs/toolkit/query/react"
import { api } from "./api"
import { Calendar, CalendarEvent } from "app/types/calendar";

export function getCalendarFromResponse(src: CalendarResponse): Calendar {
  return {
    ...src
  }
}

export type CalendarRequest = {}

export type CalendarResponse = {
  id: string,

  name: string,
  description: string,
  status: string,
  primary: boolean,
  disabled: boolean,

  created: number,
  updated: number,
}

export type CalendarListResponse = {
  total: number,
  items: CalendarResponse[]
}

export type CalendarUpdateRequest = {
  disabled: boolean,
}

export function getCalendarEventFromResponse(src: CalendarEventResponse): CalendarEvent {
  return {
    ...src
  }
}

export type CalendarEventRequest = {
  from?: number
  to?: number
}

export type CalendarEventResponse = {
  name: string;
  description: string;
  start_time: string,
  end_time: string,
  duration: string,
  service: string,
}

export type CalendarEventListResponse = {
  items: CalendarEventResponse[]
}

export const calendarApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCalendarList: build.query<Calendar[], CalendarRequest>({
      query: (params) => {
        let queryString = "";
        const queryParams = new URLSearchParams(params);
        queryString = queryParams.toString()
        return {
          url: `v1/calendars${queryString ? `?${queryString}` : ""}`,
          method: "GET",
        }
      },
      transformResponse: (response: CalendarListResponse): Calendar[] => {
        return response.items.map(getCalendarFromResponse);
      },
      providesTags: (result: Calendar[] = []) => [
        ...result.map(({ id }) => ({ type: "Calendars", id }) as const),
        { type: "Calendars" as const, id: "LIST" },
      ],
    }),
    calendarUpdate: build.mutation<Calendar, { id: string, data: CalendarUpdateRequest }>({
      query: ({ id, data }) => ({
        url: `v1/calendar/${id}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: CalendarResponse): Calendar => {
        return getCalendarFromResponse(response);
      },
    }),
    getCalendarEventList: build.query<CalendarEvent[], CalendarEventRequest>({
      query: ({ from, to }) => {
        return {
          url: "v1/calendars/events",
          method: "GET",
          params: { from, to }
        }
      },
      providesTags: [ "CalendarEvents" ],
      transformResponse: (response: CalendarEventListResponse): CalendarEvent[] => {
        return response.items.map(getCalendarEventFromResponse);
      },
    }),
  }),
})

export const {
  useGetCalendarListQuery,
  useGetCalendarEventListQuery,
  useCalendarUpdateMutation
} = calendarApi

export const {
  endpoints: { getCalendarList },
} = calendarApi

