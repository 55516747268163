import React, { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Calendar, { DateOption, Event as CalendarEvent } from "widgets/forms/calendar";
import { useTranslation } from "react-i18next";
import Card from "../../components/card";

export type Event = CalendarEvent

export interface CalendarMiniProps {
  current: number;
  events?: Event[]
  dates?: DateOption[]
  allowPrevDay?: boolean
  allowEmpty?: boolean
  onChange?: (date: string) => void;
  onDateRange?: (from: string, to: string) => void;
}

const CalendarMini: FC<CalendarMiniProps> = ({
  current,
  events = [],
  onChange,
  onDateRange,
  dates = [],
  allowEmpty,
  allowPrevDay
}) => {

  const { t } = useTranslation()
  const [ currentDate, setCurrentDate ] = useState(moment());
  const [ selectedDate, setSelectedDate ] = useState(moment());
  const [ isCurrentWeek, setIsCurrentWeek ] = useState(true);
  const [ showCalendar, setShowCalendar ] = useState(false)

  const formatted: Record<string, string> = {};
  for (const { date, status } of dates) {
    formatted[date] = status;
  }

  useEffect(() => {
    const date = moment.unix(current);
    setSelectedDate(date);
    setCurrentDate(date.clone().startOf("isoWeek"));
    setIsCurrentWeek(date.clone().startOf("isoWeek").isSame(moment().startOf("isoWeek"), "week"));
  }, [ current ]);

  const startOfWeek = currentDate.clone().startOf("isoWeek");

  const handlePrevWeek = () => {
    if (!allowPrevDay && isCurrentWeek) return;
    const newStartOfWeek = startOfWeek.clone().subtract(1, "week");

    setCurrentDate(newStartOfWeek);
    setIsCurrentWeek(newStartOfWeek.isSame(moment().startOf("isoWeek"), "week"));
    onDateRange && onDateRange(newStartOfWeek.format(), newStartOfWeek.endOf("isoWeek").format())
  };

  const handleNextWeek = () => {
    const newStartOfWeek = startOfWeek.clone().add(1, "week");

    setCurrentDate(newStartOfWeek);
    setIsCurrentWeek(newStartOfWeek.isSame(moment().startOf("isoWeek"), "week"));
    onDateRange && onDateRange(newStartOfWeek.format(), newStartOfWeek.endOf("isoWeek").format())
  };

  const handleSelect = (day: moment.Moment) => {
    if (!allowPrevDay && day.isBefore(moment(), "day")) {
      return;
    }
    setSelectedDate(day);
    onChange && onChange(day.format("YYYY-MM-DD"));
  };

  const handleChangeShowCalendar = () => {
    const show = !showCalendar
    setShowCalendar(show)
    if (show) {
      onDateRange && onDateRange(currentDate.clone().startOf("month").format(), currentDate.endOf("month").format())
    }
  }

  const groupedEvents = useMemo(() => {
    return events.reduce((items, event) => {
      const formattedDate = moment(event.start_time).format("YYYY-MM-DD");
      if (!items[formattedDate]) {
        items[formattedDate] = [];
      }
      items[formattedDate].push(event);
      return items;
    }, {} as { [id: string]: Event[] });
  }, [ events ]);

  const renderDays = () => {
    const days = [];
    let day = startOfWeek.clone();

    for (let i = 0; i < 7; i++) {
      const isCurrent = day.isSame(moment(), "day");
      const isSelected = day.isSame(selectedDate, "day");
      const hasEvents = !!groupedEvents[day.format("YYYY-MM-DD")];

      // Создаем копию переменной day, чтобы передать в handleSelect
      const dayCopy = day.clone();

      days.push(
        <div
          key={day.format("YYYY-MM-DD")}
          className={`flex items-center justify-center text-center cursor-pointer rounded-xl text-sm h-14 w-9.5
          ${isSelected ? "text-tg-theme-text border border-tg-theme-text" : ""}
          ${isCurrent ? "!text-black dark:!text-white font-bold": ""}
          ${isCurrent && !isSelected ? "border-2 border-tg-theme-accent-text text-tg-theme-accent-text" : ""}
          ${!isSelected && formatted[day.format("ll")] === "available"
            ? "bg-tg-theme-secondary text-tg-theme-accent-text border border-tg-theme-accent-text"
            : "bg-tg-theme-secondary text-tg-theme-hint"}
          ${day.isBefore(moment()) ? "cursor-not-allowed" : ""}`}
          onClick={() => handleSelect(dayCopy)}
        >
          <div className="flex flex-col justify-center items-center text-center relative">
            <span className="text-xs">
              {t(`common.calendar.days-short.${day.format("ddd").toLowerCase()}`)}
            </span>
            <span>
              {day.format("D")}
            </span>
            {hasEvents && (
              <span className="absolute -bottom-1 block mt-1 w-1 h-1 bg-tg-theme-button rounded-full"/>
            )}
          </div>
        </div>
      );

      day.add(1, "day");
    }

    return days;
  };

  return (
    <div className="w-full relative">

      <div className="flex justify-between items-center mb-4 px-2">

        <div className="text-tg-theme-link" onClick={handleChangeShowCalendar}>
          {t(`common.calendar.months.${currentDate.format("MMMM").toLowerCase()}`)}, {currentDate.format("YYYY")} <i className={`fa fa-chevron-${showCalendar ? "down" : "right"}`}/>
        </div>

        <div className="text-tg-theme-link" onClick={() => handleSelect(moment())}>
          {t("widgets.forms.calendarmini.today")}
        </div>

      </div>

      {showCalendar && (
        <Card className="w-full top-8 absolute z-50">
          <Calendar
            current={selectedDate.unix()}
            events={events}
            dates={dates}
            onDateRange={onDateRange}
            allowEmpty={allowEmpty}
            allowPrevDay={allowPrevDay}
            onSelect={(date) => {
              handleSelect(moment(date))
              setShowCalendar(false)
            }}
          />
        </Card>
      )}

      <div className="h-[3.5rem]">
        {!showCalendar && (
          <div className="flex items-center justify-between">
            <div className="w-5 text-left">
              <i
                className={`fa-solid fa-chevron-left cursor-pointer ${!allowPrevDay && isCurrentWeek ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handlePrevWeek}
              />
            </div>

            <div className="grid grid-cols-7 gap-1 flex-1">{renderDays()}</div>

            <div className="w-5 text-right">
              <i className="fa-solid fa-chevron-right cursor-pointer" onClick={handleNextWeek}/>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarMini;
