import React from "react";
import { useTranslation } from "react-i18next";

const EventTypeFetchError = () => {

  const { t } = useTranslation()

  return (
    <>
      <div className="px-5 max-w-full mx-auto">
        <div className="my-5 mt-16 px-6 capitalize text-center">
          <p className="text-3xl font-medium text-tg-theme-text">
            {t("features.eventtype.components.eventTypeFetchError.title")}
          </p>
        </div>

        <div className="px-5 my-5 text-center">
          <p>
            {t("features.eventtype.components.eventTypeFetchError.description")}
          </p>
        </div>

      </div>
    </>
  )
}

export default EventTypeFetchError
