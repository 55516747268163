import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountAvatarForm } from "../../account/forms/accountAvatarForm";
import AccountPersonalInfoForm from "../../account/forms/accountPersonalInfoForm";
import { useAccount } from "hooks/useAccount";

export interface AccountInfo {
  first_name: string
  last_name: string
}

export interface Props {
  data: AccountInfo
  onChange: (d: AccountInfo) => void
}

const ScreenProfile: FC<Props> = ({ data, onChange }) => {

  const { t } = useTranslation()
  const auth = useAccount()
  const [ formData, setFormData ] = useState<AccountInfo>(data)

  const handleChange = (name: any, data: any) => {
    const info = { ...formData, [name]: data }
    setFormData(info)
    onChange(info)
  }

  return (
    <div className="flex flex-col min-h-screen px-5">
      <h1 className="text-3xl font-bold pt-8 text-center">
        {t("features.welcome.components.screenProfile.title")}
      </h1>

      <div className="mb-5 text-center">
        {t("features.welcome.components.screenProfile.desc")}
      </div>

      <div className="flex-1">

        <div className="mb-8">
          <AccountAvatarForm name={auth.account.first_name + " " + auth.account.last_name} src={auth.account.avatar}/>
        </div>

        <div className="mb-5">
          <AccountPersonalInfoForm data={formData} onChange={handleChange}/>
        </div>

        <div className="text-center pt-5 text-tg-theme-subtitle">
          {t("features.welcome.components.screenProfile.info")}
        </div>

      </div>

    </div>
  )
}

export default ScreenProfile
