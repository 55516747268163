import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EventTypeOptionsList from "../components/eventTypeOptionsList";
import { useAccount } from "hooks/useAccount";
import { Screen, useScreen } from "context/screen.provider";
import {
  EventTypeCreateRequest,
  EventTypeHostCreateRequest,
  EventTypeUpdateRequest,
  useEventTypeCreateMutation,
  useEventTypeDeleteMutation,
  useEventTypeFetchQuery,
  useEventTypeUpdateMutation
} from "app/services/eventtypes";
import Duration from "widgets/forms/duration";
import Timezone from "widgets/forms/timezone";
import Card from "widgets/components/card";
import { List, ListItem } from "widgets/components/list/list";
import { formatMinutes, parseDuration } from "app/utils";
import { Location, LocationDefault, locations, LocationType, LocationTypes } from "app/types/location";
import { Schedule, ScheduleRuleDay, ScheduleRuleInterval } from "app/types/schedule";
import { EventType } from "app/types/eventType";
import { remindOptions } from "widgets/forms/remind/reminders";
import LocationForm from "widgets/forms/location";
import Remind from "widgets/forms/remind";
import Note from "widgets/forms/note";
import { LocationResponse } from "app/services/locations";
import { useShowPopup, useWebApp } from "app/telegram";
import { useScheduleListQuery } from "app/services/schedules";
import EventTypeLoading from "../components/eventTypeLoading";
import EventTypeNotFound from "../components/eventTypeNotFound";
import EventTypeFetchError from "../components/eventTypeFetchError";
import AvailabilityForm from "../forms/availabilityForm";
import { useTranslation } from "react-i18next";
import DatePicker from "widgets/components/datepicker";
import { EventListParams, useEventListQuery } from "app/services/events";
import { useGetCalendarEventListQuery } from "app/services/calendar";
import { adjustIntervals } from "widgets/components/datepicker/datepicker";
import { Event } from "widgets/forms/timepicker/timepicker";
import Schedulelist from "widgets/components/schedulelist";
import Collapse from "widgets/components/сollapse";
import { handleError } from "utils";
import moment from "moment";

type FormData = {
  profile_id: string;
  name: string;
  timezone: string;
  duration: string;
  locations: Location[];
  type: string;
  note: string;
  remind?: string;
  schedule_id?: string;
  rules: ScheduleRuleDay[]
};

const defaultEventType: FormData = {
  name: "meeting",
  profile_id: "",
  duration: "30m",
  remind: "30m",
  timezone: "Europe/Moscow",
  locations: [],
  note: "",
  type: "one-off-meeting",
  schedule_id: undefined,
  rules: [],
}

const matchTypes: { [key: string]: string } = {
  "on-off": "one-off-meeting",
  "meeting": "meeting-poll",
  "solo": "one-on-one",
  "group": "group",
  "collective": "collective",
  "rr": "round-robin",
}

function eventTypeToFormData(eventType: EventType): FormData {
  return {
    name: eventType.name,
    profile_id: eventType.profile.id,
    duration: eventType.duration,
    timezone: eventType.timezone,
    locations: eventType.locations || [],
    note: eventType.note,
    type: eventType.type,
    rules: eventType.hosts.filter(h => h.role === "host")[0].rules,
    schedule_id: eventType.hosts.filter(h => h.role === "host")[0].schedule_id,
  } as FormData
}

const EventTypePage: React.FC = () => {
  const { type, eventtype_id } = useParams<{ type?: string; eventtype_id?: string }>();
  const showPopup = useShowPopup()
  const webApp = useWebApp()

  const {
    screen,
    setScreen,
    setBackButtonVisible,
    setBackButtonOnClick,
    setMainButtonProps,
    setMainButtonVisible,
    setMainButtonDisabled
  } = useScreen();
  const navigate = useNavigate();
  const account = useAccount()
  const { t } = useTranslation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ isChanges, setIsChanges ] = useState<boolean>(false)
  const [ eventTypeData, setEventTypeData ] = useState<FormData>(defaultEventType)
  const [ location, setLocation ] = useState<{ index: number, data: Location }>({ index: -1, data: {} as Location })
  const [ day, setDay ] = useState<number>(moment().startOf("day").unix());
  const [ canGoBack, setCanGoBack ] = useState(false);
  const [ eventFilter, setEventFilter ] = useState<EventListParams>({
    from: moment().startOf("isoWeek").unix(),
    to: moment().endOf("isoWeek").unix(),
  })
  const { data: schedules, isLoading, isSuccess } = useScheduleListQuery({ profile_id: account.profile.id })
  const [ createEventType, { isError: isCreateError } ] = useEventTypeCreateMutation()
  const [ updateEventType, { isError: isUpdateError } ] = useEventTypeUpdateMutation()
  const [ deleteEventType, { isError: isDeleteError } ] = useEventTypeDeleteMutation()
  const {
    data: eventType,
    isError: isFetchError,
    isLoading: isFetchLoading
  } = useEventTypeFetchQuery(eventtype_id || "", {
    skip: !eventtype_id,
  });
  const {
    data: events,
    refetch: eventRefetch,
    isLoading: isEventLoading
  } = useEventListQuery(eventFilter)
  const {
    data: calendarEvents,
    isLoading: isCalendarEventLoading,
    refetch: calendarEventRefetch,
  } = useGetCalendarEventListQuery(eventFilter)

  useEffect(() => {
    if (!!eventtype_id && searchParams.has("new")) {
      setMainButtonProps(t("features.eventtype.pages.info.button.home"), goToHome)
    }
  }, []);

  useEffect(() => {
    eventRefetch()
    calendarEventRefetch()
  }, [ eventFilter ])

  useEffect(() => {
    if (type === "on-off" && searchParams.has("type")) {
      const evType = searchParams.get("type")
      setBackButtonVisible(true)
      setBackButtonOnClick(() => goToHome())
      if (evType === "schedule") {
        setMainButtonVisible(false)
      }
      if (evType === "custom") {
        setMainButtonVisible(true)
        setMainButtonProps(t("features.eventtype.pages.info.button.next"), () => {
          const newParams = new URLSearchParams(searchParams.toString());
          newParams.delete("type")
          setSearchParams(newParams)
        })
      }
    }
  }, [ searchParams ]);

  useEffect(() => {
    if (eventtype_id && !isFetchLoading && eventType) {
      setEventTypeData(eventTypeToFormData(eventType));
    }
  }, [ eventType, isFetchLoading ]);

  useEffect(() => {
    if (!type) return

    setEventTypeData({
      ...defaultEventType,
      type: matchTypes[type],
      profile_id: account.profile.id,
      name: `Meeting with ${account.account.first_name}`,
      locations: [ LocationDefault ],
      schedule_id: undefined,
    } as FormData)

  }, [ account, type, schedules ])

  useEffect(() => {
    if (searchParams.has("type")) return
    if (!!screen) return

    setBackButtonVisible(true)
    setBackButtonOnClick(handleBack)

    if (!!type || !!eventtype_id) {

      if (!eventTypeData.schedule_id && !eventTypeData.rules?.length) {
        setMainButtonVisible(true)
        setMainButtonDisabled(false)
        setMainButtonProps(t("features.eventtype.pages.info.button.selectDateTime"),
          () => setScreen("availability"))
        return;
      }

      const reschedule = searchParams.has("reschedule")
      setMainButtonVisible(true)

      if (!!eventtype_id) {
        if (reschedule) {
          setMainButtonProps(t("features.eventtype.pages.info.button.reschedule"), () => onSubmit())
        } else if (isChanges) {
          const newParams = new URLSearchParams(searchParams.toString());
          newParams.delete("new")
          setSearchParams(newParams)
          setMainButtonProps(t("features.eventtype.pages.info.button.update"), () => onSubmit())
        } else {
          if (searchParams.has("new")) {
            setMainButtonProps(t("features.eventtype.pages.info.button.home"), goToHome)
          } else {
            setMainButtonProps(t("features.eventtype.pages.info.button.share"),
              () => {
                const shareLink = `https://t.me/${window._env_.TG_APP_URL}?startapp=book-` + eventType?.hash
                const encodedUrl = encodeURIComponent(shareLink);
                webApp.openTelegramLink(`https://t.me/share/url?url=${encodedUrl}`)
                // switchInlineQuery(`book#${eventType?.hash}`, [ "users" ])
              })
          }
        }
      } else {
        setMainButtonProps(t("features.eventtype.pages.info.button.create"),
          () => onSubmit())
      }
    } else {
      setMainButtonVisible(false)
    }
  }, [ eventTypeData, isChanges, type, eventType, eventtype_id, screen, searchParams ]);

  useEffect(() => {
    if (!eventTypeData.schedule_id && !eventTypeData.rules?.length) return

    setMainButtonDisabled(!eventTypeData.schedule_id && !eventTypeData.rules?.length)
    return () => {
      setMainButtonDisabled(false)
    }
  }, [ eventTypeData ]);

  useEffect(() => {
    // Проверяем, если в истории навигации больше одного элемента
    if (window.history.state && window.history.state.idx > 0) {
      setCanGoBack(true);
    } else {
      setCanGoBack(false);
    }
  }, [ location ]);

  const goToHome = () => {
    setEventTypeData({ ...defaultEventType, profile_id: account.profile.id, rules: [] })
    navigate("/")
  }
  const goToList = () => {
    setEventTypeData({ ...defaultEventType, profile_id: account.profile.id, rules: [] })
    navigate("/eventtypes/create")
  }
  const handleBack = () => {
    if (canGoBack && (searchParams.has("reschedule"))) {
      navigate(-1);
    } else {
      if (!!type) {
        goToList()
      } else {
        goToHome()
      }
    }
  }

  if (!type && !eventtype_id) return (
    <div className="p-5">
      <EventTypeOptionsList/>
    </div>
  )

  if (eventtype_id && isFetchLoading) return <EventTypeLoading/>;
  if (eventtype_id && isFetchError) return <EventTypeFetchError/>;
  if (eventtype_id && !eventType) return <EventTypeNotFound/>;

  const handleDayChange = (v: string) => {
    setDay(moment(v).unix())
  }

  const handleChange = (name: any, data: any) => {
    if (eventtype_id) setIsChanges(true)

    if (name === "location") {
      if (!!location) {
        const locations = [ ...eventTypeData.locations ]
        if (location?.index > -1 && location?.index < eventTypeData.locations.length) {
          locations[location?.index] = data
        } else {
          locations.push(data)
        }

        setEventTypeData({ ...eventTypeData, locations: locations })
        setLocation({ index: -1, data: {} as Location })
      }
      return
    }

    let patch = { ...eventTypeData, [name]: data }

    if (name === "rules") {
      patch.schedule_id = undefined
      patch.timezone = account.account.timezone
    }
    if (name === "schedule_id") {
      patch.rules = []
      patch.timezone = schedules?.find(s => s.id === data as string)?.timezone || account.account.timezone
    }
    if (name === "duration") {
      patch.rules = (eventTypeData.rules || []).map(rule => {
        return { ...rule, intervals: [ ...adjustIntervals(rule.intervals, data) ] }
      })
    }

    setEventTypeData(patch)
  }

  const onSubmit = async () => {
    const eData = { ...eventTypeData }

    let host: EventTypeHostCreateRequest = {
      account_id: account.account.id,
      role: "host",
      schedule_id: eData.schedule_id,
      rules: eData.rules,
      remind: eData.remind,
    }

    let newEventData: EventTypeCreateRequest | EventTypeUpdateRequest = {
      profile_id: eData.profile_id,
      name: eData.name,
      timezone: eData.timezone,
      duration: eData.duration,
      type: eData.type,
      note: eData.note,
      locations: eData.locations as LocationResponse[],
      invitees_counter: {
        limit: 1,
      },
      invitees: [],
      hosts: [ host ]
    }

    if (!!eventtype_id) {
      try {
        (newEventData as EventTypeUpdateRequest).reschedule = searchParams.get("reschedule") || undefined
        const result = await updateEventType({ id: eventtype_id, data: newEventData }).unwrap()
        if (!isUpdateError && result) {
          if (searchParams.has("reschedule")) {
            setMainButtonProps(t("features.eventtype.pages.info.button.home"), goToHome)
            return
          }
          setIsChanges(false)
          return
        }
      } catch (err) {
        handleError(err, "Failed to update event type")
      }
    } else {
      try {
        const result = await createEventType(newEventData).unwrap()
        if (!isCreateError && result) {
          const shareLink = `https://t.me/${window._env_.TG_APP_URL}?startapp=book-` + result?.hash
          const encodedUrl = encodeURIComponent(shareLink);
          webApp.openTelegramLink(`https://t.me/share/url?url=${encodedUrl}`)
          // switchInlineQuery(`book#${result?.hash}`, [ "users" ])
          navigate(`/eventtype/${result.id}?new=true`);
          setMainButtonProps(t("features.eventtype.pages.info.button.home"), goToHome)
          return
        }
      } catch (err) {
        handleError(err, "Failed to create event type")
      }
    }
  };

  const handleAvailabilityChange = (type: string, data: any) => {
    if (type === "custom") {
      setScreen("datepicker")
      return
    }
    if (type === "schedule") {
      handleChange("schedule_id", data)
      return
    }
  }

  const handleLocationChange = (index: number, location: Location) => {
    setLocation({ index: index, data: location })
    setScreen("location")
  }

  const handleAddLocation = () => {
    setLocation({ index: eventTypeData.locations.length, data: {} as Location })
    setScreen("location")
  }

  const handleLocationRemove = (index: number) => {
    const locations = [ ...eventTypeData.locations ]
    locations.splice(index, 1)
    setEventTypeData({ ...eventTypeData, locations: locations })
    setLocation({ index: -1, data: {} as Location })
  }

  const showDeleteEventTypePopup = (id: string) => {
    showPopup({
      title: t("features.eventtype.pages.info.removeConfirmTitle"),
      message: t("features.eventtype.pages.info.removeConfirmDescription"),
      buttons: [
        { id: "close", type: "cancel", text: t("features.eventtype.pages.info.removeConfirmButtonCancel") },
        {
          id: "delete",
          type: "destructive",
          text: t("features.eventtype.pages.info.removeConfirmButtonRemove")
        },
      ]
    }).then(async buttonId => {
      if (buttonId === "delete") {
        try {
          const result = await deleteEventType(id).unwrap()
          if (!isDeleteError && result) {
            navigate("/events/pending")
          }
        } catch (err) {
          handleError(err, "Failed to delete event type")
        }
      }
    });
  }

  const onDateRangeChange = (from: string, to: string) => {
    setEventFilter({
      from: moment(from).unix(),
      to: moment(to).unix()
    })
  }

  if (type === "on-off" && searchParams.has("type")) {
    const evType = searchParams.get("type")

    if (evType === "custom") {
      return (
        <DatePicker day={day}
                    rules={eventTypeData.rules}
                    events={[ ...(events || []), ...(calendarEvents || []) ] as Event[]}
                    duration={eventTypeData.duration}
                    onChange={(v) => handleChange("rules", v)}
                    onChangeDay={handleDayChange}
                    onDateRange={onDateRangeChange}
                    onChangeDuration={(v) => handleChange("duration", v)}/>
      )
    }

    if (evType === "schedule") {
      return (
        <div className="p-5">
          <Schedulelist schedules={schedules} onSelect={(scheduleId) => {
            handleAvailabilityChange("schedule", scheduleId)
            const newParams = new URLSearchParams(searchParams.toString());
            newParams.delete("type")
            setSearchParams(newParams)
          }}/>
        </div>
      )
    }
  }

  const renderSchedule = (schedule: Schedule | undefined) => {

    if (!schedule) return (
      <div className="px-2 text-nowrap text-xs text-tg-theme-destructive">
        {t("features.eventtype.pages.info.schedule-not-found")}
      </div>
    )

    const rules = (schedule.rules as any[])
      .filter((rule) => rule.type !== "date" && !!rule.intervals?.length)

    if (!rules?.length) return (
      <div className="py-2 text-nowrap text-xs text-tg-theme-destructive">
        {t("features.eventtype.pages.info.not-available")}
      </div>
    )

    return (
      <div className="flex flex-col mt-4">
        {rules.map((rule, r_index) => (
          <div key={r_index} className="mb-2">
            <div className="mb-2">{rule.wday || rule.date}</div>
            <div className="grid grid-cols-3 gap-2 text-center w-auto">
              {(rule.intervals as ScheduleRuleInterval[])
                .map((interval, i_index) => (
                  <div key={i_index} className="border border-tg-theme-accent-text rounded">
                    <div className="px-1 text-nowrap text-xs">
                      {interval.from} - {interval.to}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const screens = {
    duration: <Duration value={eventTypeData.duration} onSelect={(v) => handleChange("duration", v)}
                        autoCloseScreen={true}/>,
    timezone: <Timezone value={eventTypeData.timezone} onSelect={(v) => handleChange("timezone", v)}
                        timeFormat={account.account.format_time}/>,
    location: <LocationForm value={location?.data}
                            onSelect={(v) => handleChange("location", v)}
                            onRemove={location.index !== -1 ? () => handleLocationRemove(location.index) : undefined}/>,
    remind: <Remind value={eventTypeData.remind!} onSelect={(v) => handleChange("remind", v)}/>,
    availability: <AvailabilityForm allowCustom={false}
                                    schedules={schedules || []}
                                    onSubmit={handleAvailabilityChange}/>,
    datepicker: <DatePicker day={day}
                            rules={eventTypeData.rules}
                            events={[ ...(events || []), ...(calendarEvents || []) ] as Event[]}
                            duration={eventTypeData.duration}
                            onChange={(v) => handleChange("rules", v)}
                            onChangeDay={handleDayChange}
                            onDateRange={onDateRangeChange}
                            onChangeDuration={(v) => handleChange("duration", v)}/>,
    note: <Note value={eventTypeData.note} onChange={(v) => handleChange("note", v)} title="Additional info"/>,
  };

  return (
    <Screen screens={screens}>
      <div className="flex flex-col gap gap-5">

        <div className="px-6 capitalize text-center mt-10">
          <p className="text-3xl font-medium text-tg-theme-text">
            {type === "on-off" && "One-off meeting"}
            {type === "solo" && "One-to-One meeting"}
            {type === "meeting-poll" && "Meeting poll"}
            {type === "group" && "Group meeting"}
            {type === "collective" && "Collective meeting"}
            {type === "round-robin" && "Round Robin"}
          </p>
        </div>

        <div className="px-5">
          <Card title={t("features.eventtype.pages.info.name.title")}
                className="py-3">
            <input
              value={eventTypeData.name}
              className="w-full text-tg-theme-text bg-tg-theme-section focus:outline-none"
              onChange={e => handleChange("name", e.target.value)}
            />
          </Card>
        </div>

        <div className="px-5">
          <List title={t("features.eventtype.pages.info.details.title")}>
            <ListItem onClick={() => setScreen("duration")}>
              <div className="flex justify-between">
                <div className="flex-1">{t("features.eventtype.pages.info.details.duration")}</div>
                <div className="text-tg-theme-subtitle">
                  {formatMinutes(parseDuration(eventTypeData.duration), account.account.language)}
                </div>
                <div className="w-8 text-right text-tg-theme-subtitle">
                  <i className="fas fa-angle-right"/>
                </div>
              </div>
            </ListItem>
            <ListItem onClick={() => !eventTypeData.schedule_id ? setScreen("timezone") : null}>
              <div className="flex justify-between">
                <div className="flex-1">{t("features.eventtype.pages.info.details.timezone")}</div>
                <div className="text-tg-theme-subtitle">
                  {eventTypeData.timezone}
                </div>
                {!eventTypeData.schedule_id && (
                  <div className="w-8 text-right text-tg-theme-subtitle">
                    <i className="fas fa-angle-right"/>
                  </div>
                )}
              </div>
            </ListItem>
          </List>
        </div>

        {(!!eventTypeData.schedule_id || !!eventTypeData.rules?.length) && (
          <div className="px-5">
            <List title={t("features.eventtype.pages.info.availability_details")}>
              <ListItem onClick={() => !!eventTypeData.schedule_id
                ? setScreen("availability")
                : setScreen("datepicker")}>
                <div>
                  <div className="flex justify-between">
                    <div className="flex-1">
                      {
                        !!eventTypeData.rules.length
                          ? t("features.eventtype.pages.info.custom")
                          : !!eventTypeData.schedule_id
                            ? schedules?.filter(s => s.id === eventTypeData.schedule_id)[0].name
                            : t("features.eventtype.pages.info.details.availability")
                      }
                    </div>
                    <div className="text-tg-theme-subtitle">
                      {(!eventTypeData.rules.length && !eventTypeData.schedule_id) && "No availability selected"}
                    </div>
                    <div className="w-8 text-right text-tg-theme-subtitle">
                      <i className="fas fa-angle-right"/>
                    </div>
                  </div>
                  {!!eventTypeData.schedule_id && renderSchedule(schedules?.filter(s => s.id === eventTypeData.schedule_id)[0])}
                  {!!eventTypeData.rules.length && (
                    <div className="flex flex-col mt-4">
                      {(eventTypeData.rules as ScheduleRuleDay[]).map((rule, r_index) => (
                        <div key={r_index} className="mb-2">
                          <div className="mb-2">{rule.date}</div>
                          <div className="grid grid-cols-3 gap-2 text-center w-auto">
                            {(rule.intervals as ScheduleRuleInterval[]).map((interval, i_index) => (
                              <div key={i_index} className="border border-tg-theme-accent-text rounded">
                                <div className="px-1 text-nowrap text-xs">
                                  {interval.from} - {interval.to}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </ListItem>
            </List>
          </div>
        )}

        <div className="px-5">
          <List title={t("features.eventtype.pages.info.location.title")}>
            {(!eventTypeData.locations || !eventTypeData.locations.length) && (
              <ListItem onClick={() => setScreen("location")}>
                <div className="flex justify-between">
                  <div className="flex-1">{t("features.eventtype.pages.info.location.location")}</div>
                  <div className="text-tg-theme-subtitle">
                    {t("features.eventtype.pages.info.location.no-location")}
                  </div>
                  <div className="w-8 text-right text-tg-theme-subtitle">
                    <i className="fas fa-angle-right"/>
                  </div>
                </div>
              </ListItem>
            )}
            {!!eventTypeData.locations && eventTypeData.locations.map((location, index) => {
              let locationName = ""
              switch (true) {
                case location?.kind === LocationTypes.Telegram && location.outbound:
                  locationName = t("common.locations." + location?.kind + ".outbound.title")
                  break
                case location?.kind === LocationTypes.Telegram && !location.outbound:
                  locationName = t("common.locations." + location?.kind + ".incoming.title")
                  break
                case location?.kind === LocationTypes.Phone && location.outbound:
                  locationName = t("common.locations." + location?.kind + ".outbound.title")
                  break
                case location?.kind === LocationTypes.Phone && !location.outbound:
                  locationName = t("common.locations." + location?.kind + ".incoming.title")
                  break
                default:
                  locationName = t("common.locations." + location?.kind + ".title")
              }
              return (
                <ListItem key={index} onClick={() => handleLocationChange(index, location)}>
                  <div className="flex justify-between">
                    <div className="flex-1">
                      <span className="w-6">
                        <i className={locations[(location?.kind as LocationType)]?.ico + " mx-2 fa-xs w-4 m-auto"}/>
                      </span>
                      {locationName}
                    </div>
                    <div className="w-8 text-right text-tg-theme-subtitle">
                      <i className="fas fa-angle-right"/>
                    </div>
                  </div>
                </ListItem>
              )
            })}
          </List>
        </div>

        <div className="px-5">
          {!!eventTypeData.locations && !!eventTypeData.locations.length && (
            <div className="flex -mt-4 ml-2 text-sm text-tg-theme-accent-text" onClick={handleAddLocation}>
              + {t("features.eventtype.pages.info.location.add-location")}
            </div>
          )}
        </div>

        <div className="border border-t border-tg-theme-background-section mt-2 mx-5"/>

        <div className="mb-5">
          <Collapse title={t("features.eventtype.pages.info.advanced")}>
            <>
              <div className="px-5">
                <Card onClick={() => setScreen("remind")} title={t("features.eventtype.pages.info.remind.title")}>
                  <div className="flex justify-between">
                    <div>
                      {t("features.eventtype.pages.info.remind.name")}
                    </div>
                    <div className="inline-flex items-center justify-end">
                      <div className="text-tg-theme-subtitle">
                        {remindOptions.find(i => eventTypeData.remind === i.value)?.label || t("features.eventtype.pages.info.remind.no-remind")}
                      </div>
                      <div className="w-8 text-right text-tg-theme-subtitle">
                        <i className="fa fa-arrows-up-down"/>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="my-2 px-5">
                <Card
                  title={t("features.eventtype.pages.info.note.title")}
                  onClick={() => setScreen("note")}>
                  <div className="flex justify-between items-center">
                    <div className="flex-1">
                      {!!eventTypeData.note ?
                        <p> {eventTypeData.note}</p> :
                        <p>{t("features.eventtype.pages.info.note.placeholder")}</p>
                      }
                    </div>
                    <div className="w-8 text-right text-tg-theme-subtitle">
                      <i className="fas fa-angle-right"/>
                    </div>
                  </div>
                </Card>
              </div>

              {!!eventType?.id && (
                <div className="px-5 my-7">
                  <Card
                    hint={t("features.eventtype.pages.info.removeHint")}
                    onClick={() => showDeleteEventTypePopup(eventType.id)}>
                    <div className="text-tg-theme-destructive">
                      {t("features.eventtype.pages.info.button.remove")}
                    </div>
                  </Card>
                </div>
              )}
            </>
          </Collapse>
        </div>

      </div>
    </Screen>
  );
};

export default EventTypePage;
