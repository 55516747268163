import { api } from "./api"
import { EmptyRequest, EmptySuccessResponse } from "./empty"
import { getLocationsFromResponse, LocationResponse } from "./locations"
import { EventType } from "../types/eventType"
import { getScheduleRulesFromResponse, ScheduleRuleRequest, ScheduleRuleResponse } from "./schedules"

export function getEventTypeFromResponse(src: EventTypeResponse): EventType {
  return {
    ...src,
    hosts: src.hosts.map(host => ({
      ...host,
      rules: host.rules ? getScheduleRulesFromResponse(host.rules) : []
    })),
    locations: src.locations ? getLocationsFromResponse(src.locations) : [],
    invitees: src.invitees?.map(invitee => ({
      ...invitee
    })),
  }
}

export type EventTypeHostCreateRequest =
  {
    account_id: string,
    role: string,
    remind?: string
    schedule_id?: string,
    rules?: ScheduleRuleRequest[],
  }

export type EventTypeCreateRequest = {
  profile_id?: string,

  name: string,
  hosts?: EventTypeHostCreateRequest[],
  timezone: string,
  duration: string,
  type: string,

  note?: string,

  locations: LocationResponse[],

  invitees_counter: {
    limit: number,
  }

  invitees?: string[],
}

export type EventTypeUpdateRequest = EventTypeCreateRequest & { reschedule?: string }

export type EventTypeResponse = {
  id: string,

  profile: {
    id: string
    name: string
    avatar: string
    slug: string
  },

  hosts: [
    {
      account_id: string,
      name: string,
      avatar: string,
      role: string,
      schedule_id?: string,
      rules?: ScheduleRuleResponse[],
      remind?: string
    }
  ],

  hash: string,
  name: string,
  type: string,
  secret: boolean,
  timezone: string,

  start_date?: string,
  end_date?: string,

  note?: string,
  duration: string,

  locations?: LocationResponse[],

  invitees_counter: {
    total: number,
    limit: number,
  }

  invitees?: {
    account_id: string,
    name: string,
    username: string
  }[],

  status: string,
  created: number,
  updated: number,
}

export type EventTypeListResponse = {
  total: number,

  cursor?: string,
  order?: string,

  items: EventTypeResponse[]
}

export const eventTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    eventTypeList: build.query<EventType[], EmptyRequest>({
      query: () => ({
        url: "v1/eventtypes",
        method: "GET",
      }),
      transformResponse: (response: EventTypeListResponse): EventType[] => {
        return response.items.map(getEventTypeFromResponse);
      },
      providesTags: (result: EventType[] = []) => [
        ...result.map(({ id }) => ({ type: "EventTypes", id }) as const),
        { type: "EventTypes" as const, id: "LIST" },
      ],
    }),
    eventTypeCreate: build.mutation<EventType, EventTypeCreateRequest>({
      query: (data: EventTypeCreateRequest) => ({
        url: "v1/eventtype",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: EventTypeResponse): EventType => {
        return getEventTypeFromResponse(response);
      },
      invalidatesTags: [ { type: "EventTypes", id: "LIST" } ],
    }),
    eventTypeFetch: build.query<EventType, string>({
      query: (id: string) => ({
        url: `v1/eventtype/${id}`,
        method: "GET",
      }),
      transformResponse: (response: EventTypeResponse): EventType => {
        return getEventTypeFromResponse(response);
      },
      providesTags: (result, error, id) => [ { type: "EventTypes", id } ],
    }),
    eventTypeUpdate: build.mutation<EventType, { id: string, data: EventTypeUpdateRequest }>({
      query: ({ id, data }) => {
        return {
          url: `v1/eventtype/${id}?`,
          method: "PUT",
          body: data,
        }
      },
      transformResponse: (response: EventTypeResponse): EventType => {
        return getEventTypeFromResponse(response);
      },
      invalidatesTags: (result, error, { id }) => [ { type: "EventTypes", id } ],
    }),
    eventTypeDelete: build.mutation<EmptySuccessResponse, string>({
      query: (id) => ({
        url: `v1/eventtype/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [ { type: "EventTypes", id }, { type: "PendingEvents", id } ],
    }),
    eventTypeFetchByHash: build.query<EventType, string>({
      query: (id: string) => ({
        url: `v1/eventtype/${id}/link`,
        method: "GET",
      }),
      transformResponse: (response: EventTypeResponse): EventType => {
        return getEventTypeFromResponse(response);
      },
      providesTags: (result, error, id) => [ { type: "EventTypes", id } ],
    }),
  }),
})

export const {
  useEventTypeCreateMutation,
  useEventTypeListQuery,
  useEventTypeFetchQuery,
  useLazyEventTypeFetchQuery,
  useEventTypeUpdateMutation,
  useEventTypeDeleteMutation,
  useEventTypeFetchByHashQuery
} = eventTypeApi

export const {
  endpoints: { eventTypeList, eventTypeCreate, eventTypeFetch, eventTypeFetchByHash, eventTypeUpdate, eventTypeDelete },
} = eventTypeApi

