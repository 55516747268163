import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "layouts/protected";
import Loader from "./layouts/preloader";
import { useThemeParams } from "app/telegram";
import OAuth2Page from "./pages/oauth";

function App() {

  let [ ColorScheme ] = useThemeParams()

  return (
    <div data-testid="app" className={`h-screen overflow-y-scroll ${ColorScheme === "dark" ? "dark" : "light"}`}>
      <Routes>
        <Route path="/loading" element={<Loader/>}/>
        <Route path="/:provider/oauth" element={<OAuth2Page/>}/>
        <Route path="*" element={<Main/>}/>
      </Routes>
    </div>
  );
}

export default App;
