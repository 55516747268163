import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useIntegrationExchangeTokenMutation } from "app/services/integration";
import Preloader from "widgets/components/preloader";
import { useTranslation } from "react-i18next";

const OAuth2Page: React.FC = () => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const { provider } = useParams<{ provider: string }>();
  const code = urlParams.get("code") || "";
  const state = urlParams.get("state") || "";

  const [ exchangeToken, { isLoading, isError } ] = useIntegrationExchangeTokenMutation();

  useEffect(() => {
    if (!!provider && !!code && !!state) {
      exchangeToken({ provider, code, state })
        .unwrap()
        .then((data) => {
          console.log("Token exchange successful:", data);
        })
        .catch((error) => {
          console.error("Failed to exchange token:", error);
        });
    }
  }, [ provider, code ]);

  if (isLoading) return <Preloader/>
  if (isError) return <div>{t("page.oauth.errorMessage")}</div>;

  const returnToApp = () => {
    window.location.href = "tg://";
    window.close();
  };

  return (
    <div className="flex h-[calc(100dvh)] justify-center items-center max-w-72 m-auto">
      <div className="text-center">
        <div className="self-end mb-10">
          <img
            className="w-64 m-auto py-10 object-cover object-center"
            src="/assets/preloader.png"
            alt="loading"
          />
          <button
            className="rounded-2xl mt-5 m-auto p-3 bg-green-400"
            onClick={returnToApp}
          >
            {t("page.oauth.backButton")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OAuth2Page;
