import { FormatDate, FormatSpot, FormatTime } from "app/types/common";
import { useAccount } from "hooks/useAccount";
import moment from "moment";

type TimeVariant = "Time" | "Datetime" | "WDatetime" | "Weekday" | "Date";

interface TimeProps {
  variant?: TimeVariant;
  className?: string;
  time: string;
  format_date?: FormatDate;
  format_time?: FormatTime;
  timezone?: string; // User-provided timezone
  year?: boolean;
}

const Time = ({ variant, className, time, format_time, format_date, timezone, year }: TimeProps) => {

  const account = useAccount();
  const t = moment(time, FormatSpot);
  const effectiveTimezone = timezone || account.account.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Determine the format string based on the variant
  let f: string;

  switch (variant) {
    case "WDatetime":
      f = format_date || (account.account.format_date === "DD/MM/YYYY" ? "DD MMM" : "MMM DD");
      f = t.year() > moment().year() ? f + "' YY" : year ? f + " YY" : f;
      f += ", dddd";
      break;
    case "Datetime":
      f = format_date || (account.account.format_date === "DD/MM/YYYY" ? "DD MMM YYYY" : "MMM DD YYYY");
      break;
    case "Date":
      f = format_date || (account.account.format_date === "DD/MM/YYYY" ? "DD MM YYYY" : "MM DD YYYY");
      break;
    case "Time":
      f = format_time || (account.account.format_time === "12h" ? "hh:mm a" : "HH:mm");
      break;
    case "Weekday":
      f = "dddd";
      break;
    default:
      f = "DD/MM/YYYY";
  }

  // If timezone is provided, adjust the time using Intl.DateTimeFormat
  if (effectiveTimezone) {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: effectiveTimezone,
      hour12: format_time === "12h" || account.account.format_time === "12h"
    };

    if (variant === "Time" || variant === "Datetime") {
      options.hour = '2-digit';
      options.minute = '2-digit';
    }

    if (variant === "Datetime" || variant === "Date" || variant === "WDatetime") {
      options.year = 'numeric';
      options.month = 'short';
      options.day = '2-digit';
    }

    if (variant === "WDatetime" || variant === "Weekday") {
      options.weekday = 'long';
    }

    const dateInTimeZone = new Intl.DateTimeFormat(account.account.language || "en", options).format(t.toDate());
    return <span className={className}>{dateInTimeZone}</span>;
  }

  // If no timezone is provided, format using moment's format
  return (
    <span className={className}>
      {t.locale(account.account.language || "en").format(f)}
    </span>
  );
}

export default Time;
