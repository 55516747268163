import { retry } from "@reduxjs/toolkit/query/react"
import { api } from "./api"
import { getLocationsFromResponse, LocationResponse } from "./locations"
import { Booking, BookingAvailability } from "app/types/booking";

export function getBookingFromResponse(src: BookingResponse): Booking {
  return {
    ...src,
    locations: src.locations ? getLocationsFromResponse(src.locations) : [],
  }
}

export function getBookingAvailabilityFromResponse(src: BookingAvailabilityResponse): BookingAvailability {
  return {
    ...src,
    days: src.days.map(day => ({
      ...day,
      spots: day.spots.map(spot => ({
        ...spot
      })),
    }))
  }
}

export type BookingResponse = {
  id: string,

  hosts: [
    {
      account_id: string,
      name: string,
      avatar: string,
    }
  ],

  profile: {
    name: string,
    avatar: string,
  }

  hash: string,
  name: string,
  type: string,
  timezone: string,

  start_date?: string,
  end_date?: string,

  note?: string,
  duration: string,

  locations?: LocationResponse[],

  invitees_counter: {
    total: number,
    limit: number,
  }

  status: string,
  created: number,
  updated: number,
}

export type BookingAvailabilityResponse = {
  today: string
  timezone: string,
  days: {
    date: string,
    status: string,
    spots: {
      status: string,
      start_time: string,
      invitees_remaining: number
    }[],
  }[],
}

export type BookingAvailabilityQueryParams = {
  hash: string, range_start?: string, range_end?: string, timezone?: string, duration?: string
}

export const eventTypeApi = api.injectEndpoints({
  endpoints: (build) => ({

    bookingFetchByHash: build.query<Booking, string>({
      query: (hash: string) => ({
        url: `v1/booking/${hash}`,
        method: "GET",
      }),
      transformResponse: (response: BookingResponse, meta): Booking => {
        return getBookingFromResponse(response);
      },

    }),

    bookingAvailabilityFetchByHash: build.query<BookingAvailability, BookingAvailabilityQueryParams>({
      query: ({ hash, range_start, range_end, timezone, duration }) => {
        return {
          url: `v1/booking/${hash}/availability`,
          method: "GET",
          params: { range_start, range_end, timezone, duration }
        }
      },
      transformResponse: (response: BookingAvailability): BookingAvailability => {
        return getBookingAvailabilityFromResponse(response);
      },
    }),

  }),

})

export const {
  useBookingFetchByHashQuery,
  useBookingAvailabilityFetchByHashQuery,
} = eventTypeApi

export const {
  endpoints: { bookingFetchByHash, bookingAvailabilityFetchByHash },
} = eventTypeApi

