import React, { FC, useEffect, useMemo, useState } from "react";
import { ScheduleRuleDay, ScheduleRuleInterval } from "app/types/schedule";
import TimePicker from "../../forms/timepicker/timepicker";
import CalendarMini, { Event } from "../../forms/calendarmini/calendar";
import { DateOption } from "../../forms/calendar";
import moment from "moment";
import { useScreen } from "context/screen.provider";
import { useTranslation } from "react-i18next";
import Card from "../card";
import Duration from "../../forms/duration";
import { parseDuration } from "../../forms/timepicker/timespot";

interface DatepickerProps {
  day: number;
  duration?: string;
  hideDayChanger?: boolean;
  rules: ScheduleRuleDay[];
  events?: Event[];
  dates?: DateOption[],
  onChangeDuration?: (v: string) => void;
  onChangeDay?: (day: string) => void;
  onChange: (rules: ScheduleRuleDay[]) => void;
  onDateRange?: (from: string, to: string) => void;
}

const newScheduleRule = (date: string): ScheduleRuleDay => ({
  type: "date",
  date: date,
  intervals: []
})

const DatePicker: FC<DatepickerProps> = ({
  day,
  rules,
  duration,
  events,
  dates,
  onChangeDuration,
  onChange,
  onChangeDay,
  onDateRange,
  hideDayChanger,
}) => {

  const { t } = useTranslation();
  const [ datePicked, setDatePicked ] = useState<number>(day)
  const date = moment.unix(day).format("YYYY-MM-DD")
  const ruleIndex = rules.findIndex((item: { date: string; }) => item.date === date)
  const intervals = (ruleIndex === -1) ? [] : rules[ruleIndex].intervals
  const [ intervalItems, setIntervalItems ] = useState<ScheduleRuleInterval[]>(intervals || [])
  const [ durationValue, setDurationValue ] = useState<string>(duration || "30m")
  const [ showDurationForm, setShowDurationForm ] = useState<boolean>(false)
  const {
    setScreen,
    setBackButtonVisible,
    setBackButtonOnClick,
    setMainButtonProps,
    setMainButtonVisible
  } = useScreen();

  useEffect(() => {
    setMainButtonVisible(true)
    setMainButtonProps(t("widgets.components.datepicker.close"), () => setScreen(undefined))
    setBackButtonVisible(true)
    setBackButtonOnClick(() => {
      setScreen(undefined)
    })
  }, []);

  useEffect(() => {
    setDatePicked(day)
  }, [ day ])

  useEffect(() => {
    const date = moment.unix(datePicked).format("YYYY-MM-DD")
    const ruleIndex = rules.findIndex((item: { date: string; }) => item.date === date)
    setIntervalItems((ruleIndex === -1) ? [] : rules[ruleIndex].intervals)
  }, [ rules, datePicked ]);

  const handleChangeDate = (date: string) => {
    onChangeDay && onChangeDay(date)
    setDatePicked(moment(date).unix())
    const newRules = [ ...rules ]
    let index = newRules.findIndex((d: ScheduleRuleDay) => d.date === date)
    let rule = (index === -1) ? newScheduleRule(date) : newRules[index]
    setIntervalItems([ ...rule.intervals ])
  }

  const recalculateIntervals = (d: string) => {
    const newRules = [ ...rules ];
    const ruleIndex = newRules.findIndex((item: {
      date: string
    }) => item.date === moment.unix(datePicked).format("YYYY-MM-DD"));

    const rule = { ...newRules[ruleIndex], intervals: [ ...intervals ] };
    rule.intervals = adjustIntervals([ ...intervalItems ], d)
    newRules[ruleIndex] = rule;

    onChange && onChange(newRules);
    setIntervalItems(intervals);
  }

  const handleChange = (intervals: ScheduleRuleInterval[]) => {
    const newRules = [ ...rules ];
    const ruleIndex = newRules.findIndex((item: {
      date: string
    }) => item.date === moment.unix(datePicked).format("YYYY-MM-DD"));

    // Create a new rule object to avoid mutating the original one
    let rule;
    if (ruleIndex === -1) {
      rule = newScheduleRule(moment.unix(datePicked).format("YYYY-MM-DD"));
    } else {
      rule = { ...newRules[ruleIndex], intervals: [ ...newRules[ruleIndex].intervals ] };
    }

    rule.intervals = [ ...intervals ];

    if (ruleIndex === -1) {
      newRules.push(rule);
    } else {
      newRules[ruleIndex] = rule;
    }

    onChange && onChange(newRules);
    setIntervalItems(intervals);
  };

  const handleChangeDuration = (v: string) => {
    setShowDurationForm(false)
    setDurationValue(v)
    recalculateIntervals(v)
    onChangeDuration && onChangeDuration(v)
  }

  const groupedEvents = useMemo(() => {
    return (events || []).reduce((items, event) => {
      const formattedDate = moment(event.start_time).format("ll");
      if (!items[formattedDate]) {
        items[formattedDate] = [];
      }
      items[formattedDate].push(event);
      return items;
    }, {} as { [id: string]: Event[] });
  }, [ events ]);

  return (
    <div className="px-5">
      {showDurationForm && (
        <div className="absolute z-50 -mx-5 top-20 -mt-5 w-screen">
          <Duration value={durationValue} onSelect={handleChangeDuration} autoCloseScreen={false}/>
        </div>
      )}

      <div className="flex flex-col h-screen max-h-screen">
        <Card className="rounded-xl flex mt-5" onClick={() => setShowDurationForm(!showDurationForm)}>
          <div className="flex-1">
            {t("widgets.components.datepicker.duration")}
          </div>
          <div>
            {durationValue}
          </div>
          <div className="w-8 text-right text-tg-theme-subtitle">
            {showDurationForm
              ? <i className="fa-solid fa-chevron-down"/>
              : <i className="fa-solid fa-chevron-right"/>
            }
          </div>
        </Card>

        {!hideDayChanger && (
          <div className="flex items-center py-5">
            <CalendarMini current={day}
              dates={dates}
              events={events}
              allowEmpty={true}
              allowPrevDay={false}
              onDateRange={onDateRange}
              onChange={handleChangeDate}/>
          </div>
        )}

        <div className="flex-1 flex flex-col overflow-hidden">
          <TimePicker
            key={datePicked}
            events={groupedEvents[moment.unix(datePicked).format("ll")]}
            onChange={handleChange}
            duration={durationValue}
            intervals={intervalItems}
          />
        </div>
      </div>
    </div>
  );
}

export function adjustIntervals(intervals: ScheduleRuleInterval[], duration: string): ScheduleRuleInterval[] {
  const parsedDuration = parseDuration(duration);

  // Sort intervals by 'from' time
  intervals.sort((a, b) => moment(a.from, "HH:mm").diff(moment(b.from, "HH:mm")));

  let adjustedIntervals: ScheduleRuleInterval[] = [];

  for (let i = 0; i < intervals.length; i++) {
    const from = moment(intervals[i].from, "HH:mm");
    let to = from.clone().add(parsedDuration, "minutes");

    // Skip intervals that exceed 24:00
    if (to.isAfter(moment("24:00", "HH:mm"))) {
      continue;
    }

    // Adjust for overlaps
    if (adjustedIntervals.length > 0) {
      const lastInterval = adjustedIntervals[adjustedIntervals.length - 1];
      const lastTo = moment(lastInterval.to, "HH:mm");

      if (from.isBefore(lastTo)) {
        const newFrom = lastTo.clone();
        to = newFrom.clone().add(parsedDuration, "minutes");

        if (to.isAfter(moment("24:00", "HH:mm"))) {
          continue;
        }

        adjustedIntervals.push({
          from: newFrom.format("HH:mm"),
          to: to.format("HH:mm")
        });

        continue;
      }
    }

    adjustedIntervals.push({
      from: from.format("HH:mm"),
      to: to.format("HH:mm")
    });
  }

  return adjustedIntervals;
}

export default DatePicker
