import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EventTypeList } from "features/eventtype/components/eventTypeList";
import Avatar from "widgets/components/avatar";
import FormSearch from "widgets/forms/search/search";
import { useAccount } from "hooks/useAccount";

const EventTypeListPage = () => {

  const { t } = useTranslation()
  const account = useAccount().account

  return (
    <>
      <div className="my-5">
        <p className="text-gray-800">
          {t("page.eventtype.list.title")}
        </p>
        <p className="text-gray-600 font-bold">
          {t("page.eventtype.list.no-events")}
        </p>
      </div>

      <div className="my-5">
        <FormSearch/>
      </div>

      <div className="flex flex-col-3 align-middle">
        <div className="w-16">
          <Avatar
            rounded
            name={account.first_name + " " + account.last_name}
            src={account.avatar}
            size={2.5}/>
        </div>
        <div className="grow align-middle">
          <p className="text-md font-bold">{account.first_name + " " + account.last_name}</p>
        </div>
        <div className="w-16 p-3 align-middle">
          <i className="fa-solid fa-gear"/>
        </div>
      </div>

      <hr className="h-0.5 my-5 bg-gray-300 border-0 dark:bg-gray-700"/>

      <div className="">
        <NavLink to="/eventtypes/create"
          className="w-full button inline-block rounded-full border-2 border-primary px-6 pb-[6px] pt-2 text-xs
          text-center font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out
          hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600
          focus:bg-primary-50/50 focus:text-blue-500 focus:outline-none focus:ring-0 active:border-primary-700
          active:text-primary-700 motion-reduce:transition-none dark:text-blue-400 dark:hover:bg-blue-950
          dark:focus:bg-blue-950">
          <span>
            New event type
          </span>
        </NavLink>
      </div>

      <div className="my-5">
        <EventTypeList variant={"horizontal"}/>
      </div>

    </>
  );
};

export default EventTypeListPage;
