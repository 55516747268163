import { ChangeEvent, FC, useEffect, useState } from "react";
import Card from "widgets/components/card";
import { useTranslation } from "react-i18next";
import { useScreen } from "context/screen.provider";

export interface Props {
  value?: string
  onChange?: (v: string) => void
  onSubmit?: (v: string) => void
}

const LocationPhoneSetForm: FC<Props> = ({ value, onSubmit }) => {

  const { t } = useTranslation();
  const { setMainButtonVisible, setMainButtonProps, isVisibleMainButton } = useScreen();
  const [ dataValue, setDataValue ] = useState(value || "")

  useEffect(() => {
    const lastState = isVisibleMainButton
    setMainButtonVisible(true)
    setMainButtonProps(t("features.booking.forms.locationPhoneSetForm.mainButton"), handleSubmit)
    return () => {
      setMainButtonVisible(true)
      setMainButtonVisible(lastState)
      setMainButtonProps(t("features.booking.forms.locationPhoneSetForm.mainButton"), handleSubmit)
    }
  }, []);

  useEffect(() => {
    setMainButtonProps(t("features.booking.forms.locationPhoneSetForm.mainButton"), handleSubmit)
  }, [ dataValue ]);

  useEffect(() => {
    setDataValue(value || "")
  }, [ value ])

  const handleSubmit = () => {
    onSubmit && onSubmit(dataValue)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDataValue(e.target.value)
  }

  return (
    <div className="px-5">
      <Card title={t("features.booking.forms.locationPhoneSetForm.title")} className="mb-2 p-0">
        <input
          type="tel"
          name="phone"
          placeholder={t("features.booking.forms.locationPhoneSetForm.placeholder")}
          className="w-full text-tg-theme-text bg-tg-theme-section focus:text-tg-theme-text focus:bg-tg-theme-section focus:outline-none"
          value={dataValue}
          onChange={handleChange}
        />
      </Card>
    </div>
  )
}

export default LocationPhoneSetForm
