import { api } from "./api"

export type PaymentRequest = {}

export type PaymentResponse = {
  link: string
}

export const paymentApi = api.injectEndpoints({
  endpoints: (build) => ({

    paymentCreate: build.mutation<string, PaymentRequest>({
      query: (payment: PaymentRequest) => ({
        url: "v1/payment/telegram",
        method: "POST",
        body: payment,
      }),
      transformResponse: (response: PaymentResponse): string => {
        return response.link;
      },
    }),

  }),
})

export const {
  usePaymentCreateMutation,
} = paymentApi

