import React, { useEffect, useState, useRef } from "react";

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  name?: string;
  options: Option[];
  onChange: (value: string) => void;
  value?: string;
  label?: string;
  className?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  name,
  options,
  onChange,
  value,
  label = "Dropdown an option",
  className
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ selected, setSelected ] = useState<string | undefined>(value);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelected(value);
  }, [ value ]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelected(value)
  }, [ value ]);

  const handleOptionClick = (option: Option) => {
    setSelected(option.value);
    onChange(option.value);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className={`relative inline-block min-w-24 ${className}`}>
      <div
        className="flex justify-between items-center bg-tg-theme-section border border-tg-theme-accent-text rounded-md cursor-pointer px-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="p-1">{selected || label}</span>
        <svg
          className={`w-4 h-4 transform transition-transform ${isOpen ? "rotate-180" : "rotate-0"}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
        </svg>
      </div>

      {isOpen && (
        <div
          className="w-full absolute z-10 mt-1 bg-tg-theme-secondary rounded-md border border-spacing-2 border-tg-theme-background-main">
          <div className="h-[12rem] overflow-y-auto divide-y-2 divide-neutral-200 dark:divide-neutral-800">
            {options.map((option, index) => {
              return (
                <div
                  key={!!name ? name + "-" + option.value + "-" + index : option.value + "-" + index}
                  className="p-2 cursor-pointer"
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
