import React, { useEffect } from "react";
import { useHapticFeedback } from "app/telegram";
import { List, ListItem } from "widgets/components/list/list";
import { useScreen } from "context/screen.provider";
import { formatMinutes, parseDuration } from "app/utils";
import { useAccount } from "../../../hooks/useAccount";

const durationOptions =
  [
    { value: "15m", label: "15 min" },
    { value: "30m", label: "30 min" },
    { value: "45m", label: "45 min" },
    { value: "60m", label: "60 min" },
    { value: "90m", label: "1 hour 30 min" },
    { value: "120m", label: "2 hour" },
  ]

export type OptionType = {
  value: string;
  label: string;
};

interface DurationProps {
  maxDuration?: string;
  value: string
  autoCloseScreen?: boolean
  onSelect?: (v: string) => void
}

const Duration = ({ maxDuration, value, onSelect, autoCloseScreen = true }: DurationProps) => {

  const [ , , selectionChanged ] = useHapticFeedback();
  const { account } = useAccount()
  const { setScreen, setMainButtonVisible, isVisibleMainButton } = useScreen();

  useEffect(() => {
    const preVisibleState = isVisibleMainButton;
    setMainButtonVisible(false)
    return () => {
      setMainButtonVisible(preVisibleState)
    }
  }, []);

  const handleChange = (v: string) => {
    selectionChanged();
    onSelect && onSelect(v)
    autoCloseScreen && setScreen(undefined)
  }

  return (
    <div className="p-5">
      <List>
        {durationOptions.map((item, index) => {

          let available: boolean = (!!maxDuration && parseDuration(item.value) <= parseDuration(maxDuration)) || !maxDuration;

          return (
            <ListItem key={index} onClick={() => handleChange(item.value)} disabled={!available}>
              <div className="flex items-center justify-between">
                <span>
                  {formatMinutes(parseDuration(item.value), account.language)}
                </span>
                <div className="w-8 text-right text-tg-theme-subtitle">
                  <i className="fas fa-angle-right"/>
                </div>
              </div>
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}

export default Duration;
