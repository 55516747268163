import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocationPhoneCall } from "app/types/location";
import Card from "widgets/components/card";
import { List, ListItem } from "../../../components/list/list";

interface LocationPhoneCallProps {
  value: LocationPhoneCall;
  onChange: (data: LocationPhoneCall) => void;
  onRemove?: () => void
  connected: boolean
}

const LocationPhoneCallForm: FC<LocationPhoneCallProps> = ({
  value,
  onChange,
  onRemove,
  connected
}) => {

  const patchData = (v: LocationPhoneCall) => {
    const data = { ...v }
    if (!data.outbound) data.outbound = true
    if (!data.phone) data.phone = ""
    if (!data.note) data.note = ""
    return data
  }

  const { t } = useTranslation();
  const [ isOutbound, setIsOutbound ] = useState<boolean>(!!value?.outbound);
  const [ data, setData ] = useState<LocationPhoneCall>(patchData(value));

  useEffect(() => {
    onChange(patchData(value))
  }, [])

  useEffect(() => {
    setData(patchData(value))
  }, [ value ])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = { ...data, [e.target.name]: e.target.value, is_outbound: isOutbound }
    setData(value);
    onChange(value);
  };

  const handleOutboundChange = (v: boolean) => {
    setIsOutbound(v);
    const updatedData = { ...data, outbound: v };
    setData(updatedData);
    onChange(updatedData);
  }

  return (
    <div className="px-5">

      <div className="font-bold pt-5 pb-8 text-center">
        {t("widgets.forms.location.form.locationPhoneCallForm.title")}
      </div>

      <List className="flex flex-col mb-2" title={t("widgets.forms.location.form.locationPhoneCallForm.host.label")}>
        <ListItem>
          <div className="flex items-center" onClick={() => handleOutboundChange(true)}>
            <input
              name="type"
              type="radio"
              value="false"
              checked={isOutbound}
              onChange={() => handleOutboundChange(true)}
            />
            <div className="ml-2">
              <p>
                {t("widgets.forms.location.form.locationPhoneCallForm.host.title")}
              </p>
              <small>
                {t("widgets.forms.location.form.locationPhoneCallForm.host.description")}
              </small>
            </div>
          </div>
        </ListItem>

        <ListItem>
          <div className="flex items-center" onClick={() => handleOutboundChange(false)}>
            <input
              name="type"
              type="radio"
              value="true"
              checked={!isOutbound}
              onChange={() => handleOutboundChange(false)}
            />
            <div className="ml-2">
              <p>
                {t("widgets.forms.location.form.locationPhoneCallForm.member.title")}
              </p>
              <small>
                {t("widgets.forms.location.form.locationPhoneCallForm.member.description")}
              </small>
            </div>
          </div>
        </ListItem>

      </List>

      {!isOutbound && (
        <>
          <Card title={t("widgets.forms.location.form.locationPhoneCallForm.phone.title")} className="mb-2 p-0">
            <input
              type="tel"
              name="phone"
              placeholder={t("widgets.forms.location.form.locationPhoneCallForm.phone.placeholder")}
              className="w-full text-tg-theme-text bg-tg-theme-section focus:text-tg-theme-text
                focus:bg-tg-theme-section focus:outline-none"
              value={data.phone}
              onChange={handleChange}
            />
          </Card>

          <Card title={t("widgets.forms.location.form.locationPhoneCallForm.note.title")} className="mb-2 p-0">
            <textarea
              name="note"
              className="w-full text-tg-theme-text bg-tg-theme-section focus:text-tg-theme-text
                focus:bg-tg-theme-section focus:outline-none"
              value={data.note}
              onChange={handleChange}
            />
          </Card>
        </>
      )}

      {connected && onRemove && (
        <List className="mt-5">
          <ListItem
            onClick={onRemove}>
            <div className="text-tg-theme-destructive">
              {t("widgets.forms.location.buttons.remove")}
            </div>
          </ListItem>
        </List>
      )}
    </div>
  )
}

export default LocationPhoneCallForm
