import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useScheduleListQuery } from "app/services/schedules";
import { Schedule } from "app/types/schedule";
import Accordion from "widgets/components/accordion";
import { useAccount } from "hooks/useAccount";

export interface EventTypeListProps {
}

const ScheduleAccordionList: FC<EventTypeListProps> = ({}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAccount()

  const {
    data: schedules,
    isLoading: isSchedulesLoading,
    isSuccess: isSchedulesSuccess
  } = useScheduleListQuery({ profile_id: auth.profile.id })

  const handleSelectSchedule = (schedule: Schedule) => {
    navigate(`/schedule/${schedule.id}`)
  }

  const handleCreateSchedule = () => {
    navigate(`/schedules/create`)
  }

  const schedulePlaceholder: string = !schedules || schedules.length === 0
    ? t("widgets.components.scheduleAccordionList.placeholder")
    : schedules?.length === 1
      ? schedules[0].name
      : `${schedules[0].name}, +${schedules?.length - 1} ${t("widgets.components.scheduleAccordionList.more")}`;

  return (
    <Accordion
      title={t("widgets.components.scheduleAccordionList.title")}
      name={schedulePlaceholder}
      options={(schedules || []).map((s) => {
        return {
          label: s.name,
          onClick: () => handleSelectSchedule(s),
          value: (
            <div className="w-8 text-right text-tg-theme-subtitle">
              <i className="fas fa-angle-right"/>
            </div>
          ),
        }
      })}
      footer={(
        <div className="flex ms-2 text-sm text-tg-theme-accent-text" onClick={handleCreateSchedule}>
          + {t("widgets.components.scheduleAccordionList.addSchedule")}
        </div>
      )}
    />
  )
}

export default ScheduleAccordionList
