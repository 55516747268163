import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreen } from "context/screen.provider";
import { useNavigate } from "react-router-dom";
import { EventFilter } from "features/event/components/eventList";
import { PendingEventList } from "../components/pendingEventList";

const EventPendingPage = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [ eventFilter, setEventFilter ] = useState<EventFilter>({})
  const {
    setBackButtonVisible,
    setBackButtonOnClick,
    setMainButtonVisible,
  } = useScreen();

  useEffect(() => {
    setMainButtonVisible(false)
    setBackButtonVisible(true)
    setBackButtonOnClick(() => navigate("/"))
  }, []);

  return (
    <div className="px-5">

      <div className="my-5 mt-4 px-6 capitalize text-center">
        <p className="text-3xl font-medium text-tg-theme-text">
          {t("features.event.pages.list.title")}
        </p>
      </div>

      <div className="my-5">
        <PendingEventList filter={eventFilter}/>
      </div>

    </div>
  );
};

export default EventPendingPage;
