import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "widgets/components/card";
import CalendarMini, { Event as CalendarEvent } from "widgets/forms/calendarmini";
import { Event } from "app/types/event";
import EventLoading from "./eventLoading";
import { EventItem } from "./eventItem";
import { EventListParams, useEventListQuery } from "app/services/events";
import { useGetCalendarEventListQuery } from "app/services/calendar";
import moment from "moment";

type EventFilter = {
  day?: string,
  status?: string,
}

const EventListCard = () => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const [ eventFilter, setEventFilter ] = useState<EventListParams>({
    from: moment().startOf("isoWeek").unix(),
    to: moment().endOf("isoWeek").unix(),
  })
  const [ filter, setFilter ] = useState<EventFilter>({
    day: moment().format("YYYY-MM-DD"),
  })
  const [ day, setDay ] = useState(moment())

  const {
    data: events,
    refetch: eventRefetch,
    isLoading: isEventLoading
  } = useEventListQuery(eventFilter)
  const {
    data: calendarEvents,
    isLoading: isCalendarEventLoading,
    refetch: calendarEventRefetch,
  } = useGetCalendarEventListQuery(eventFilter)

  useEffect(() => {
    eventRefetch()
    calendarEventRefetch()
  }, [ eventFilter ])

  const handleDayChange = (day: string) => {
    const pickedDay = moment(day)
    setDay(pickedDay)
    setFilter({
      day: pickedDay.format("YYYY-MM-DD"),
    })
  }

  const onDateRangeChange = (from: string, to: string) => {
    setEventFilter({
      from: moment(from).unix(),
      to: moment(to).unix()
    })
  }

  if (isEventLoading || isCalendarEventLoading) return <EventLoading/>

  const filterEvents = (events: Event[] = [], filter?: EventFilter): Event[] => {
    if (!filter) return events;
    return events.filter(event => {
      const matchesEventType = filter.day ? moment(event.start_time).format("YYYY-MM-DD") === filter.day : true;
      const matchesStatus = filter.status ? event.status === filter.status : true;
      return matchesEventType && matchesStatus;
    });
  };

  const filteredEvents = filterEvents([ ...(events || []), ...(calendarEvents || []) ] as Event[], filter)
    .sort((left, right) => moment(left.start_time).utc().diff(moment(right.start_time).utc()))

  return (
    <div>
      <div className="px-5">
        <div className="flex justify-between mb-2 px-3">
          <div className="text-tg-theme-subtitle text-lg font-normal">
            {t("features.event.components.eventListCard.title")}
          </div>
          {/*<div className="text-tg-theme-accent-text">*/}
          {/*  <i className="fa-solid fa-filter"/>*/}
          {/*</div>*/}
        </div>

        <Card className="px-0 divide-y-2 divide-neutral-200 dark:divide-neutral-800 rounded-t-xl rounded-b-xl">
          <div className="mb-2 px-5">
            <CalendarMini current={day.unix()}
                          onChange={handleDayChange}
                          onDateRange={onDateRangeChange}
                          events={[ ...(events || []), ...(calendarEvents || []) ] as CalendarEvent[]}
                          allowPrevDay={true}
                          allowEmpty={true}/>
          </div>
        </Card>

        <div className="flex flex-col gap gap-3">
          <div className="divide-y-2 divide-neutral-200 dark:divide-neutral-800">
            {!filteredEvents?.length && (
              <div className="py-4 mb-2">
                <span
                  className="w-full inline-flex text-center justify-center rounded-md px-2 py-3 text-sm
                  font-medium text-tg-theme-subtitle ring-1 ring-inset ring-gray-500/10">
                  {t("features.event.components.eventListCard.no-events")}
                </span>
              </div>
            )}

            {!!filteredEvents?.length && filteredEvents.map((event, index) => (
              <div className="py-2" key={index}>
                <EventItem event={event}
                           onSelect={(id) => navigate(`/event/${id}`)}
                />
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  )
}

export default EventListCard
