import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Schedule, ScheduleRuleWDay, ScheduleRuleWDayType } from "app/types/schedule";
import Toggle from "widgets/forms/toggle";
import moment from "moment";
import TimeRange from "widgets/forms/timerange";

export interface ScheduleFormWeeklyHoursProps {
  value: Schedule,
  onChange: (schedule: Schedule) => void
}

const daysOfWeek = [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday" ];
const dayOfWeekMap: Record<string, ScheduleRuleWDayType> = {
  "Monday": "Mon",
  "Tuesday": "Tue",
  "Wednesday": "Wed",
  "Thursday": "Thu",
  "Friday": "Fri",
  "Saturday": "Sat",
  "Sunday": "Sun",
};

export const ScheduleFormWeeklyHours: FC<ScheduleFormWeeklyHoursProps> = ({ value, onChange }) => {
  const [ scheduleValue, setScheduleValue ] = useState(value);

  useEffect(() => {
    const updatesRules = [ ...value.rules ]
    daysOfWeek.forEach((day) => {
      const existingRule = updatesRules.find(
        (rule) => rule.type === "wday" && (rule as ScheduleRuleWDay).wday === day
      );
      if (!existingRule) {
        updatesRules.push({
          type: "wday",
          wday: day,
          date: "",
          disabled: false,
          intervals: [],
        } as ScheduleRuleWDay);
      }
    });
    setScheduleValue({ ...value, rules: [ ...updatesRules ] });
  }, [ value ]);

  const rules = (scheduleValue.rules
    .filter((rule) => rule.type === "wday") as ScheduleRuleWDay[])
    .sort((left, right) => (left < right ? -1 : 1));

  const handleChange = (index: number, rule: ScheduleRuleWDay) => {
    const updatedRules = scheduleValue.rules.map((r, i) =>
      i === index ? rule : r
    );
    const updatedScheduleValue = { ...scheduleValue, rules: updatedRules };
    setScheduleValue(updatedScheduleValue);
    onChange(updatedScheduleValue);
  };

  return (
    <div className="divide-y-2 divide-neutral-200 dark:divide-neutral-800">
      {rules.map((rule, index) => (
        <div key={index} className="pt-3">
          <ScheduleFormWeeklyHoursDay
            key={rule.wday + "-" + index}
            onChange={(v) => handleChange(index, v)}
            rule={rule as ScheduleRuleWDay}
          />
        </div>
      ))}
    </div>
  );
};

export interface ScheduleFormWeeklyHoursDayProps {
  rule: ScheduleRuleWDay
  onChange?: (rule: ScheduleRuleWDay) => void;
}

export const ScheduleFormWeeklyHoursDay = ({ rule, onChange }: ScheduleFormWeeklyHoursDayProps) => {
  const { t } = useTranslation()
  const [ ruleValue, setRuleValue ] = useState(rule)

  useEffect(() => {
    setRuleValue(rule)
  }, [ rule ])

  const handlerAddInterval = () => {
    if (!ruleValue.intervals?.length) {
      const newState = {
        ...ruleValue,
        intervals: [ ...ruleValue.intervals, { from: "09:00", to: "18:00" } ],
        disabled: false
      }
      onChange && onChange(newState)
      setRuleValue(newState);
    } else {
      const lastInterval = ruleValue.intervals[ruleValue.intervals.length - 1];
      const newFrom = moment(lastInterval.to, "HH:mm").format("HH:mm");
      const newTo = moment(newFrom, "HH:mm").add(1, "hour").format("HH:mm");
      const newState = { ...ruleValue, intervals: [ ...ruleValue.intervals, { from: newFrom, to: newTo } ] }
      setRuleValue(newState);
      onChange && onChange(newState)
    }
  };

  const handleRemoveInterval = (index: number) => {
    const newState = { ...ruleValue, intervals: ruleValue.intervals.filter((_, i) => i !== index) }
    setRuleValue(newState);
    onChange && onChange(newState)
  };

  const handlerUpdateInterval = (index: number, startTime: string, endTime: string) => {
    let newIntervals = ruleValue.intervals.map(interval => ({ ...interval }));
    newIntervals[index]["from"] = startTime;
    newIntervals[index]["to"] = endTime;

    const newState = { ...ruleValue, intervals: newIntervals }
    setRuleValue(newState);
    onChange && onChange(newState)
  };

  const handleDisable = () => {
    const newState = { ...ruleValue, disabled: !ruleValue.disabled }
    setRuleValue(newState);
    onChange && onChange(newState)
  }

  return (
    <div className="pb-4">
      <div className="flex items-center justify-between">
        <p className="text-lg">
          {t(("common.calendar.days." + dayOfWeekMap[ruleValue.wday]).toLowerCase())}
        </p>
        <Toggle checked={!ruleValue.disabled} onToggle={handleDisable}/>
      </div>

      {!!ruleValue?.intervals.length && (
        <div className="mt-2">
          {ruleValue?.intervals.map((interval, index) => {
            return (
              <div className="mb-2" key={"schedule-rule-" + ruleValue.wday + "-internal-" + index}>
                <div className="grid grid-cols-2 gap-4 items-center">
                  <TimeRange
                    startTime={interval.from}
                    endTime={interval.to}
                    onChange={(startTime, endTime) => {
                      handlerUpdateInterval(index, startTime, endTime)
                    }}
                  />
                  <div className="px-5">
                    <i className="fa fa-trash-can text-tg-theme-subtitle" onClick={() => handleRemoveInterval(index)}/>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}

      <div className="text-sm text-tg-theme-accent-text mt-2" onClick={handlerAddInterval}>
        <i className="fa-solid fa-plus"/> {t("features.schedule.forms.scheduleForm.scheduleWeeklyHours.addSlotButton")}
      </div>
    </div>
  )
}
