import { api } from "./api"
import { EmptyRequest, EmptySuccessResponse } from "./empty"
import { Profile, ProfileType } from "app/types/profile";
import { setProfile } from "features/auth/authSlice";
import { handleError } from "utils";

function getProfileFromResponse(src: ProfileResponse): Profile {
  return {
    ...src
  }
}

export type ProfileResponse = {
  id: string,
  type: ProfileType,
  name: string,
  slug: string,
  avatar: string,
  timezone: string,
  calendar_id?: string,
  active: boolean,
  created: number,
  updated: number
}

export type ProfileListResponse = {
  total: number,
  cursor: string,
  order: string,
  items: ProfileResponse[]
}

export type ProfileCreateRequest = {
  name: string,
  type: string,
  slug: string
  timezone: string,
}

export type ProfileUpdateRequest = ProfileCreateRequest

export type ProfileAvatarUpdateRequest = {}

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({

    profileList: build.query<Profile[], EmptyRequest>({
      query: () => ({
        url: "v1/profiles",
        method: "GET",
      }),
      transformResponse: (response: ProfileListResponse): Profile[] => {
        return response.items.map(getProfileFromResponse);
      },
      providesTags: (result: Profile[] = []) => [
        ...result.map(({ id }) => ({ type: "Profiles", id }) as const),
        { type: "Profiles" as const, id: "LIST" },
      ],
    }),

    profileCreate: build.mutation<Profile, ProfileCreateRequest>({
      query: (data: ProfileCreateRequest) => ({
        url: "v1/profile",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: ProfileResponse): Profile => {
        return getProfileFromResponse(response);
      },
      invalidatesTags: [ { type: "Profiles", id: "LIST" } ],
    }),

    profileFetch: build.query<ProfileResponse, EmptyRequest>({
      query: () => ({
        url: "v1/profile/{profile_id}",
        method: "GET",
      }),
    }),

    profileUpdate: build.mutation<ProfileResponse, { id: string, data: ProfileUpdateRequest }>({
      query: ({ id, data }) => ({
        url: `v1/profile/${id}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: ProfileResponse): Profile => {
        return getProfileFromResponse(response);
      },
      invalidatesTags: [ { type: "Profiles", id: "LIST" } ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProfile(data));
        } catch (err) {
          handleError(err, "Service: Failed to update profile")
        }
      },
    }),

    profileDelete: build.mutation<EmptySuccessResponse, string>({
      query: (id) => ({
        url: `v1/profile/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [ { type: "Profiles", id } ],
    }),

    profileUpdateAvatar: build.mutation<EmptySuccessResponse, ProfileAvatarUpdateRequest>({
      query: (data: ProfileAvatarUpdateRequest) => ({
        url: "v1/profile/{profile_id}/avatar",
        method: "PUT",
        body: data,
      }),
    }),
  }),
})

export const {
  useProfileListQuery,
  useProfileCreateMutation,
  useProfileFetchQuery,
  useLazyProfileFetchQuery,
  useLazyProfileListQuery,
  useProfileUpdateMutation,
  useProfileDeleteMutation,
  useProfileUpdateAvatarMutation,
} = profileApi

export const {
  endpoints: { profileList, profileCreate, profileFetch, profileUpdate, profileDelete, profileUpdateAvatar },
} = profileApi

