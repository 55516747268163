import { EventListParams, useEventListQuery } from "app/services/events";
import { useNavigate } from "react-router-dom";
import { EventItem } from "./eventItem";
import moment from "moment";
import { Event } from "app/types/event";
import Time from "widgets/components/time";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EventLoading from "./eventLoading";
import { useAccount } from "../../../hooks/useAccount";

export type EventFilter = {
  eventtype?: string,
  createdAfter?: number,
  createdBefore?: number,
  updatedAfter?: number,
  limit?: number,
  status?: string,
}

interface EventListProps {
  filter?: EventFilter
}

export const EventList = ({ filter }: EventListProps) => {

  const { t } = useTranslation();
  const account = useAccount()
  const [ eventFilter, setEventFilter ] = useState<EventFilter>({})
  const { data: events, isLoading } = useEventListQuery(eventFilter)
  const navigate = useNavigate()

  useEffect(() => {
    let f: EventListParams = {}
    if (!!filter?.createdAfter) f.from = filter.createdAfter
    if (!!filter?.createdBefore) f.to = filter.createdBefore
    if (!!filter?.eventtype) f.event_type_id = filter.eventtype
    if (!!filter?.status) f.status = filter.status
    if (!!filter?.limit) f.limit = filter.limit
    setEventFilter(f)
  }, [ filter ]);

  if (isLoading) return <EventLoading/>
  if (!events || !events.length) {
    return (
      <div className="text-center">
        {t("features.event.components.eventList.no-events")}
      </div>
    )
  }

  // no-events
  const eventDate = (item: Event) => moment(item.start_time).format("YYYY-MM-DD");

  const filterEvents = (events: Event[], filter?: EventFilter): Event[] => {
    if (!filter) return events;

    return events.filter(event => {
      const matchesEventType = filter.eventtype ? event.event_type_id === filter.eventtype : true;
      const matchesCreatedAfter = filter.createdAfter ? event.created > filter.createdAfter : true;
      const matchesUpdatedAfter = filter.updatedAfter ? event.updated > filter.updatedAfter : true;
      const matchesStatus = filter.status ? event.status === filter.status : true;
      return matchesEventType && matchesCreatedAfter && matchesUpdatedAfter && matchesStatus;
    });
  };

  const groupEventsByDate = (events: Event[]): { [date: string]: Event[] } => {
    return events.reduce((groupedEvents, event) => {
      const date = eventDate(event);

      if (!groupedEvents[date]) groupedEvents[date] = [];

      groupedEvents[date].push(event);
      return groupedEvents;
    }, {} as { [date: string]: Event[] });
  };

  const groupedEvents = groupEventsByDate(filterEvents(events, eventFilter));

  return (
    <div className="w-full mb-4">
      {
        Object.keys(groupedEvents)
          .sort((left, right) => moment(left).diff(moment(right)))
          .map((date) => (
            <div key={"event-group-" + date} className="mb-5">
              <h3 className="font-bold text-tg-theme-hint ml-2 mb-2">
                <Time className="capitalize" variant="WDatetime" time={date}/>
              </h3>
              <div className="flex flex-col gap gap-3">
                {groupedEvents[date].map((event: Event) => (
                  <EventItem key={event.id}
                    event={event}
                    onSelect={(id) => navigate(`/event/${id}`)}
                  />
                ))}
              </div>
            </div>
          ))
      }
    </div>
  )
}
