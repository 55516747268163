import { api } from "./api"
import { EmptyRequest, EmptySuccessResponse } from "./empty"
import { Integration } from "../types/integration";

function getIntegrationFromResponse(src: IntegrationResponse): Integration {
  return {
    ...src
  }
}

export interface IntegrationLoginResponse {
  url: string;
}

interface IntegrationExchangeTokenRequest {
  provider: string;
  code: string;
  state: string;
}

export type IntegrationResponse = {
  id: string,
  name: string,
  service: string,
  type: string,
  integration: string,
  url?: string,
}

export type IntegrationListResponse = {
  total: number,
  cursor: string,
  order: string,
  items: IntegrationResponse[]
}

export const integrationApi = api.injectEndpoints({
  endpoints: (build) => ({

    integrationConnect: build.query<IntegrationLoginResponse, string>({
      query: (provider) => ({
        url: `v1/integration/${provider}/connect`,
        method: "GET",
      }),
    }),

    integrationExchangeToken: build.mutation<string, IntegrationExchangeTokenRequest>({
      query: ({ provider, code, state }) => ({
        retry: 0,
        url: `v1/integration/${provider}/connect`,
        method: "POST",
        params: { code, state }
      }),
    }),

    integrationList: build.query<Integration[], EmptyRequest>({
      query: () => ({
        url: "v1/integrations",
        method: "GET",
      }),
      transformResponse: (response: IntegrationListResponse): Integration[] => {
        return response.items.map(getIntegrationFromResponse);
      },
      providesTags: (result: Integration[] = []) => [
        ...result.map(({ id }) => ({ type: "Integrations", id }) as const),
        { type: "Integrations" as const, id: "LIST" },
      ],
    }),

    integrationFetch: build.query<IntegrationResponse, EmptyRequest>({
      query: (id) => ({
        url: `v1/integration/${id}`,
        method: "GET",
      }),
    }),

    integrationDelete: build.mutation<EmptySuccessResponse, string>({
      query: (id) => ({
        url: `v1/integration/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [ { type: "Integrations", id } ],
    }),
  }),
})

export const {
  useIntegrationListQuery,
  useIntegrationFetchQuery,
  useIntegrationExchangeTokenMutation,
  useLazyIntegrationConnectQuery,
  useLazyIntegrationFetchQuery,
  useLazyIntegrationListQuery,
  useIntegrationDeleteMutation,
} = integrationApi

export const {
  endpoints: { integrationList, integrationFetch, integrationDelete },
} = integrationApi

