import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FormatSpot } from "app/types/common";
import {
  EventUpdateRequest,
  useEventCancelMutation,
  useEventFetchQuery,
  useEventUpdateMutation
} from "app/services/events";
import { formatMinutes, parseDuration } from "app/utils";
import Avatar from "widgets/components/avatar";
import Card from "widgets/components/card";
import Time from "widgets/components/time";
import { List, ListItem } from "widgets/components/list/list";
import { Menu, MenuItem } from "widgets/components/menu";
import { Screen, useScreen } from "context/screen.provider";
import EventLoading from "../components/eventLoading";
import EventNotFound from "../components/eventNotFound";
import Note from "widgets/forms/note";
import * as Sentry from "@sentry/react";
import Remind from "widgets/forms/remind";
import EventLocationCard from "../components/eventLocationCard";
import { handleError } from "utils";

const EventInfoPage = () => {

  const { t } = useTranslation();
  const { event_id } = useParams();
  const {
    screen,
    setScreen,
    setBackButtonVisible,
    setBackButtonOnClick,
    setMainButtonVisible,
    setMainButtonProps
  } = useScreen();

  const { data: event, isLoading } = useEventFetchQuery(event_id!)
  const [ cancelEvent, { isError: isCancelError, error: eventCancelError } ] = useEventCancelMutation()
  const [ updateEvent, { isError: isUpdateError, error: eventUpdateError } ] = useEventUpdateMutation()
  const { account } = useAccount()

  const navigate = useNavigate();
  const location = useLocation();
  const [ canGoBack, setCanGoBack ] = useState(false);

  useEffect(() => {
    setMainButtonVisible(true)
    setBackButtonVisible(true)
    setBackButtonOnClick(handleBack)
    setMainButtonProps(t("features.event.pages.info.mainButtonBack"), handleBack)
  }, []);

  useEffect(() => {
    if (!!screen) return
    setMainButtonVisible(true)
    setBackButtonVisible(true)
    setBackButtonOnClick(handleBack)
    setMainButtonProps(t("features.event.pages.info.mainButtonBack"), handleBack)
  }, [ screen ])

  useEffect(() => {
    // Проверяем, если в истории навигации больше одного элемента
    if (window.history.state && window.history.state.idx > 0) {
      setCanGoBack(true);
    } else {
      setCanGoBack(false);
    }
  }, [ location ]);

  const handleBack = () => {
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate("/");
    }
  }

  if (isLoading) return <EventLoading/>
  if (!event) return <EventNotFound/>

  const handleShowCancelForm = () => {
    setScreen("cancel")
    setBackButtonVisible(true)
    setBackButtonOnClick(() => {
      setScreen(undefined)
    })
  }

  const handleReschedule = async () => {
    const member = event.event_memberships.find(m => m.account_id === account.id)
    if (!!member) {
      if (member.role !== "member") {
        navigate(`/eventtype/${event.event_type_id}?reschedule=${event.id}`)
      } else {
        navigate(`/booking/${event.booking_id}?reschedule=${event.id}`)
      }
    } else {
      Sentry.captureMessage(`Event ${event.id} reschedule: member ${account.id} not found`);
    }
  }

  const handleCancel = async (reason: string) => {
    try {
      const result = await cancelEvent({ id: event_id!, data: { reason: reason } }).unwrap()
      if (!isCancelError && result) {
        return
      }
    } catch (err) {
      handleError(err, "Failed to cancel event")
    }
  }

  const handleChange = async (name: string, v: string) => {
    const data = {
      remind: v,
    } as EventUpdateRequest
    try {
      const result = await updateEvent({ id: event_id!, data: data }).unwrap()
      if (!isUpdateError && result) {
        return
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.error("Failed to update remind event:", err.message);
        console.error("Stack trace:", err.stack);
      } else {
        console.error("Failed to update remind event:", JSON.stringify(err, null, 2));
      }
      Sentry.captureMessage("Failed to update remind event");
      Sentry.captureException(err);
    }
  }

  const remind = event.event_memberships.find((member) => member.account_id === account.id)?.remind
  const note = event.event_memberships.find((member) => member.account_id === account.id)?.note

  const screens = {
    cancel: <Note value=""
                  onApply={handleCancel}
                  btnName={t("features.event.pages.info.cancellation.note.confirm")}
                  title={t("features.event.pages.info.cancellation.note.title")}/>,
    remind: <Remind value={remind} onSelect={(v) => handleChange("remind", v)}/>,
  };

  return (
    <Screen screens={screens}>
      <div className="my-5 mt-16 px-6 normal-case">
        <p className="text-3xl font-medium text-tg-theme-text">
          {event.name}
        </p>
      </div>

      <div className="my-5 px-6">
        <div className="flex flex-inline items-center gap gap-1 mb-3">
          <span className={`${event.cancellation ? "w-20" : "w-16"}  text-xs text-tg-theme-hint capitalize`}>
            {t("features.event.pages.info.main.status")}
          </span>
          <i className="fa-regular fa-clock ml-1 text-tg-theme-accent-text"/>
          <span className="text-xs font-bold text-tg-theme-accent-text capitalize ">
            {event.status}
          </span>
        </div>

        <div className="flex flex-inline items-center gap gap-1 mb-3">
          <span className={`${event.cancellation ? "w-20" : "w-16"} text-xs text-tg-theme-hint capitalize`}>
            {t("features.event.pages.info.main.type")}
          </span>
          <i className="fa-regular fa-calendar ml-1 text-tg-theme-accent-text"/>
          <span className="text-xs font-bold text-tg-theme-accent-text capitalize ">
            {event.type}
          </span>
        </div>

        {event.cancellation && (
          <div className="flex flex-inline items-center gap gap-1 mb-3">
            <span className={`${event.cancellation ? "w-20" : "w-16"} text-xs text-tg-theme-hint capitalize`}>
              {t("features.event.pages.info.main.canceled_by")}
            </span>
            <span className="text-xs font-bold text-tg-theme-accent-text ">
              @{event.cancellation.canceled_by}
            </span>
          </div>
        )}
      </div>

      {event.cancellation && (
        <div className="px-5 mt-5 mb-5 w-full">
          <Card title={t("features.event.pages.info.cancellation.title")}
                className="bg-tg-theme-section rounded-xl flex">
            <span className="text-left">
              {event.cancellation.reason}
            </span>
          </Card>
        </div>
      )}

      <div className="px-5 mt-5 mb-5">
        <Card
          title={t("features.event.pages.info.time.title")}>
          <div className="w-full mb-3 pb-2 border-b-2 border-b-neutral-300 dark:border-b-neutral-700">
            <div className="flex flex-row h-22 pb-2 items-center">
              <div className="w-36 h-22">
                <div className="w-36 text-left pb-2 pt-2">
                  <p className="font-bold text-tg-theme-accent-text">
                    {t(`common.calendar.days.${moment(event.start_time).format("ddd").toLowerCase()}`)}
                  </p>
                  <p className="font-bold text-tg-theme-accent-text">
                    <Time variant="WDatetime" time={event.start_time}/>
                  </p>
                </div>
              </div>

              <div
                className="w-48 h-18 ml-auto py-2 pl-4 border-l-2 border-l-neutral-300 dark:border-l-neutral-700">
                <div className="max-w-48 flex flex-row items-center gap gap-4">
                  <div className="basis-1/2 text-left">
                    <p className="text-tg-theme-hint text-xs">
                      {t("features.event.pages.info.time.start")}
                    </p>
                    <p className="h-5 font-bold text-tg-theme-accent-text">
                      <Time className="text-xs" variant="Time" time={event.start_time} />
                    </p>
                  </div>
                  <div className="basis-1/2 text-left">
                    <p className="text-tg-theme-hint text-xs">
                      {t("features.event.pages.info.time.end")}
                    </p>
                    <p className="h-5 font-bold text-tg-theme-accent-text">
                      <Time className="text-xs" variant="Time"
                            time={moment(event.start_time, FormatSpot)
                              .add(parseDuration(event.duration), "minutes")
                              .format(FormatSpot)}/>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="w-full">
            <p className="text-tg-theme-hint">
              {t("features.event.pages.info.time.duration")}: {formatMinutes(parseDuration(event.duration), account.language)}
            </p>
          </div>

        </Card>
      </div>

      {!!event.location && (
        <div className="px-5 mt-5">
          <Card title={t("features.event.pages.info.location.title")}>
            <EventLocationCard location={event.location}
                               isInvitee={event.event_memberships.find((m) => m.account_id === account.id)?.role === "member"}/>
          </Card>
        </div>
      )}

      <div className="w-full my-5 px-1">
        <Menu>
          <MenuItem onClick={handleReschedule}>
            <button className="btn rounded-md h-12 basis-1/2 w-full py-3">
              <span className="inline-flex gap gap-2 items-center">
                <i className="fa-solid fa-arrows-rotate"/>
                <p className="text-sm">
                  {t("features.event.pages.info.menu.reschedule.title")}
                </p>
              </span>
            </button>
          </MenuItem>
          {[ "cancel", "done" ].indexOf(event.status) === -1 && (
            <MenuItem>
              <button
                onClick={handleShowCancelForm}
                className="rounded-md h-12 basis-1/2 w-full py-3 bg-transparent border border-1 border-tg-theme-destructive">
                <span className="inline-flex gap gap-2 items-center text-tg-theme-destructive">
                  <i className="fa-regular fa-circle-xmark"/>
                  <p className="text-sm">
                    {t("features.event.pages.info.menu.cancel.title")}
                  </p>
                </span>
              </button>
            </MenuItem>
          )}
        </Menu>
      </div>

      <div className="my-5 px-5">
        <List title={t("features.event.pages.info.members.title")}>
          {
            (event.event_memberships.length > 0) ? (
                event.event_memberships.map((member, index) => (
                  <ListItem key={index}>
                    <div className={"flex flex-row gap gap-3"}>
                      <Avatar
                        rounded={true}
                        name={member.name}
                        src={member.avatar}
                        size={2.5}/>

                      <div>
                        <h4 className="text-tg-theme-text">
                          {member.name}
                        </h4>
                        <p color="gray" className="text-xs text-tg-theme-hint">
                          {t("common.roles." + member.role)}
                        </p>
                      </div>
                    </div>

                  </ListItem>

                ))) :
              <ListItem>
                <h6 className="text-tg-theme-hint text-xs">
                  {t("features.event.pages.info.members.no-members")}
                </h6>
              </ListItem>
          }
        </List>
      </div>

      {[ "cancel", "done" ].indexOf(event.status) === -1 && (
        <div className="px-5 my-5">
          <Card onClick={() => setScreen("remind")}>
            <div className="flex justify-between">
              <div>
                {t("features.event.pages.info.remind.title")}
              </div>
              <div className="inline-flex items-center justify-end">
                <div className="text-tg-theme-subtitle">
                  {!!remind ? formatMinutes(parseDuration(remind), account.language) : t("features.event.pages.info.remind.no-remind")}
                </div>
                <div className="w-8 text-right text-tg-theme-subtitle">
                  <i className="fa fa-arrows-up-down"/>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}

      <div className="px-5 my-5">
        <Card>
          <h5 className="text-tg-theme-accent-text mb-2">{t("features.event.pages.info.note.title")}</h5>
          <p className="text-xs text-tg-theme-hint">
            <span className="text-xs">{note || t("features.event.pages.info.note.no-note")}</span>
          </p>
        </Card>
      </div>
    </Screen>
  );
};

export default EventInfoPage;
