import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Card from "widgets/components/card";
import { useTranslation } from "react-i18next";
import { useScreen } from "context/screen.provider";

export interface Props {
  value?: string
  onChange?: (v?: string) => void
  onSubmit?: (v?: string) => void
}

const LocationPlaceSetForm: FC<Props> = ({ value, onSubmit }) => {

  const { t } = useTranslation();
  const { setMainButtonVisible, setMainButtonProps, isVisibleMainButton } = useScreen();
  const [ dataValue, setDataValue ] = useState(value)

  useEffect(() => {
    const lastState = isVisibleMainButton
    setMainButtonVisible(true)
    setMainButtonProps(t("features.booking.forms.locationPlaceSetForm.mainButton"), handleSubmit)
    return () => {
      setMainButtonVisible(true)
      setMainButtonVisible(lastState)
      setMainButtonProps(t("features.booking.forms.locationPlaceSetForm.mainButton"), handleSubmit)
    }
  }, []);

  useEffect(() => {
    setMainButtonProps(t("features.booking.forms.locationPlaceSetForm.mainButton"), handleSubmit)
  }, [ dataValue ]);

  useEffect(() => {
    setDataValue(value)
  }, [ value ])

  const handleSubmit = () => {
    onSubmit && onSubmit(dataValue)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDataValue(e.target.value)
  }

  return (
    <div className="px-5">

      <div className="font-bold pt-5 pb-8 text-center">
        {t("features.booking.forms.locationPlaceSetForm.title")}
      </div>

      <Card title={t("features.booking.forms.locationPlaceSetForm.label")}
        hint={t("features.booking.forms.locationPlaceSetForm.hint")}>
        <textarea
          name="note"
          autoFocus
          placeholder={t("features.booking.forms.locationPlaceSetForm.placeholder")}
          className="w-full text-tg-theme-text bg-tg-theme-section focus:text-tg-theme-text focus:bg-tg-theme-section focus:outline-none"
          value={dataValue}
          style={{
            height: "10rem",
            maxHeight: "10rem",
          }}
          onChange={handleChange}
        />
      </Card>
    </div>
  )
}

export default LocationPlaceSetForm
