import { eventTypeGeneralList } from "app/types/eventType"

export interface EventIconProps {
  kind: string
}

function findIcoByKind(kind: string): any | undefined {
  for (const category in eventTypeGeneralList) {
    if (eventTypeGeneralList.hasOwnProperty(category)) {
      const events = eventTypeGeneralList[category as keyof typeof eventTypeGeneralList];
      for (const event of events) {
        if (event.kind === kind) {
          return event;
        }
      }
    }
  }
  return undefined;
}

export const EventIcon = ({ kind }: EventIconProps) => {

  let iconClass = findIcoByKind(kind)?.ico

  return (
    <div className="flex items-center justify-center bg-gray-100 dark:bg-gray-700 h-12 w-12 p-2
      rounded-full text-tg-theme-hint text-2xl">
      <i className={"text-inherit " + iconClass + " px-1 fa-xs m-auto"}></i>
    </div>
  )
}
