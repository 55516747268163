import React, { ChangeEvent, useEffect, useState } from "react";
import { useHapticFeedback } from "app/telegram";
import { List, ListItem } from "../../components/list/list";
import { languages } from "./languages";
import { useScreen } from "../../../context/screen.provider";
import { useTranslation } from "react-i18next";

interface LanguageProps {
  value: string;
  onSelect?: (v: string) => void;
}

const Language = ({ value, onSelect }: LanguageProps) => {
  const [ , , selectionChanged ] = useHapticFeedback();
  const [ filter, setFilter ] = useState("");
  const { setScreen, setMainButtonVisible, isVisibleMainButton } = useScreen();
  const { t } = useTranslation();

  useEffect(() => {
    const preVisibleState = isVisibleMainButton;
    setMainButtonVisible(false)
    return () => {
      setMainButtonVisible(preVisibleState)
    }
  }, []);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value.toLowerCase());
  };

  const handleSelect = (v: string) => {
    selectionChanged();
    onSelect && onSelect(v)
    setScreen(undefined)
  };

  const filteredData = languages
    .flat()
    .filter(item =>
      item.label.toLowerCase().includes(filter)
    );

  return (
    <div className="container w-full h-full overflow-y-auto p-5">

      <div className="flex justify-center mb-5">
        <div className="w-full">
          <input
            type="text"
            className="w-full p-3 text-tg-theme-text bg-tg-theme-section focus:outline-none"
            onChange={handleSearch}
            placeholder={t("widgets.forms.language.search")}
          />
        </div>
      </div>

      <List>
        {filteredData.map((item, index) => (
          <ListItem
            key={index}
            disabled={item.soon}
            onClick={() => handleSelect(item.value)}>
            <div className="flex justify-between items-center">
              <div>
                <p>{item.label}</p>
                <p className="text-xs">
                  {item.hint}
                </p>
              </div>

              <div className="w-8 text-right text-tg-theme-subtitle">
                <i className="fas fa-angle-right"/>
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default Language;
