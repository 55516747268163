import { useTranslation } from "react-i18next";
import { EventList } from "features/event/components/eventList";
import { useNavigate } from "react-router-dom";
import FormSearch from "widgets/forms/search/search";

const EventRequestsPage = () => {

  const { t } = useTranslation()

  return (
    <>
      <div className="my-5">
        <p className="text-gray-800">
          {t("features.event.pages.requests.title")}
        </p>
        <p className="text-gray-600 font-bold">
          {t("features.event.pages.requests.no-event-requests")}
        </p>
      </div>

      <div className="my-5">
        <FormSearch/>
      </div>


      <div className="my-5">
        <p className="text-gray-800 mb-2">
          {t("features.event.pages.requests.sent")}
        </p>
        <EventList/>
      </div>


      <div className="my-5">
        <p className="text-gray-800 mb-2">
          {t("features.event.pages.requests.invited")}
        </p>
        <EventList/>
      </div>
    </>
  );
};

export default EventRequestsPage;
