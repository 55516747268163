import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { LocationInPersonalMeeting } from "app/types/location";
import Card from "../../../components/card";
import { useTranslation } from "react-i18next";
import { List, ListItem } from "../../../components/list/list";

interface LocationInPersonalMeetingProps {
  value: LocationInPersonalMeeting,
  onChange: (v: LocationInPersonalMeeting) => void
  onRemove?: () => void
  connected: boolean
}

const LocationInPersonalMeetingForm: FC<LocationInPersonalMeetingProps> = ({
  value,
  onChange,
  onRemove,
  connected
}) => {

  const { t } = useTranslation();

  const [ data, setData ] = useState<LocationInPersonalMeeting>(value)

  useEffect(() => {
    setData(value)
  }, [ value ])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = { ...data, [e.target.name]: e.target.value }
    setData(value)
    onChange(value)
  }

  return (
    <div className="px-5">

      <div className="font-bold pt-5 pb-8 text-center">
        {t("widgets.forms.location.form.locationInPersonalMeetingForm.title")}
      </div>

      <div className="mb-5 w-full">
        <Card
          title={t("widgets.forms.location.form.locationInPersonalMeetingForm.name.title")}
          hint={t("widgets.forms.location.form.locationInPersonalMeetingForm.name.hint")}
        >
          <input
            type="text"
            name="name"
            placeholder={t("widgets.forms.location.form.locationInPersonalMeetingForm.name.placeholder")}
            className="w-full text-tg-theme-text bg-tg-theme-section focus:text-tg-theme-text focus:bg-tg-theme-section focus:outline-none"
            value={data.name}
            onChange={handleChange}
          />
        </Card>
      </div>


      <Card
        title={t("widgets.forms.location.form.locationInPersonalMeetingForm.note.title")}
        hint={t("widgets.forms.location.form.locationInPersonalMeetingForm.note.hint")}>
        <textarea
          name="note"
          placeholder={t("widgets.forms.location.form.locationInPersonalMeetingForm.note.placeholder")}
          className="w-full text-tg-theme-text bg-tg-theme-section focus:text-tg-theme-text focus:bg-tg-theme-section focus:outline-none"
          value={data.note}
          onChange={handleChange}
        />
      </Card>

      {connected && onRemove && (
        <List className="mt-5">
          <ListItem
            onClick={onRemove}>
            <div className="text-tg-theme-destructive">
              {t("widgets.forms.location.buttons.remove")}
            </div>
          </ListItem>
        </List>
      )}
    </div>
  )
}

export default LocationInPersonalMeetingForm
