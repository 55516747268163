import React, { FC } from "react";
import { List, ListItem } from "widgets/components/list/list";
import { useLazyIntegrationConnectQuery } from "app/services/integration";
import { useShowPopup, useWebApp } from "app/telegram";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Calendar = {
  ico: React.ReactNode;
  service: string;
  title: string;
  disabled: boolean;
}

const calendars: Calendar[] = [
  {
    ico: <i className="fa-brands fa-google"/>,
    service: "google",
    title: "Google Calendar",
    disabled: false
  },
  {
    ico: <i className="fa-brands fa-yandex"/>,
    service: "yandex",
    title: "Yandex Calendar",
    disabled: true
  },
  {
    ico: <i className="fa-brands fa-microsoft"/>,
    service: "outlook",
    title: "Outlook Calendar",
    disabled: true
  },
]

export interface CalendarConnectFormProps {
  hiddenDisabled?: boolean
}

const CalendarConnectForm: FC<CalendarConnectFormProps> = ({ hiddenDisabled }) => {

  const webApp = useWebApp()
  const showPopup = useShowPopup()
  const navigate = useNavigate()

  const { t } = useTranslation();
  const [ triggerLogin, {
    data: loginData, isLoading: isConnectLoading, isError: isConnectError
  } ] = useLazyIntegrationConnectQuery();

  const handleLogin = async (provider: string) => {
    const result = await triggerLogin(provider);
    if (result.data) {
      webApp.openLink(result.data.url);
    } else {
      if ((result.error as any)?.status === 402) {
        showPopup({
          title: t("features.calendar.forms.calendarConnectForm.popupLimit.title"),
          message: t("features.calendar.forms.calendarConnectForm.popupLimit.message"),
          buttons: [
            {
              id: "close",
              type: "default",
              text: t("features.calendar.forms.calendarConnectForm.popupLimit.buttons.close")
            },
          ]
        }).then(async buttonId => {
        });
      }
    }
  };

  const filteredCalendars = (calendars: Calendar[]) => {
    if (!hiddenDisabled) return calendars || []
    return (calendars || []).filter((i: { disabled: any; }) => !i.disabled)
  }

  return (
    <List title={t("features.calendar.forms.calendarConnectForm.title")}>
      {filteredCalendars(calendars).map((i, index) => (
        <ListItem key={index}
                  onClick={() => handleLogin(i.service)}
                  disabled={i.disabled}
                  prefix={i.ico}>
          <div className="flex justify-between">
            <div className="flex-1">
              {i.title}
            </div>
            <div>
              {i.disabled
                ? <div>{t("features.calendar.forms.calendarConnectForm.soonMessage")}...</div>
                : (
                  <button className="rounded-xl text-xs py-1 px-4">
                    {t("features.calendar.forms.calendarConnectForm.buttonConnect")}
                  </button>
                )
              }
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  )
}

export default CalendarConnectForm
