import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWebApp } from "app/telegram";
import { useBookingFetchByHashQuery } from "app/services/booking";
import BookingEventCreateForm from "../forms/bookingEventCreateForm";
import {
  EventUpdateRequest,
  useEventCreateMutation,
  useEventUpdateMutation,
  useLazyEventListQuery
} from "app/services/events";
import { Event } from "app/types/event";
import { useScreen } from "context/screen.provider";
import moment from "moment/moment";
import Loading from "../components/loading";
import BookingNotFound from "../components/bookingNotFound";
import Error from "../components/error";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { EventItem } from "features/event/components/eventItem";
import { useAccount } from "hooks/useAccount";
import { handleError } from "utils";

const BookingPage = () => {

  const { t } = useTranslation()
  const { hash } = useParams();
  const [ searchParams ] = useSearchParams();
  const [ events, setEvents ] = useState<Event[]>([])
  const [ formErrors, setFormErrors ] = useState({})
  const webApp = useWebApp()
  const navigate = useNavigate();
  const {
    screen,
    setScreen,
    setBackButtonVisible,
    setBackButtonOnClick,
    setMainButtonVisible,
    setMainButtonProps
  } = useScreen();
  const { account } = useAccount()

  const getDefaultName = () => {
    let accName = [ account.first_name, account.last_name ].join(" ")
    if (!accName) accName = account.username
    return (booking?.hosts[0].name + " x " + accName)
      .replace(/\s{2,}/g, ' ')
      .trim()
  }

  const {
    data: booking,
    error: bookingError,
    isLoading: isBookingLoading,
    isError: isBookingError
  } = useBookingFetchByHashQuery(hash!)
  const [ getBookingEvents ] = useLazyEventListQuery()
  const [ createEvent, { isError: isCreateError, error: eventCreateError } ] = useEventCreateMutation()
  const [ updateEvent, { isError: isUpdateError, error: eventUpdateError } ] = useEventUpdateMutation()

  const [ note, setNote ] = useState<string>("")
  const [ formData, setFormData ] = useState({
    booking_id: hash,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    name: "",
    start_time: "",
    remind: "30m",
    note: "",
    day: "",
    location: null,
  })

  // handle back button
  const handleBack = () => {
    webApp.initDataUnsafe.start_param = "";
    if (searchParams.has("preview") || searchParams.has("reschedule")) {
      navigate(-1)
    } else {
      navigate("/")
    }
  }

  const handleReschedule = async () => {
    if (!formData.start_time) return
    const data = {
      start_time: formData.start_time,
      remind: formData.remind,
      note: formData.note,
      location: formData.location || undefined,
      reschedule: searchParams.get("reschedule") || undefined
    } as EventUpdateRequest
    try {
      const response = await updateEvent({ id: searchParams.get("reschedule")!, data: data }).unwrap()
      response.id && setEvents([ response ])
      setBackButtonVisible(false)
      setMainButtonProps(t("features.booking.pages.booking.mainButtonClose"), () => webApp.close())
      return
    } catch (err) {
      handleError(err, "Failed to reschedule event")
    }
  }

  useEffect(() => {
    if (!booking) return

    const isPreview = !!(booking.hosts?.find(h => h.account_id === account.id))

    if (isPreview && !searchParams.has("preview")) {
      searchParams.set("preview", "true")
      return;
    }

    const location: any = !!booking.locations?.length ? booking.locations[0] : null
    setFormData({
      ...formData,
      name: getDefaultName(),
      timezone: booking.timezone,
      location: location,
    })
  }, [ booking ]);

  // Configure navigation routing
  useEffect(() => {

    if (screen === "note") {
      setBackButtonVisible(true)
      setBackButtonOnClick(() => setScreen(undefined))
      setMainButtonProps(t("features.booking.pages.booking.mainButtonApply"), () => {
        setFormData({ ...formData, note: note })
        setScreen(undefined)
      })
      return
    }

    if (screen === "availability" || screen === "timezone") {
      setBackButtonVisible(true)
      setBackButtonOnClick(() => setScreen(undefined))
      setMainButtonVisible(false)
      return;
    }

    if (screen === "location_phone" || screen === "location_place" || screen === "location") {
      setBackButtonVisible(true)
      setBackButtonOnClick(() => setScreen(undefined))
      return;
    }

    if (events.length > 0 && !searchParams.has("reschedule")) {
      setMainButtonProps(t("features.booking.pages.booking.mainButtonHome"), () => {
        webApp.initDataUnsafe.start_param = ""
        navigate("/")
      })
      return;
    }

    if (searchParams.has("preview")) {
      if (!screen) {
        setBackButtonVisible(false)
        setMainButtonProps(t("features.booking.pages.booking.mainButtonClosePreview"), handleBack)
        return;
      } else {
        setBackButtonVisible(true)
        setMainButtonVisible(false)
        setBackButtonOnClick(() => setScreen(undefined))
        return;
      }
    }

    setBackButtonVisible(searchParams.has("reschedule"))
    setBackButtonOnClick(handleBack)

    if (!booking) return;
    if (searchParams.has("reschedule") && (!!formData.start_time)) {
      setMainButtonProps(t("features.booking.pages.booking.mainButtonReschedule"), handleReschedule)
      return;
    }

    if (booking.status === "done") {
      setMainButtonProps(t("features.booking.pages.booking.mainButtonHome"), () => {
        webApp.initDataUnsafe.start_param = ""
        navigate("/")
      })
      getBookingEvents({ event_type_id: booking.id }).unwrap().then(setEvents)
      return
    }

    if (!formData.start_time) {
      setMainButtonProps(t("features.booking.pages.booking.mainButtonClose"), () => webApp.close())
      return
    }

    setMainButtonProps(t("features.booking.pages.booking.mainButtonSubmit"), handleSubmit)
  }, [ screen, formData, note, booking ])

  if (isBookingLoading) return <Loading/>
  if (isBookingError && (bookingError as FetchBaseQueryError).status !== 404) {
    return <Error/>;
  }

  if (!booking) return <BookingNotFound/>
  if (events.length > 0 && !searchParams.has("reschedule")) {
    return (
      <div>
        <div className="my-5 mt-16 px-5 normal-case text-center">
          <p className="text-3xl font-medium text-tg-theme-text">
            {booking.name}
          </p>
        </div>

        <div className="px-5 my-5 text-center">
          {t("features.booking.pages.booking.registered")}
        </div>

        {!!events?.length && (
          <div className="px-5">
            {events.map(event =>
              <div key={event.id}>
                <p className="text-tg-theme-hint capitalize">
                  {moment(event.start_time).format("dddd, MMMM Do, YYYY")}
                </p>
                <EventItem key={event.id} event={event} onSelect={() => navigate(`/event/${event.id}`)}/>
              </div>
            )}
          </div>
        )}
      </div>
    )
  } else if (booking.status === "done") {
    navigate("/")
    return
  }

  const handleChange = (name: any, data: any) => {
    setFormErrors({})

    if (name === "note") {
      setNote(data)
      return;
    }

    setScreen(undefined)
    const patch = { ...formData, [name]: data }
    setFormData(patch)
  }

  const handleSubmit = async () => {
    if (!formData.start_time) return

    const data = {
      name: formData.name,
      booking_id: booking.hash,
      start_time: formData.start_time,
      remind: formData.remind,
      note: formData.note,
      location: formData.location,
    }

    const errorsData = {} as any

    if (!formData.name)
      errorsData.name = "cannot be empty"

    if (!!Object.keys(errorsData).length) {
      setFormErrors(errorsData)
      return
    }

    try {
      const response = await createEvent(data).unwrap()
      response.id && setEvents([ response ])
      setBackButtonVisible(false)
      setMainButtonProps(t("features.booking.pages.booking.mainButtonClose"), () => webApp.close())
      return
    } catch (err) {
      handleError(err, "Failed to create event")
    }
  }

  return (
    <>
      {searchParams.has("preview") && (
        <>
          <div className="fixed h-7 z-10 w-full text-center items-center bg-tg-theme-main">
            <span className="text-tg-theme-accent-text">
              {t("features.booking.pages.booking.preview")}
            </span>
          </div>
          <div className="h-7"/>
        </>
      )}

      {(searchParams.has("reschedule") || (!!booking && booking.status !== "done")) && (
        <BookingEventCreateForm booking={booking} onChange={handleChange} errors={formErrors}/>
      )}
    </>
  )
};

export default BookingPage;
