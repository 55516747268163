import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { List, ListItem } from "../../../components/list/list";
import { useIntegrationListQuery, useLazyIntegrationConnectQuery } from "../../../../app/services/integration";
import { useAccount } from "../../../../hooks/useAccount";
import Card from "../../../components/card";
import { useScreen } from "../../../../context/screen.provider";
import { useShowPopup, useWebApp } from "../../../../app/telegram";

interface LocationGoogleProps {
  onRemove?: () => void
  connected: boolean
}

const LocationGoogleForm: FC<LocationGoogleProps> = ({ onRemove, connected }) => {
  const { t } = useTranslation()
  const webApp = useWebApp()
  const showPopup = useShowPopup()
  const auth = useAccount()
  const prevLength = useRef<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const {
    setMainButtonVisible,
  } = useScreen();
  const {
    data: integrations,
    refetch,
    isLoading: isIntegrationsLoading,
    isSuccess: isIntegrationsSuccess,
  } = useIntegrationListQuery({ profile_id: auth.profile.id })
  const [ triggerLogin, {
    data: loginData, isLoading: isConnectLoading, isError: isConnectError
  } ] = useLazyIntegrationConnectQuery();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refetch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [ refetch ]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, []);

  useEffect(() => {
    const currentLength = integrations?.length || 0;
    if (currentLength !== prevLength.current) {
      if (intervalRef.current) {
        setMainButtonVisible(true)
        clearInterval(intervalRef.current);
      }
    }
  }, [ integrations ]);

  const handleLogin = async (provider: string) => {
    const result = await triggerLogin(provider);
    if (result.data) {
      webApp.openLink(result.data.url);
      if (!intervalRef.current) {
        prevLength.current = integrations?.length || 0;
        intervalRef.current = setInterval(() => {
          refetch();
        }, 2000);
      }
    } else {
      if ((result.error as any)?.status === 402) {
        showPopup({
          title: t("features.calendar.forms.calendarConnectForm.popupLimit.title"),
          message: t("features.calendar.forms.calendarConnectForm.popupLimit.message"),
          buttons: [
            {
              id: "close",
              type: "default",
              text: t("features.calendar.forms.calendarConnectForm.popupLimit.buttons.close")
            },
          ]
        }).then(async buttonId => {
        });
      }
    }
  };

  if (isIntegrationsLoading) return <div/>

  if (!integrations?.length || integrations?.findIndex(i => i.service === "google") === -1) {
    setMainButtonVisible(false)
    return (
      <div className="px-5">

        <div className="font-bold pt-5 pb-8 text-center">
          {t("widgets.forms.location.form.locationGoogleForm.title")}
        </div>

        <Card>
          <div className="text-tg-theme-subtitle">
            <div>{t("widgets.forms.location.form.locationGoogleForm.no-connected")}</div>
            <div className="w-full flex justify-center mt-5">
              <button className="rounded-xl text-xs py-1 px-4"
                onClick={() => handleLogin("google")}>
                {t("widgets.forms.location.form.locationGoogleForm.connectButton")}
              </button>
            </div>
          </div>
        </Card>
      </div>
    )
  }

  // TODO: сделать выборку сервиса выбранного по умолчаню у профайла в будущем
  const integration = integrations?.find(i => i.service === "google")

  return (
    <div className="px-5">

      <div className="font-bold pt-5 pb-8 text-center">
        {t("widgets.forms.location.form.locationGoogleForm.title")}
      </div>

      <Card>
        <div className="gap gap-2 text-xs">
          <div className="text-tg-theme-subtitle mb-3">
            {t("widgets.forms.location.form.locationGoogleForm.connected")}
          </div>
          <div className="text-tg-theme-subtitle py-2 uppercase">
            {t("widgets.forms.location.form.locationGoogleForm.connectedBy")}
          </div>
          <div className="flex justify-between">
            <div>{integration?.name}</div>
          </div>
        </div>
      </Card>

      {connected && onRemove && (
        <List className="mt-5">
          <ListItem
            onClick={onRemove}>
            <div className="text-tg-theme-destructive">
              {t("widgets.forms.location.buttons.remove")}
            </div>
          </ListItem>
        </List>
      )}
    </div>
  )
}

export default LocationGoogleForm

