import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TimeSpotItem, { calcSpotPosition, getHeight, parseDuration } from "./timespot";
import { formatDuration, getDayIntervalFromTimeSpot, getTimeSpotsFromDayIntervals, newTimeSpot } from "./helpers";
import { useHapticFeedback } from "app/telegram";
import { ScheduleRuleInterval } from "app/types/schedule";
import moment from "moment";

export interface TimeSpot {
  start: string;
  duration: string;
}

export interface Event {
  name: string;
  description: string;
  start_time: string,
  end_time: string,
  duration: string,
  service?: string,
}

export interface IProps {
  duration?: string
  events?: Event[]
  intervals: ScheduleRuleInterval[]
  onChange?: (spots: ScheduleRuleInterval[]) => void;
}

const TimePicker: FC<IProps> = ({ intervals, events, duration = "30m", onChange }) => {
  const { t } = useTranslation()
  const [ , , selectionChanged ] = useHapticFeedback();

  const [ eventItems, setEventItems ] = useState<Event[]>();
  const [ timeSpots, setTimeSpots ] = useState<TimeSpot[]>(getTimeSpotsFromDayIntervals(intervals));
  const [ selectedTimeSpot, setSelectedTimeSpot ] = useState<TimeSpot>()

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Прокрутка до 08:00 при первом рендере
    if (containerRef.current) {
      const target = document.getElementById("07:30");
      if (target) {
        containerRef.current.scrollTop = target.offsetTop;
      }
    }
  }, []);

  useEffect(() => {
    setTimeSpots(getTimeSpotsFromDayIntervals(intervals))
  }, [ intervals ])

  useEffect(() => {
    setSelectedTimeSpot(undefined)
  }, [ duration ])

  useEffect(() => {
    setEventItems(events)
  }, [ events ])

  useEffect(() => {
    if (timeSpots.findIndex(s => s.start === selectedTimeSpot?.start) === -1) {
      setSelectedTimeSpot(undefined)
    }
  }, [ selectedTimeSpot ])

  const handlePickTime = (time: string, spotDuration?: string) => {

    spotDuration = spotDuration || duration

    const newSpotStart = moment(time, "HH:mm");
    const newSpotEnd = newSpotStart.clone().add(parseDuration(spotDuration), "minutes");

    // Проверяем, пересекается ли новый интервал с любым из существующих
    const overlaps = timeSpots.some(spot => {
      const spotStart = moment(spot.start, "HH:mm");
      const spotEnd = spotStart.clone().add(parseDuration(spot.duration), "minutes");

      return newSpotStart.isBetween(spotStart, spotEnd, undefined, "[)") ||
        newSpotEnd.isBetween(spotStart, spotEnd, undefined, "(]") ||
        (newSpotStart.isSameOrBefore(spotStart) && newSpotEnd.isSameOrAfter(spotEnd));
    });

    if (overlaps) {
      // Если пересекается, ни чего не делаем
      return;
    }

    // Если не пересекается, добавляем новый интервал и сортируем массив по времени начала
    const newSpot = newTimeSpot(newSpotStart.format("HH:mm"), spotDuration);
    const updatedSpots = [ ...timeSpots, newSpot ];

    // Сортируем по времени начала
    updatedSpots.sort((a, b) => moment(a.start, "HH:mm").diff(moment(b.start, "HH:mm")));

    setSelectedTimeSpot(newSpot)
    setTimeSpots(updatedSpots)

    onChange && onChange(updatedSpots.map(spot => getDayIntervalFromTimeSpot(spot)))
  }

  const handleRemoveSpot = (spot: TimeSpot) => {
    // haptic
    selectionChanged()

    setSelectedTimeSpot(undefined)

    const spots = timeSpots.filter(item => item.start !== spot.start);
    setTimeSpots(spots);

    onChange && onChange(spots.map(spot => getDayIntervalFromTimeSpot(spot)))
  }

  // select unselect time spot
  const handleSelect = (spot: TimeSpot) => {

    // haptic
    selectionChanged()

    setSelectedTimeSpot(selectedTimeSpot?.start === spot?.start ? undefined : spot)
  }

  const handleTimeSpotChange = (spot: TimeSpot) => {
    // haptic
    selectionChanged()

    setSelectedTimeSpot(selectedTimeSpot)
    let indexToUpdate = timeSpots.findIndex(item => item.start === spot.start);

    const spots = [ ...timeSpots ]
    spots[indexToUpdate] = spot

    setTimeSpots(spots)

    onChange && onChange(spots.map(spot => getDayIntervalFromTimeSpot(spot)))
  }

  const groupEventsByStartTime = (events: Event[]): Record<string, { duration: string, events: Event[] }> => {
    const groupedEvents: Record<string, { duration: string, events: Event[] }> = {};

    events.forEach(event => {
      if (!groupedEvents[event.start_time]) {
        groupedEvents[event.start_time] = {
          duration: formatDuration(event.start_time, event.end_time),
          events: []
        };
      }
      groupedEvents[event.start_time].events.push(event);
    });

    return groupedEvents;
  };

  const groupedEvents = groupEventsByStartTime(eventItems || []);

  return (
    <>
      <div className="flex-1 flex-grow overflow-y-auto pr-2" ref={containerRef}>
        <div className="relative">

          <div>
            <div className="flex w-full h-6">
              <div className="w-12 flex items-center border-r border-tg-theme-hint justify-center"/>
              <div className="w-full flex justify-end"/>
            </div>

            {[ ...Array(24) ].map((_, hour) => {
              const time = (hour < 10 ? "0" + hour : hour) + ":00"
              return (
                <div
                  key={hour + "item"}
                  id={time}
                  className="flex w-full h-24">

                  <div
                    className="w-12 flex border-r border-tg-theme-hint justify-center text-tg-theme-subtitle -top-1.5 relative"
                    style={{ "fontSize": "10px" }}
                  >
                    <span className="pr-2">{time}</span>
                  </div>

                  <div className="w-full">
                    <div className="border-b border-tg-theme-hint opacity-20 w-full"/>
                    {[ "00", "15", "30", "45" ].map((el, i) => {
                      const time = (hour < 10 ? "0" + hour : hour) + `:${el}`
                      const offset = el === "00" ? "w-2 -left-2" : "w-4 -left-4"
                      return (
                        <React.Fragment key={i}>
                          <div className={`border-b border-tg-theme-hint opacity-20 -top-[0.75px] relative ${offset}`}/>
                          <div
                            id={time}
                            className="w-full h-6 text-center cursor-pointer relative"
                            onClick={() => handlePickTime(time)}
                          >
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>

                </div>
              )
            })}
          </div>

          <div className="pointer-events-none">
            {Object.keys(groupedEvents).map((start_time, index) => {
              const group = groupedEvents[start_time]
              const start = moment(start_time).format("HH:mm")
              const eventDuration = group.duration

              return (
                <div
                  key={index + "spot"}
                  className="w-full absolute cursor-pointer"
                  style={{
                    top: calcSpotPosition(start) + "px",
                    zIndex: 10,
                    height: getHeight(eventDuration) + "px"
                  }}>
                  <div className="h-full flex items-center">
                    <div className="w-14 items-center"/>
                    <div
                      style={{ "fontSize": "10px" }}
                      className="
                          overflow-hidden
                          h-full w-full text-tg-theme-text diagonal-stripes
                          opacity-50 flex items-center
                          border border-tg-theme-section-header border-dotted rounded-md
                        "
                    >
                      {group.events.map((event, e_idx) => {
                        const start = moment(event.start_time).format("HH:mm")
                        const end = moment(event.end_time).format("HH:mm")
                        return (
                          <div key={e_idx}
                            className="inline-block p-2">
                            <div className="flex items-center bg-tg-theme-section rounded-xl p-1">
                              <div>{event.service === "google" && <i className="fa-brands fa-google"/>}</div>
                              <div className="px-2 text-xs font-light">
                                <div className="max-w-64 truncate">
                                  {t("widgets.forms.timepicker.event")}: {event.name}
                                </div>
                                <div className="inline-block text-xs font-bold">
                                  {start} - {end}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div>
            {
              timeSpots.map((spot, index) => {
                const start = moment(spot.start, "HH:mm")
                const end = start.add(parseDuration(spot.duration), "minutes").format("HH:mm")
                const classSpot = (selectedTimeSpot && (spot.start === selectedTimeSpot.start)
                  ? "border-tg-theme-accent-text border-dashed border-2"
                  : "")

                return (
                  <div className="w-full absolute cursor-pointer"
                    style={{
                      top: calcSpotPosition(spot.start) + "px",
                      zIndex: 10,
                      height: getHeight(spot.duration) + "px"
                    }} key={index + "spot"}>
                    <div className="h-full flex">
                      <div className="w-14"/>
                      <div
                        onClick={() => handleSelect(spot)}
                        style={{ "fontSize": "10px" }}
                        className={`
                        h-full w-full font-bold flex
                        border border-tg-theme-button-text
                        text-tg-theme-background-main bg-tg-theme-button
                        order-tg-theme-button-text
                        content-center ${classSpot}
                      `}
                      >
                        <span className="inline-block font-bold p-1">
                          {spot.start} - {end}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

          {!!selectedTimeSpot && (
            <TimeSpotItem
              key={selectedTimeSpot.start}
              yOnly={true}
              yStep={24}
              resizable={false}
              timeSpot={selectedTimeSpot}
              onSelect={handleSelect}
              onRemove={handleRemoveSpot}
              onChange={handleTimeSpotChange}
            />
          )}

        </div>
      </div>
    </>
  );
}

export default TimePicker;
